import React, { useEffect, useState } from "react";
import requests from "../../axios/requests";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import languageVariable from '../../../utils/hookGetVariablesLanguage';
import { Popconfirm, Tooltip } from "antd";
import { changeLanguage } from "../../redux/actions/authorizationActions";
import { setCollapse } from "../../redux/actions/actions";
import Transition from "./Transition/Transition";

import fake_photos from "../../../images/fake_photos.png";
import { ReactComponent as Notification } from "../../../images/icons/navbar_notification.svg";
import { ReactComponent as AcademicPlan } from "../../../images/icons/nav_bar-case.svg";
import { ReactComponent as CourseList } from "../../../images/icons/nav_bar-course.svg";
import { ReactComponent as Logo } from "../../../images/icons/logo.svg";
import { ReactComponent as LogoB } from "../../../images/logo.svg";
import { ReactComponent as MyCourse } from "../../../images/icons/my_course.svg";
import { ReactComponent as Freelance } from "../../../images/icons/freelance_sidebar.svg";
import { ReactComponent as GenerateCourse } from "../../../images/generate_course/generate_course.svg";
import { ReactComponent as SidebarLogout } from "../../../images/icons/sidebar_logout.svg";
import { ReactComponent as Collapse } from "../../../images/icons/collapse.svg";
import { ReactComponent as MailIcon } from "../../../images/icons/mail.svg";
import './Sidebar.scss';

const Sidebar = () => {
    const variable_1 = languageVariable({keyPage: 'navbar', keyVariable: 'variable_1'});
    const variable_2 = languageVariable({keyPage: 'navbar', keyVariable: 'variable_2'});
    const variable_3 = languageVariable({keyPage: 'navbar', keyVariable: 'variable_3'});
    const variable_4 = languageVariable({keyPage: 'navbar', keyVariable: 'variable_4'});
    const variable_5 = languageVariable({keyPage: 'navbar', keyVariable: 'variable_5'});
    const variable_6 = languageVariable({keyPage: 'navbar', keyVariable: 'variable_6'});
    const variable_7 = languageVariable({keyPage: 'navbar', keyVariable: 'variable_7'});
    const variable_8 = languageVariable({keyPage: 'navbar', keyVariable: 'variable_8'});
    const variable_9 = languageVariable({keyPage: 'navbar', keyVariable: 'variable_9'});
    const variable_10 = languageVariable({keyPage: 'navbar', keyVariable: 'variable_10'});
    const variable_11 = languageVariable({keyPage: 'navbar', keyVariable: 'variable_11'});
    const variable_12 = languageVariable({keyPage: 'navbar', keyVariable: 'variable_12'});

    const history = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const {user, collapse, flagSteps} = useSelector(state => state.Reducer);
    const [changeLanguageFlag, setChangeLanguageFlag] = useState(false);
    const [countNotification, setCountNotification] = useState(false);

    let handleLogOut = () => {
        requests.users
            .logout()
            .then(() => {
                window.localStorage.removeItem("token");
                window.localStorage.removeItem("username");
                window.localStorage.removeItem("last_name");
                window.localStorage.removeItem("middle_name");
                window.localStorage.removeItem("fio");
                window.localStorage.removeItem("id");
                window.localStorage.removeItem("count_notification");
            })
            .catch((error) => {
                window.localStorage.removeItem("token");
                window.localStorage.removeItem("username");
                window.localStorage.removeItem("last_name");
                window.localStorage.removeItem("middle_name");
                window.localStorage.removeItem("fio");
                window.localStorage.removeItem("id");
                window.localStorage.removeItem("count_notification");
            })
            .finally(() => history("/student/auth"));
    };
    let getCountNotificationInterval = () => {
        setCountNotification(localStorage.getItem("count_notification"));
    };

    useEffect(() => {
        getCountNotificationInterval();
        setInterval(() => {
            getCountNotificationInterval();
        }, 1000);
    }, []);
    return (
        <div
            className={cn("sidebar", {
                z_index_0: flagSteps,
                display_none: location.pathname === "/" && window.innerWidth < 991,
                open: collapse,
                hide: flagSteps
            })
            }
        >
            <div className="logo">
                <Transition show={collapse} timeout={0}>
                    <LogoB className={"logo_b"} onClick={() => window.open("https://skillometer.ru/", 'blank')}/>
                </Transition>
                {!collapse &&
                    <Transition show={!collapse}>
                        <Logo onClick={() => window.open("https://skillometer.ru/", 'blank')}/>
                    </Transition>
                }
            </div>
            <Tooltip placement="right" title={variable_9}>
                <div
                    className={cn("notification_btn", {
                        active: location?.pathname === ("/notification")
                    })}
                    onClick={() => history("/notification")}
                >
                    <Notification/>
                    <Transition show={collapse}>
                        <div className="link">{variable_9}</div>
                    </Transition>
                    {countNotification && countNotification > 0
                        ? <div className="count_notification">
                                <span>
                                    {countNotification > 99
                                        ? "99+"
                                        : countNotification
                                    }
                                </span></div>
                        : <></>
                    }
                </div>
            </Tooltip>
            <Tooltip placement="right" title={variable_1}>
                <div
                    className={cn("navigation_btn", {
                        active: location?.pathname === ("/student/profile")
                    })}
                    onClick={() => history("/student/profile")}
                >
                    <img src={user?.avatar || fake_photos}/>
                    <Transition show={collapse}>
                        <div className="link">{variable_1}</div>
                    </Transition>
                </div>
            </Tooltip>
            <Tooltip placement="right" title={variable_2}>
                <div
                    className={cn("navigation_btn", {
                        active: location?.pathname === ("/")
                    })}
                    onClick={() => history("/")}
                >
                    <AcademicPlan/>
                    <Transition show={collapse}>
                        <div className="link">{variable_2}</div>
                    </Transition>
                </div>
            </Tooltip>
            <Tooltip placement="right" title={variable_12}>
                <div
                    className={cn("navigation_btn", {
                        active: location?.pathname === ("/student/generate-course")
                            || location?.pathname === ("/generate-course")
                    })}
                    onClick={() => history("/student/generate-course")}
                >
                    <div className="generate_course-icon">
                        <GenerateCourse/>
                    </div>
                    <Transition show={collapse}>
                        <div className="link">{variable_12}</div>
                    </Transition>
                </div>
            </Tooltip>
            <Tooltip placement="right" title={variable_3}>
                <div
                    className={cn("navigation_btn", {
                        active: location?.pathname === ("/student/course-list")
                            || location?.pathname === ("/course-list")
                    })}
                    onClick={() => history("/student/course-list")}
                >
                    <CourseList/>
                    <Transition show={collapse}>
                        <div className="link">{variable_3}</div>
                    </Transition>
                </div>
            </Tooltip>
            <Tooltip placement="right" title={variable_4}>
                <div
                    className={cn("navigation_btn", {
                        active: location?.pathname === ("/student/my-courses")
                    })}
                    onClick={() => history("/student/my-courses")}
                >
                    <MyCourse/>
                    <Transition show={collapse}>
                        <div className="link">{variable_4}</div>
                    </Transition>
                </div>
            </Tooltip>
            <Tooltip placement="right" title={variable_10}>
                <div
                    className={cn("navigation_btn", {
                        active: location?.pathname === ("/student/search-talants")
                            || location?.pathname === ("/search-talants")
                    })}
                    onClick={() => history("/student/search-talants")}
                >
                    <Freelance/>
                    <Transition show={collapse}>
                        <div className="link">{variable_10}</div>
                    </Transition>
                </div>
            </Tooltip>
            <div className="bottom_nav">
                <Tooltip placement="right" title="Техническая поддержка: support@skillometer.ru">
                    <div className="support" tabIndex={0}>
                        <MailIcon className="support-icon" />
                        <Transition show={collapse}>
                            <div className="support-text">
                                <div>Техническая поддержка:</div>
                                <div>support@skillometer.ru</div>
                            </div>
                        </Transition>
                    </div>
                </Tooltip>
                <div
                    className="change_parent"
                    tabIndex={0}
                    onClick={() => setChangeLanguageFlag(!changeLanguageFlag)}
                    onBlur={() => setChangeLanguageFlag(false)}
                >
                    <div
                        className={changeLanguageFlag
                            ? "change_language open"
                            : "change_language"}
                    >
                        {!changeLanguageFlag &&
                            <div className="active">
                                {localStorage.getItem("language") === "en" ? "EN" : "RU"}
                            </div>
                        }
                        {changeLanguageFlag &&
                            <>
                                <div
                                    className={localStorage.getItem("language") === "ru" && "active"}
                                    onClick={() => {
                                        dispatch(changeLanguage("ru"))
                                        localStorage.setItem('language', "ru")
                                        setChangeLanguageFlag(false)
                                    }}
                                >
                                    RU
                                </div>
                                <div
                                    className={localStorage.getItem("language") === "en" && "active"}
                                    onClick={() => {
                                        dispatch(changeLanguage("en"))
                                        localStorage.setItem('language', "en")
                                        setChangeLanguageFlag(false)
                                    }}
                                >
                                    EN
                                </div>
                            </>
                        }
                        {collapse &&
                            <span className="change_language-collapse">{variable_11} </span>
                        }
                    </div>
                </div>
                <Tooltip placement="right" title={variable_5}>
                    <Popconfirm
                        title={variable_6}
                        onConfirm={() => handleLogOut()}
                        okText={variable_7}
                        cancelText={variable_8}
                        placement="right"
                    >
                        <div className={"navigation_btn"}>
                            <SidebarLogout/>
                            <Transition show={collapse}>
                                <span className="link">{variable_5}</span>
                            </Transition>
                        </div>
                    </Popconfirm>
                </Tooltip>
            </div>
            <div
                className={cn("collapse_btn", {
                    open: collapse
                })}
                onClick={() => dispatch(setCollapse(!collapse))}
            >
                <Collapse/>
            </div>
        </div>
    )
};

export default Sidebar;