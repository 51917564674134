const obj = {
    en: {
        authorization: {
            variables_1: 'Authorization',
            variables_2: 'Login with QR code',
            variables_3: 'Open Skillometer on your phone.',
            variables_4: {
                part_1: 'Click the side menu and select',
                part_2: 'QR-code authorization'
            },
            variables_5: 'Point your phone at the screen to read the code.',
            variables_6: 'QR-code',
            variables_7: 'Or',
            variables_8: 'Send SMS-code',
            variables_9: 'Enter code',
            variables_10: 'Not entered',
            variables_11: 'Request QR-code',
            variables_12: 'Continue',
            variables_13: 'Create a profile',
            variables_14: 'Profile photo',
            variables_15: 'File format: jpg/jpeg, png',
            variables_16: 'Resolution: 220x220 - 900x900 px',
            variables_17: 'File size: up to 10 mb',
            variables_18: 'Replace photo',
            variables_19: 'Full name',
            variables_20: 'Sending email notifications',
            variables_21: 'Invalid number format',
            variables_22: "Invalid authorization code",
            variables_23: "Fill in the field name",
            variables_24: "Fill in the email field",
            variables_25: "Profile not found, please register",
            variables_26: "Registration error",
            variables_27: "Enter a valid e-mail",
            variables_28: "What skills do you have?",
            variables_29: "Direction of activity",
            variables_30: "Engineering & IT",
            variables_31: "Science & Medicine",
            variables_32: "Languages & Copywriting",
            variables_33: "Management & Marketing",
            variables_34: "Finance & Law",
            variables_35: "Design & Creative",
            variables_36: "Sport",
            variables_37: "For example, popular skills in ",
            variables_38: "Add skills",
            variables_39: "Search (Enter tag)",
            variables_40: "Suggested Options",
            variables_41: "Back",
            variables_42: "What skills would you like to develop?",
            variables_43: "Add the skill you want to learn",
            variables_44: "Add a Skill",
            variables_45: "For example, popular skills",
            variables_46: "glad to see you",
            variables_47: "Go to learning",
            variables_48: "en",
            variables_49: "All categories",
            variables_50: "Enter phone number",
            variables_51: "Russia",
            variables_52: "Belarus",
            variables_53: "Kazakhstan",
            variables_54: "Azerbaijan",
            variables_55: "Uzbekistan",
            variables_56: "Georgia",
            variables_57: "Kyrgyzstan",
            variables_58: "Freelancer card",
            variables_59: "Skilllometer has a “freelance directory” section, after passing registration," +
                "you can fill out your profile and place the card in directory so that you can be hired to" +
                " provide a service",
        },
        searchProfession: {
            variables_1: 'Professions',
            variables_2: 'IT professions',
            variables_3: 'All profession',
            variables_4: 'Filters',
            variables_5: 'Favorites',
            variables_6: 'Search for a profession',
            variables_7: 'Find',
            variables_8: 'My Skillset',
            variables_9: 'Add your desired skill',
            variables_10: 'Find courses',
            variables_11: 'Choose the right profession for you',
            variables_12: 'Vacancy',
            variables_13: 'Description of the profession',
            variables_14: 'Popular Skills',
            variables_15: 'Key skills you have',
            variables_16: 'Missing skills for this profession',
            variables_17: 'Missing skills are missing',
            variables_18: 'Vacancy description',
            variables_19: 'Required skills for employment',
            variables_20: {
                part_1: 'Your skills fully cover the requirements of the employer',
                part_2: 'Missing skills for this job',
            },
            variables_21: 'Salary opportunities in the profession ',
            variables_22: 'Go to vacancy',
            variables_23: 'No data on required skills',
            variables_24: 'Back to the catalog of professions',
            variables_25: 'Detailed',
            variables_26: 'Go to website',
            variables_27: 'Show Skillset',
            variables_28: 'Close',
            variables_29: 'Professions',
            variables_30: 'Category',
            variables_31: 'This skill is already in the Skillset',
            variables_32: 'Skill added',
            variables_33: 'Specify city/region',
            variables_34: "Skills you already have",
            variables_35: "Popular Skills",
            variables_36: "Generate course",
            variables_37: "Add at least one skill",
            variables_38: "instruction",
            variables_39: "Skill added",
            variables_40: "Error adding skill",

        },
        modalProfessionInfo: {
            variables_1: 'Popular Skills',
            variables_2: 'In-demand courses',
            variables_3: 'If you pass these courses, then your chance to get into a better paid company increases.',
            variables_4: 'Show all',

        },
        modalCourse: {
            variable_1: 'Show all',
            variable_2: 'Price',
            variable_3: 'Date',
            variable_4: 'In-demand courses',
            variable_5: 'If you pass these courses, then your chance to get into more paid company increases',
            variable_6: 'Sort by',
            variable_7: 'My Skillset'
        },
        favorites: {
            variable_1: 'Favorites',
            variable_2: 'Category:',
            variable_3: 'Profession',
            variable_4: 'Vacancy',
            variable_5: 'Go to vacancies',
            variable_6: 'Popular Skills',
            variable_7: 'In-demand courses',
            variable_8: 'If you pass these courses, then your chance to get into a better paid company increases.',
            variable_9: 'Show all',
            variable_10: 'Your match',
            variable_11: 'Current salary opportunities',
            variable_12: 'Go to',
            variable_13: 'Detailed ',
            variable_14: 'Hide Skills'
        },
        filters: {
            variable_1: 'Filters',
            variable_2: 'Keywords',
            variable_3: 'Search only',
            variable_4: 'in company name',
            variable_5: 'in job description',
            variable_6: 'List of industries',
            variable_7: 'Specify industry',
            variable_8: 'Select industries',
            variable_9: 'Region',
            variable_10: 'Append',
            variable_11: 'Income level',
            variable_12: 'from',
            variable_13: 'With salary indication',
            variable_14: 'Show',
            variable_15: 'Required experience',
            variable_16: 'No experience',
            variable_17: 'From 1 year to 3 years',
            variable_18: '3 to 6 years old',
            variable_19: 'Over 6 years',
            variable_20: 'Schedule',
            variable_21: 'Full-time',
            variable_22: 'Shift work',
            variable_23: 'Flexible schedule',
            variable_24: 'Distant work',
            variable_25: 'Shift method',
            variable_26: 'Sort By',
            variable_27: 'Date',
            variable_28: 'Descending income',
            variable_29: 'Ascending income',
            variable_30: 'Compliance',
            variable_31: 'By distance',
            variable_32: 'Output',
            variable_33: 'During all this time',
            variable_34: 'Per month',
            variable_35: 'During the week',
            variable_36: 'Over the last three days',
            variable_37: 'Per day',
            variable_38: 'Find vacancies'
        },
        courseCard: {
            variables_1: 'video',
            variables_2: {
                part_1: 'article',
                part_2: "articles",
            },
            variables_3: {
                part_1: 'homework',
                part_2: "homeworks",
            },
            variables_4: {
                part_1: 'test',
                part_2: 'tests'
            },
            variables_5: 'Key skills',
            variables_6: 'Cost:',
            variables_7: 'Free',
            variables_8: 'from',
            variables_9: 'to',
            variables_10: 'Go to course',
            variables_11: 'Rate and write a review',
        },
        courseList: {
            variables_1: 'Courses',
            variables_2: 'Apply all',
            variables_3: 'Price',
            variables_4: 'Date',
            variables_5: 'Catalog',
            variables_6: 'My courses',
            variables_7: 'Find course',
            variables_8: 'Sort by:',
            variables_9: 'Category:',
            variables_10: 'Relevant to you',
            variables_11: 'Do not apply filters to search from the Skillset',
            variables_12: 'Search course',
            variables_13: 'Cansel',
            variables_14: 'Desired Skills',
            variables_15: "Engineering & IT",
            variables_16: "Science & Medicine",
            variables_17: "Languages & Copywriting",
            variables_18: "Management & Marketing",
            variables_19: "Finance & Law",
            variables_20: "Design & Creative",
            variables_21: "Sport",
            variables_22: "All categories",
            variables_23: "Sign in",
            variables_24: "Course Name or Skill",
            variables_25: "Go to course",
            variables_26: "Free",
            variables_27: "Paid",
            variables_28: "Filters:",

        },
        myCourses: {
            variables_1: 'Courses',
            variables_2: 'Current',
            variables_3: 'Сoming',
            variables_4: 'Past',
            variables_5: 'Catalog',
            variables_6: 'My courses',
            variables_7: 'My Skillset skills',
            variables_8: 'Desired skills of the Skillset',
            variables_9: 'Course Summary:',
            variables_10: 'Category:',
            variables_11: 'Your feedback',
            variables_12: 'Write a feedback',
            variables_13: 'My skills',
            variables_14: 'Rate the course',
            variables_15: 'Review added successfully',
            variables_16: 'Find courses',
            variables_17: 'All',
        },
        landing: {
            variable_1: 'Courses catalog',
            variable_2: 'Speakers',
            variable_3: 'About the course',
            variable_4: 'Who will suit',
            variable_5: 'What will you get',
            variable_6: 'Log-in',
            variable_7: 'What is the format of the training',
            variable_8: 'Video',
            variable_9: 'Learn theory and practice tasks close to real.',
            variable_10: 'Test',
            variable_11: 'Solve problems after each module and get feedback from the curator expert within 1-3 business days.',
            variable_12: 'Article',
            variable_13: 'Study text material online mode with anyone convenient for you devices.',
            variable_14: 'Homework',
            variable_15: 'Study text material online mode with anyone convenient for you devices.',
            variable_16: 'With speaker',
            variable_17: 'Take part in training experienced speakers.',
            variable_18: 'Who will suit',
            variable_19: 'What will you learn',
            variable_20: 'Participant feedback',
            variable_21: 'Do you have any questions?',
            variable_22: 'Ask your question and the speakers of the course will be happy to answer your questions!',
            variable_23: 'Name',
            variable_24: 'Phone',
            variable_25: 'Your message',
            variable_26: 'By clicking on the button, I agree to the processing of personal data and with rules use of the Platform',
            variable_27: 'Send',
            variable_28: 'Sign up for a course',
            variable_29: 'Question sent.',
            variable_30: 'You have already submitted a question.',
            variable_31: 'Enter your name',
            variable_32: 'Enter phone',
            variable_33: 'Enter your message',
            variable_34: 'You have already applied',
            variable_35: 'Application sent',
            variable_36: 'An error has occurred',
            variable_37: 'Question sent',
            variable_38: 'Page not found!',
            variable_39: 'Pay for course',
            variable_40: 'Go to training',
            variable_41: 'Back to catalog',
            variable_42: 'You are already a member of the course',
            variable_43: "Programming",
            variable_44: "Complete tasks online at any time and from any device convenient for you.",
            variable_45: "Learn and put your skills into practice right away. Code directly on our platform.",
            variable_46: "Your resume after completing the course",
            variable_47: "Video",
            variable_48: "Your skills after completing the course",
            variable_49: "The course contains",
            variable_50: "Professional skills:",
            variable_51: "Courses:",
            variable_52: "Access to the course is open",
            variable_53: "Course content",
            variable_54: "Course materials",
            variable_55: "Share",
        },
        profile: {
            variable_1: 'Profile',
            variable_2: 'Skills',
            variable_3: 'Profile photo',
            variable_4: 'File format: jpg/jpeg, png',
            variable_5: 'Resolution: 220x220 - 900x900 px',
            variable_6: 'File size: up to 10 mb',
            variable_7: 'Replace photo',
            variable_8: 'Full name',
            variable_9: 'Your phone number',
            variable_10: 'E-mail',
            variable_11: 'Sending email notifications',
            variable_12: 'Save Changes'
        },
        userSkills: {
            variable_1: 'Profile',
            variable_2: 'Skills',
            variable_3: 'Select a category',
            variable_4: "Engineering & IT",
            variable_5: "Science & Medicine",
            variable_6: "Languages & Copywriting",
            variable_7: "Management & Marketing",
            variable_8: "Finance & Law",
            variable_9: "Design & Creative",
            variable_10: "Sport",
            variable_11: 'Add a Skill',
            variable_12: 'There are no added skills in this category',
            variable_13: 'Balance wheel',
            variable_14: 'SM',
            variable_15: 'LC',
            variable_16: 'MM',
            variable_17: 'FL',
            variable_18: 'DC',
            variable_19: 'S',
            variable_20: 'Balance wheel',
            variable_21: 'Your skills',
        },
        navbarMobile: {
            variable_1: 'Profile',
            variable_2: 'Skills',
        },
        resumePage: {
            variable_2: 'Resume',
            variable_3: 'Basic information',
            variable_4: 'First name',
            variable_5: 'Last name',
            variable_6: 'Patronymic',
            variable_7: 'Not entered',
            variable_8: 'Save photo',
            variable_9: 'Country of Residence',
            variable_10: 'Place of residence',
            variable_11: 'Date of Birth',
            variable_12: 'January',
            variable_13: 'February',
            variable_14: 'March',
            variable_15: 'April',
            variable_16: 'May',
            variable_17: 'June',
            variable_18: 'July',
            variable_19: 'August',
            variable_20: 'September',
            variable_21: 'October',
            variable_22: 'November',
            variable_23: 'December',
            variable_24: 'Specialization',
            variable_25: 'Desired Salary',
            variable_26: 'Moving',
            variable_27: 'Driving license category',
            variable_28: 'Not specified',
            variable_29: 'Willingness to travel',
            variable_30: 'Have a private car',
            variable_31: 'Schedule',
            variable_32: 'Full day',
            variable_33: 'Shift work',
            variable_34: 'Flexible schedule',
            variable_35: 'Distant work',
            variable_36: 'Shift method',
            variable_37: 'Employment',
            variable_38: 'Full employment',
            variable_39: 'Part-time employment',
            variable_40: 'Project work',
            variable_41: 'Volunteering',
            variable_42: 'Internship',
            variable_43: 'Contacts',
            variable_44: 'Enter email',
            variable_45: 'Choose a social network',
            variable_46: 'Not chosen',
            variable_47: 'Other',
            variable_48: 'Link/Login',
            variable_49: 'Insert link',
            variable_50: 'Link to profile',
            variable_51: 'Add social network',
            variable_52: 'Delete?',
            variable_53: 'Yes',
            variable_54: 'No',
            variable_55: 'Work experience',
            variable_56: 'Month',
            variable_57: 'Start year',
            variable_58: 'Until now',
            variable_59: 'Year of ending',
            variable_60: 'End month',
            variable_61: 'Job title',
            variable_62: 'Company name',
            variable_63: 'Responsibilities',
            variable_64: 'Save',
            variable_65: 'Add work experience',
            variable_66: 'Education',
            variable_67: 'Institution name',
            variable_68: 'The level of education',
            variable_69: 'Enter a title',
            variable_70: 'Faculty',
            variable_71: 'Speciality',
            variable_72: 'If you are currently studying, please indicate your expected graduation year',
            variable_73: 'Add Education',
            variable_74: 'Language skills',
            variable_75: 'Native language',
            variable_76: 'Choose language',
            variable_77: 'Proficiency level',
            variable_78: 'Add language',
            variable_79: 'Portfolio',
            variable_80: 'Provide a link',
            variable_81: 'Add link',
            variable_82: 'Professional skills',
            variable_83: 'Skill name',
            variable_84: 'Enter Skill',
            variable_85: 'About myself',
            variable_86: 'Save Changes',
            variable_87: 'Save resume',
            variable_88: 'Please enter a valid e-mail',
            variable_89: 'Profile edited successfully',
            variable_90: 'Specify the schedule and employment',
            variable_91: 'Specify a schedule',
            variable_92: 'Specify employment',
            variable_93: 'Saved.',
            variable_94: 'Ошибка сохранения резюме.',
            variable_95: 'Specify Link/Login',
            variable_96: 'Specify start month.',
            variable_97: 'Please enter a start year.',
            variable_98: 'Specify the end month.',
            variable_99: 'Please enter a start year.',
            variable_100: 'End date before start date',
            variable_101: 'Specify a position.',
            variable_102: 'Enter the name of the company.',
            variable_103: 'Specify responsibilities.',
            variable_104: 'Enter the name of the establishment.',
            variable_105: 'Specify the level of education.',
            variable_106: 'Specify faculty.',
            variable_107: 'Specify a specialty.',
            variable_108: 'Specify the year of graduation.',
            variable_109: 'Please enter a valid URL',
            variable_110: 'This skill has already been added.',
            variable_111: "Courses",
            variable_112: 'Select a category',
            variable_113: "Engineering & IT",
            variable_114: "Science & Medicine",
            variable_115: "Languages & Copywriting",
            variable_116: "Management & Marketing",
            variable_117: "Finance & Law",
            variable_118: "Design & Creative",
            variable_119: "Sport",
            variable_120: "Place your resume in the directory",
            variable_121: "Fill in all required fields",
            variable_122: "Contact number",
            variable_123: "Phone number",
            variable_124: "E-mail",
            variable_125: "Add skills for the selected category",
            variable_126: "Indicate specialization",
            variable_127: "Freelancer card",
            variable_128: "Direction of activity",
            variable_129: "Select a category",
            variable_130: "Remove from publication",
            variable_131: "Publish",
            variable_132: "Download",
            variable_133: "Share",
            variable_134: "View",
            variable_135: "(Published)",
            variable_136: "(Unpublished)",
        },
        lesson: {
            variable_1: 'Newbie',
            variable_2: 'Amateur',
            variable_3: 'Connoisseur',
            variable_4: 'Bronze master',
            variable_5: 'Silver master',
            variable_6: 'Golden master',
            variable_7: 'Platinum master',
            variable_8: 'Diamond master',
            variable_9: 'Professional',
            variable_10: 'Back',
            variable_11: 'Close',
            variable_12: 'Comments',
            variable_13: 'Write a comment',
            variable_14: 'Links to sources',
            variable_15: 'This is the end',
            variable_16: 'Ended trying to pass the test!',
            variable_17: 'Carefully',
            variable_18: 'Count of attempts left:',
            variable_19: 'Pass again',
            variable_20: 'You answered all questions correctly!',
            variable_21: 'You made a mistake',
            variable_22: 'Next',
            variable_23: 'Test result recommendations:',
            variable_24: 'Question',
            variable_25: 'Next question',
            variable_26: 'Failed to display file',
            variable_27: 'The task has been sent!',
            variable_28: 'You have already sent the answer to the task',
            variable_29: 'Specify the text of the answer',
            variable_30: 'Text for the answer',
            variable_31: 'Upload files for review',
            variable_32: 'Re-sending the task is possible after the mentor rejects the current one',
            variable_33: 'Send',
            variable_34: 'Task description',
            variable_35: 'Attached file',
            variable_36: 'Links and sources',
            variable_37: 'You didn\'t send your answer',
            variable_38: 'Mission accomplished!',
            variable_39: 'The assignment needs work.',
            variable_40: 'Assignment under consideration. Access to the next lesson is open.',
            variable_41: 'Answer',
            variable_42: 'Check time',
            variable_43: 'Your answer',
            variable_44: 'Your file',
            variable_45: 'Departure time',
            variable_46: 'Mentor\'s response',
            variable_47: 'Mentor File',
            variable_48: 'Select a file to start downloading on your computer or drag it to this window',
            variable_49: 'Material Description',
            variable_50: 'Attached file',
            variable_51: 'Some assignments have been sent for revision.',
            variable_52: 'Assignment under consideration. Wait for the teacher to answer to open access to the next lesson.',
            variable_53: 'The task needs some work. You cannot move on to the next lesson until the assignment has been accepted by the teacher.',
            variable_54: 'Task',
            variable_55: 'Image',
            variable_56: 'Demo-video',
            variable_57: 'Programming lessons not available on mobile platforms',
            variable_58: 'Your answers',
            variable_59: "Upload file size limit up to 100MB.",
            variable_60: "Your group is archived",
            variable_61: "You can't take tests and homework",
            variable_62: "Links and description of sources",
            variable_63: "Get a certificate",
            variable_64: "Your name",
        },
        navbar: {
            variable_1: 'Profile',
            variable_2: 'Skill set',
            variable_3: 'Course catalog',
            variable_4: 'My courses',
            variable_5: 'Log out',
            variable_6: 'Do you really want to leave?',
            variable_7: 'Yes',
            variable_8: 'No',
            variable_9: 'Notification',
            variable_10: 'Freelancers',
            variable_11: 'Change language',
            variable_12: 'Course Generator',
            variables_13: 'Show My Skillset',
        },
        notification: {
            variable_1: "Notifications",
            variable_2: "New",
            variable_3: "All",
            variable_4: "Question",
            variable_5: "Notification",
            variable_6: "New",
            variable_7: "Viewed",
            variable_8: "Error receiving notifications.",
            variable_9: "You received new skills:",
            variable_10: "",
            variable_11: "",
            variable_12: "",
            variable_13: "",
            variable_14: "",
            variable_15: "",
            variable_16: "",
            variable_17: "",
            variable_18: "",
            variable_19: "",
            variable_20: "",
            variable_21: "",
        },
        searchTalants: {
            variable_1: "Engineering & IT",
            variable_2: "Science & Medicine",
            variable_3: "Languages & Copywriting",
            variable_4: "Management & Marketing",
            variable_5: "Finance & Law",
            variable_6: "Design & Creative",
            variable_7: "Sport",
            variable_8: "Freelancers",
            variable_9: "Submit your CV",
            variable_10: "Sign In",
            variable_11: "Our freelance marketplace connects clients with experts in all fields.",
            variable_12: "Find the best performers for your project and get high quality results in as soon as possible. Join us today and start working on your project right away Now!",
            variable_13: "Find an Artist",
            variable_14: "Freelancer directory",
            variable_15: "Search for talents by category",
            variable_16: "FAQ",
            variable_17: "How to hire performers:",
            variable_18: "Open the candidate's resume and contact him for further cooperation",
            variable_19: "Favorite",
            variable_20: "Find",
            variable_21: "Category:",
            variable_22: "Sort by:",
            variable_23: "Open CV",
            variable_24: "",
        },
        payments: {
            variable_1: "Payment by card",
            variable_2: "You pay directly to the service using a bank card",
            variable_3: "Payment by QR",
            variable_4: "You need to scan the code to automatically proceed to payment",
            variable_5: "Pay in installments",
            variable_6: "The entire amount does not need to be paid, the cost is divided into four equal payments",
            variable_7: "Point your phone camera at the QR code",
            variable_8: "The selection of the bank application will appear on the screen. Follow further instructions on your phone. If it doesn't work, go to step 3",
            variable_9: "Open the bank app on your phone",
            variable_10: "Go to the \"Payments\" section",
            variable_11: "Select \"Payment by QR code\" and scan",
            variable_12: "The order will be formed within a minute after payment",
            variable_13: "Back",
            variable_14: "Payment by QR code",
            variable_15: "Course payment",
            variable_16: "Choose a payment format convenient for you",
            variable_17: "Pay",
        },
        chatGptModal: {
            variable_1: "Create a learning plan for course generation",
            variable_2: "The system will help generate a step-by-step training plan and form text content for mastering the skill",
            variable_3: "What will we learn?",
            variable_4: "Choose a skill to create a content learning plan",
            variable_5: "Refresh",
            variable_6: "You can generate a learning plan for one skill up to 5 times and choose the best option for course generation.",
            variable_7: "Updated:",
            variable_8: "Please wait, it will take some time to process your request...",
            variable_9: "Save instruction",
            variable_10: "Add a course and go to learning",
            variable_11: "Pay",
            variable_12: "Failed to generate course attempts",
            variable_13: "Pay for course generation (left:",
            variable_14: "Count courses:",
            variable_15: "Total amount:",
            variable_16: "Choose the best option:",
            variable_17: "Go to course",
            variable_18: "Wait for plan generation to complete",
            variable_19: "Pay for course generation",
            variable_20: "Available ",
            variable_21: " generations course",
            variable_22: "Total price:",
            variable_23: "Price with discount",
            variable_24: "Minimum quantity for purchase: 5 generations",
        },
        generateCourse: {
            variable_1: "Course Generator",
            variable_2: "Choose one of your skills to generate a course that's right for you",
            variable_3: "Increase",
            variable_4: "Downsize",
            variable_5: "Generation settings",
            variable_6: "Generate Learning Plan",
            variable_7: "Offer is limited, hurry up!",
            variable_8: "Manage to invite a friend and get 2 generations for yourself and 2 generations for a friend for free",
            variable_9: "Invite",
            variable_10: "Difficulty",
            variable_11: "For newbies",
            variable_12: "Course time",
            variable_13: "Create course",
            variable_14: "Buy generation",
            variable_15: "Failed to generate course attempts",
            variable_16: "Plan of the education",
            variable_17: "Please wait, it will take some time to process your request...",
            variable_18: "Wait for plan generation to complete",
            variable_19: "Add skills",
            variable_20: "Count lessons",
            variable_21: "Choice",
            variable_22: "Go to course",
            variable_23: "Top up",
            variable_24: "Number of plans",
            variable_25: "Number of courses",
            variable_26: "Copy link",
            variable_27: "Copy",
            variable_28: "Send a friend an invite",
            variable_29: "Favorite",
            variable_30: "List of training plans saved in favorites",
            variable_31: "Invite friends to receive 2 additional course generations. Your friends will also receive 2 course generations",
            variable_32: "Curl",
            variable_33: "My Skillset",
            variable_34: "Select skill",
            variable_35: "Uncurl",
            variable_36: "Log-in",
            variable_37: "Share the course in messengers",
            variable_38: "Invite your friends to get 2 additional course generations. Your friends will also receive 2 generations of the course.",
            variable_39: "To get training plan options, select the skill you want",
            variable_40: "Plan of the education"
        },
        sidebar: {
            variable_1: "Skills",
            variable_2: "Catalog",
            variable_3: "Profile",
            variable_4: "Notification",
            variable_5: "Generator",
            variable_6: "My courses",
            variable_7: "Freelance",
            variable_8: "Exit",
            variable_9: "Add generation",
            variable_10: "",
        }
    },
    ru: {
        authorization: {
            variables_1: 'Авторизация',
            variables_2: 'Войти с помощью QR кода',
            variables_3: 'Откройте Skillometer на своем телефоне.',
            variables_4: {
                part_1: 'Нажмите боковое меню и выберите',
                part_2: 'QR-код авторизация.'
            },
            variables_5: 'Наведите телефон на экран чтобы считать код.',
            variables_6: 'QR-код',
            variables_7: 'Или',
            variables_8: 'Отправить СМС-код',
            variables_9: 'Введите код',
            variables_10: 'Не введено',
            variables_11: 'Запросить QR-код',
            variables_12: 'Далее',
            variables_13: 'Создание профиля',
            variables_14: 'Фото профиля',
            variables_15: 'Формат файла: jpg/jpeg, png',
            variables_16: 'Разрешение: 220x220 - 900x900 px',
            variables_17: 'Размер файла: до 10 мб',
            variables_18: 'Заменить фото',
            variables_19: 'ФИО',
            variables_20: 'Рассылка уведомлений на почту',
            variables_21: 'Неверный формат номера',
            variables_22: "Неверный код авторизации",
            variables_23: "Заполните поле ФИО",
            variables_24: "Заполните поле email",
            variables_25: "Профиль не найден, пройдите регистрацию",
            variables_26: "Registration error",
            variables_27: "Введите корректный e-mail",
            variables_28: "Какие навыки у вас есть?",
            variables_29: "Направление деятельности",
            variables_30: "Инженерные & IT",
            variables_31: "Наука & Медицина",
            variables_32: "Языки & Копирайтинг",
            variables_33: "Менеджмент & Маркетинг",
            variables_34: "Финансы & Юриспруденция",
            variables_35: "Дизайн & Креатив",
            variables_36: "Спорт",
            variables_37: "Например, популярные навыки в ",
            variables_38: "Добавить скилл",
            variables_39: "Поиск (введите тег)",
            variables_40: "Предложенные варианты",
            variables_41: "Назад",
            variables_42: "Какие навыки хотели бы прокачать?",
            variables_43: "Добавить навык который хотите узнать",
            variables_44: "Добавьте навык",
            variables_45: "Например, популярные навыки",
            variables_46: "рады тебя видеть",
            variables_47: "Перейти к обучению",
            variables_48: "ru",
            variables_49: "Все категории",
            variables_50: "Введите номер телефона",
            variables_51: "Россия",
            variables_52: "Беларусь",
            variables_53: "Казахстан",
            variables_54: "Азербайджан",
            variables_55: "Узбекистан",
            variables_56: "Грузия",
            variables_57: "Кыргызстан",
            variables_58: "Карточка фрилансера",
            variables_59: "Skilllometer имеет раздел “каталог фрилансеров”, после прохождения регистрации вы " +
                "можете заполнить свой профиль и разместить карточку в каталоге, чтобы вас могли нанять для оказания услуги",
        },
        searchProfession: {
            variables_1: 'Профессии',
            variables_2: 'IT профессии',
            variables_3: 'Все профессии',
            variables_4: 'Фильтры',
            variables_5: 'Избранное',
            variables_6: 'Поиск профессии',
            variables_7: 'Найти',
            variables_8: 'Мой Скиллсет',
            variables_9: 'Добавьте желаемый навык',
            variables_10: 'Подобрать курсы',
            variables_11: 'Выберите для себя подходящую профессию',
            variables_12: 'Вакансии',
            variables_13: 'Описание профессии',
            variables_14: 'Популярные навыки',
            variables_15: 'Ключевые навыки, которые у вас есть',
            variables_16: 'Недостающие навыки для этой профессии',
            variables_17: 'Недостающие навыки отсутствуют для этой профессии',
            variables_18: 'Описание вакансии',
            variables_19: 'Требуемые навыки для трудоустройства',
            variables_20: {
                part_1: 'Ваши скиллы полностью покрывают требования работодателя',
                part_2: 'Недостающие навыки  для этой вакансии'
            },
            variables_21: 'Зарплатные возможности в профессии ',
            variables_22: 'Перейти к вакансии',
            variables_23: 'Нет данных о требуемых навыках',
            variables_24: 'Назад к каталогу профессий',
            variables_25: 'Подробнее',
            variables_26: 'Перейти',
            variables_27: 'Показать Скиллсет',
            variables_28: 'Закрыть',
            variables_29: 'Профессии',
            variables_30: 'Категория',
            variables_31: 'Этот навык уже есть в Скиллсете',
            variables_32: 'Навык добавлен',
            variables_33: 'Укажите город/регион',
            variables_34: "Навыки, которые у вас уже есть",
            variables_35: "Популярные навыки",
            variables_36: "Генерировать курс",
            variables_37: "Добавьте хотя бы один навык",
            variables_38: "инструкция",
            variables_39: "Навык добавлен",
            variables_40: "Ошибка добавления навыка",
        },
        modalProfessionInfo: {
            variables_1: 'Популярные навыки',
            variables_2: 'Курсы по востребованным скилам',
            variables_3: 'Если пройдете данные курсы, то ваш шанc попасть в более оплачиваемую компанию возрастает.',
            variables_4: 'Показать все',
        },
        modalCourse: {
            variable_1: 'Показать все',
            variable_2: 'Цене',
            variable_3: 'Дате',
            variable_4: 'Курсы по востребованным скилам',
            variable_5: 'Если пройдете данные курсы, то ваш шанc попасть в более оплачиваемую компанию возрастает',
            variable_6: 'Сортировать по',
            variable_7: 'Мой Скиллсет'
        },
        favorites: {
            variable_1: 'Избранное',
            variable_2: 'Категория:',
            variable_3: 'Профессия',
            variable_4: 'Вакансия',
            variable_5: 'Перейти к вакансиям',
            variable_6: 'Популярные навыки',
            variable_7: 'Курсы по востребованным скилам',
            variable_8: 'Если пройдете данные курсы, то ваш шан попасть в более оплачиваемую компанию возрастает.',
            variable_9: 'Показать все',
            variable_10: 'Ваше соответсвие',
            variable_11: 'Текущие зарплатные возможности',
            variable_12: 'Перейти',
            variable_13: 'Подробнее',
            variable_14: 'Скрыть навыки'
        },
        filters: {
            variable_1: 'Фильтры',
            variable_2: 'Ключевые слова',
            variable_3: 'Искать только',
            variable_4: 'в названии компании',
            variable_5: 'в описании вакансии',
            variable_6: 'Список отраслей',
            variable_7: 'Указать отрасль',
            variable_8: 'Выбор отраслей',
            variable_9: 'Регион',
            variable_10: 'Добавить',
            variable_11: 'Уровень дохода',
            variable_12: 'от',
            variable_13: 'С указанием зп',
            variable_14: 'Показывать',
            variable_15: 'Требуемый опыт',
            variable_16: 'Нет опыта',
            variable_17: 'От 1 года до 3 лет',
            variable_18: 'От 3 до 6 лет',
            variable_19: 'Более 6 лет',
            variable_20: 'График работы',
            variable_21: 'Полный день',
            variable_22: 'Сменный график',
            variable_23: 'Гибкий график',
            variable_24: 'Удаленная работа',
            variable_25: 'Вахтовый метод',
            variable_26: 'Сортировка',
            variable_27: 'По дате',
            variable_28: 'По убыванию дохода',
            variable_29: 'По возрастанию дохода',
            variable_30: 'По соответствию',
            variable_31: 'По удалённости',
            variable_32: 'Выводить',
            variable_33: 'За всё время',
            variable_34: 'За месяц',
            variable_35: 'За неделю',
            variable_36: 'За последние три дня',
            variable_37: 'За сутки',
            variable_38: 'Подобрать вакансии'
        },
        courseCard: {
            variables_1: 'видео',
            variables_2: {
                part_1: 'статья',
                part_2: "статьи",
                part_3: "статей"
            },
            variables_3: 'д/з',
            variables_4: {
                part_1: 'тест',
                part_2: 'теста',
                part_3: 'тестов'
            },
            variables_5: 'Ключевые навыки',
            variables_6: 'Стоимость:',
            variables_7: 'Бесплатно',
            variables_8: 'c',
            variables_9: 'по',
            variables_10: 'Перейти к курсу',
            variables_11: 'Оставить оценку и отзыв',
        },
        courseList: {
            variables_1: 'Курсы',
            variables_2: 'Применять все',
            variables_3: 'Цене',
            variables_4: 'Дате',
            variables_5: 'Каталог',
            variables_6: 'Мои курсы',
            variables_7: 'Найти курс',
            variables_8: 'Сортировать по:',
            variables_9: 'Категория:',
            variables_10: 'Актуальное для вас',
            variables_11: 'Не применять фильтры для поиска из Скиллсета',
            variables_12: 'Поиск курса',
            variables_13: 'Отменить',
            variables_14: 'Желаемые навыки',
            variables_15: "Инженерные & IT",
            variables_16: "Наука & Медицина",
            variables_17: "Языки & Копирайтинг",
            variables_18: "Менеджмент & Маркетинг",
            variables_19: "Финансы & Юриспруденция",
            variables_20: "Дизайн & Креатив",
            variables_21: "Спорт",
            variables_22: "Все категории",
            variables_23: "Войти",
            variables_24: "Наименование курса или навык",
            variables_25: "Перейти к курсу",
            variables_26: "Бесплатные",
            variables_27: "Платные",
            variables_28: "Фильтры:",

        },
        myCourses: {
            variables_1: 'Курсы',
            variables_2: 'Текущие',
            variables_3: 'Предстоящие',
            variables_4: 'Прошедшие',
            variables_5: 'Каталог',
            variables_6: 'Мои курсы',
            variables_7: 'Мои навыки Скиллсета',
            variables_8: 'Желаемые навыки Скиллсета',
            variables_9: 'Сводка по курсам:',
            variables_10: 'Категория:',
            variables_11: 'Ваш отзыв',
            variables_12: 'Оставить отзыв',
            variables_13: 'Мои навыки',
            variables_14: 'Оцените курс',
            variables_15: 'Отзыв успешно добавлен',
            variables_16: 'Найти курс',
            variables_17: 'Все',
        },
        landing: {
            variable_1: 'Каталог курсов',
            variable_2: 'Спикеры',
            variable_3: 'О курсе',
            variable_4: 'Кому подойдет',
            variable_5: 'Что получите',
            variable_6: 'Войти',
            variable_7: 'В каком формате проходит обучение',
            variable_8: 'Видео',
            variable_9: 'Изучаете теорию и практикуетесь на задачах, приближенных к реальным.',
            variable_10: 'Тест',
            variable_11: 'Решаете задачи после каждого модуля и получаете обратную связь от курирующего эксперта в течение 1–3 рабочих дней.',
            variable_12: 'Статья',
            variable_13: 'Изучайте текстовый материал в онлайн режиме с любого,  удобного для вас устройства.',
            variable_14: 'Домашнее задание',
            variable_15: 'Изучайте текстовый материал в онлайн режиме с любого,  удобного для вас устройства.',
            variable_16: 'Со спикером',
            variable_17: 'Проходите обучение с участием опытных спикеров.',
            variable_18: 'Кому подойдет',
            variable_19: 'Чему вы научитесь',
            variable_20: 'Отзывы участников',
            variable_21: 'Остались вопросы?',
            variable_22: 'Задайте свой вопрос и спикеры курса с удовольствием ответят на возникшие у вас вопросы!',
            variable_23: 'Имя',
            variable_24: 'Телефон',
            variable_25: 'Ваше сообщение',
            variable_26: 'Нажимая на кнопку, я соглашаюсь на обработку персональных данных и с правилами пользования Платформой',
            variable_27: 'Отправить',
            variable_28: 'Записаться на курс',
            variable_29: 'Question sent.',
            variable_30: 'Вы уже отправили вопрос.',
            variable_31: 'Введите имя',
            variable_32: 'Введите телефон',
            variable_33: 'Введите сообщение',
            variable_34: 'Вы уже подали заявку',
            variable_35: 'Заявка отправлена',
            variable_36: 'Произошла ошибка',
            variable_37: 'Вопрос отправлен',
            variable_38: 'Упс, а страница не найдена!',
            variable_39: 'Оплатить курс',
            variable_40: 'Перейти к обучению',
            variable_41: 'Назад в каталог',
            variable_42: 'Вы уже являетесь участником курса',
            variable_43: "Программирование",
            variable_44: "Выполняйте задания в онлайн режиме в любое время и с любого удобного вам устройства.",
            variable_45: "Учитесь и сразу применяйте ваши навыки на практике. Пишите код прямо на нашей платформе.",
            variable_46: "Ваше резюме после прохождения курса",
            variable_47: "Видео",
            variable_48: "Ваши умения после прохождения курса",
            variable_49: "Курс содержит",
            variable_50: "Профессиональные навыки:",
            variable_51: "Курсы:",
            variable_52: "Доступ к курсу открыт",
            variable_53: "Содержание курса",
            variable_54: "Материалы курса",
            variable_55: "Поделиться",
        },
        profile: {
            variable_1: 'Профиль',
            variable_2: 'Навыки',
            variable_3: 'Фото профиля',
            variable_4: 'Формат файла: jpg/jpeg, png',
            variable_5: 'Разрешение: 220x220 - 900x900 px',
            variable_6: 'Размер файла: до 10 мб',
            variable_7: 'Заменить фото',
            variable_8: 'ФИО',
            variable_9: 'Ваш номер телефона',
            variable_10: 'E-mail',
            variable_11: 'Рассылка уведомлений на почту',
            variable_12: 'Сохранить изменения'
        },
        userSkills: {
            variable_1: 'Профиль',
            variable_2: 'Навыки',
            variable_3: 'Выберите категорию',
            variable_4: "Инженерные & IT",
            variable_5: "Наука & Медицина",
            variable_6: "Языки & Копирайтинг",
            variable_7: "Менеджмент & Маркетинг",
            variable_8: "Финансы & Юриспруденция",
            variable_9: "Дизайн & Креатив",
            variable_10: "Спорт",
            variable_11: 'Добавьте навык',
            variable_12: 'Нет добавленных скиллов этой категории',
            variable_13: 'Колесо баланса',
            variable_14: 'НМ',
            variable_15: 'ЯК',
            variable_16: 'ММ',
            variable_17: 'ФЮ',
            variable_18: 'ДК',
            variable_19: 'C',
            variable_20: 'Колесо баланса',
            variable_21: 'Ваши навыки',

        },
        navbarMobile: {
            variable_1: 'Профиль',
            variable_2: 'Навыки',
        },
        resumePage: {
            variable_2: 'Резюме',
            variable_3: 'Основная информация',
            variable_4: 'Имя',
            variable_5: 'Фамилия',
            variable_6: 'Отчество',
            variable_7: 'Не введено',
            variable_8: 'Сохранить фото',
            variable_9: 'Страна проживания',
            variable_10: 'Место проживания',
            variable_11: 'Дата рождения',
            variable_12: 'Январь',
            variable_13: 'Февраль',
            variable_14: 'Март',
            variable_15: 'Апрель',
            variable_16: 'Май',
            variable_17: 'Июнь',
            variable_18: 'Июль',
            variable_19: 'Август',
            variable_20: 'Сентябрь',
            variable_21: 'Октябрь',
            variable_22: 'Ноябрь',
            variable_23: 'Декабрь',
            variable_24: 'Специализация',
            variable_25: 'Желаемая зарплата',
            variable_26: 'Переезд',
            variable_27: 'Категория прав',
            variable_28: 'Не указано',
            variable_29: 'Готовность к командировкам',
            variable_30: 'Есть личный автомобиль',
            variable_31: 'График работы',
            variable_32: 'Полный день',
            variable_33: 'Сменный график',
            variable_34: 'Гибкий график',
            variable_35: 'Удаленная работа',
            variable_36: 'Вахтовый метод',
            variable_37: 'Занятость',
            variable_38: 'Полная занятость',
            variable_39: 'Частичная занятость',
            variable_40: 'Проектная работа',
            variable_41: 'Волонтерство',
            variable_42: 'Стажировка',
            variable_43: 'Контакты',
            variable_44: 'Введите email',
            variable_45: 'Выберите сеть',
            variable_46: 'Не выбрано',
            variable_47: 'Другое',
            variable_48: 'Ссылка/Логин',
            variable_49: 'Вставьте ссылку',
            variable_50: 'Ссылка на профиль',
            variable_51: 'Добавить социальную сеть',
            variable_52: 'Удалить?',
            variable_53: 'Да',
            variable_54: 'Нет',
            variable_55: 'Опыт работы',
            variable_56: 'Месяц',
            variable_57: 'Год начала',
            variable_58: 'По настоящее время',
            variable_59: 'Год окончания',
            variable_60: 'Месяц окончания',
            variable_61: 'Должность',
            variable_62: 'Название компании',
            variable_63: 'Обязанности',
            variable_64: 'Сохранить',
            variable_65: 'Добавить опыт работы',
            variable_66: 'Образование',
            variable_67: 'Название заведения',
            variable_68: 'Уровень образования',
            variable_69: 'Укажите название',
            variable_70: 'Факультет',
            variable_71: 'Специальность',
            variable_72: 'Если вы учитесь в настоящее время, укажите год предполагаемого окончания',
            variable_73: 'Добавить образование',
            variable_74: 'Владение языками',
            variable_75: 'Родной язык',
            variable_76: 'Выберите язык',
            variable_77: 'Уровень владения',
            variable_78: 'Добавить язык',
            variable_79: 'Портфолио',
            variable_80: 'Укажите ссылку',
            variable_81: 'Добавить ссылку',
            variable_82: 'Профессиональные навыки',
            variable_83: 'Наименование навыка',
            variable_84: 'Введите навык',
            variable_85: 'О себе',
            variable_86: 'Сохранить изменения',
            variable_87: 'Сохранить резюме',
            variable_88: 'Введите корректный e-mail',
            variable_89: 'Профиль успешно отредактирован',
            variable_90: 'Укажите график и занятость',
            variable_91: 'Укажите график',
            variable_92: 'Укажите занятость',
            variable_93: 'Сохранено.',
            variable_94: 'Ошибка сохранения резюме.',
            variable_95: 'Укажите Ссылку/Логин',
            variable_96: 'Укажите месяц начала.',
            variable_97: 'Укажите год начала.',
            variable_98: 'Укажите месяц окончания.',
            variable_99: 'Укажите год начала.',
            variable_100: 'Дата окончания ранее даты начала',
            variable_101: 'Укажите должность.',
            variable_102: 'Укажите название компании.',
            variable_103: 'Укажите обязанности.',
            variable_104: 'Укажите название заведения.',
            variable_105: 'Укажите уровень образования.',
            variable_106: 'Укажите факультет.',
            variable_107: 'Укажите специальность.',
            variable_108: 'Укажите год окончания.',
            variable_109: 'Введите корректный URL',
            variable_110: 'Этот навык уже добавлен.',
            variable_111: "Курсы",
            variable_112: 'Выберите категорию',
            variable_113: "Инженерные & IT",
            variable_114: "Наука & Медицина",
            variable_115: "Языки & Копирайтинг",
            variable_116: "Менеджмент & Маркетинг",
            variable_117: "Финансы & Юриспруденция",
            variable_118: "Дизайн & Креатив",
            variable_119: "Спорт",
            variable_120: "Разместить резюме в каталоге",
            variable_121: "Заполните все обязательные поля",
            variable_122: "Контактный телефон",
            variable_123: "Телефон",
            variable_124: "E-mail",
            variable_125: "Добавьте навыки по выбранной категории",
            variable_126: "Укажите специализацию",
            variable_127: "Карточка фрилансера",
            variable_128: "Направление деятельности",
            variable_129: "Выберите категорию",
            variable_130: "Снять с публикации",
            variable_131: "Опубликовать",
            variable_132: "Скачать",
            variable_133: "Поделиться",
            variable_134: "Просмотреть",
            variable_135: "(Опубликовано)",
            variable_136: "(Не опубликовано)",
        },
        lesson: {
            variable_1: 'Новичок',
            variable_2: 'Любитель',
            variable_3: 'Знаток',
            variable_4: 'Бронзовый мастер',
            variable_5: 'Серебряный мастер',
            variable_6: 'Золотой мастер',
            variable_7: 'Платиновый мастер',
            variable_8: 'Бриллиантовый мастер',
            variable_9: 'Профессионал',
            variable_10: 'Назад',
            variable_11: 'Закрыть',
            variable_12: 'Комментарии',
            variable_13: 'Оставьте комментарий',
            variable_14: 'Ссылки на источники',
            variable_15: 'Это конец',
            variable_16: 'Закончились попытки прохождения теста!',
            variable_17: 'Осторожно',
            variable_18: 'Колличество оставшихся попыток:',
            variable_19: 'Пройти заново',
            variable_20: 'Вы ответили на все вопросы верно!',
            variable_21: 'Вы допустили ошибки',
            variable_22: 'Далее',
            variable_23: 'Рекомендации по результатам теста:',
            variable_24: 'Вопрос',
            variable_25: 'Следующий вопрос',
            variable_26: 'Не удалось отобразить файл',
            variable_27: 'Задание отправлено!',
            variable_28: 'Вы уже отослали ответ на задание',
            variable_29: 'Укажите текст ответа',
            variable_30: 'Текст к ответу',
            variable_31: 'Загрузить файлы на проверку',
            variable_32: 'Повторное отправление задание возможно после отклонения наставником текущего',
            variable_33: 'Отправить',
            variable_34: 'Описание задания',
            variable_35: 'Прикреплённый файл',
            variable_36: 'Ссылки и источники',
            variable_37: 'Вы не отсылали свой ответ',
            variable_38: 'Задание выполнено!',
            variable_39: 'Задание требует доработки.',
            variable_40: 'Задание на рассмотрении. Доступ к следующему занятию открыт.',
            variable_41: 'Ответ',
            variable_42: 'Время проверки',
            variable_43: 'Ваш ответ',
            variable_44: 'Ваш файл',
            variable_45: 'Время отправления',
            variable_46: 'Ответ наставника',
            variable_47: 'Файл наставника',
            variable_48: 'Чтобы начать загрузку, выберите файл на компьютере или перетащите его в это окно',
            variable_49: 'Описание материала',
            variable_50: 'Прикреплённый файл',
            variable_51: 'Некоторые задания отправлены на доработку.',
            variable_52: 'Задание на рассмотрении. Ждите пока преподователь примет задание, чтобы окрыть доступ к следующему занятию.',
            variable_53: 'Задание требует доработки. Нельзя перейти к следующему занятию, пока задание не будет принято преподователем.',
            variable_54: 'Задание',
            variable_55: 'Изображение',
            variable_56: 'Демо-видео',
            variable_57: 'Уроки по программированнию недоступны на мобильных платформах',
            variable_58: 'Ваши ответы',
            variable_59: "Ограничение размера загружаемого файла до 100мб.",
            variable_60: "Ваша группа в архиве",
            variable_61: "Вы не можете проходить тесты и домашние задания",
            variable_62: "Ссылки и описание источников",
            variable_63: "Получить сертификат",
            variable_64: "Ваше ФИО",
        },
        navbar: {
            variable_1: 'Профиль',
            variable_2: 'Скиллсет',
            variable_3: 'Каталог курсов',
            variable_4: 'Мои курсы',
            variable_5: 'Выйти',
            variable_6: 'Вы действительно хотите выйти?',
            variable_7: 'Да',
            variable_8: 'Нет',
            variable_9: 'Уведомления',
            variable_10: 'Фрилансеры',
            variable_11: 'Сменить язык',
            variable_12: 'Генератор курсов',
            variables_13: 'Показать мой Скиллсет',
        },
        notification: {
            variable_1: "Уведомления",
            variable_2: "Новые",
            variable_3: "Все",
            variable_4: "Вопрос",
            variable_5: "Уведомление",
            variable_6: "Новое",
            variable_7: "Просмотрено",
            variable_8: "Ошибка получения уведомлений.",
            variable_9: "Вы получили новые навыки:",
            variable_10: "",
            variable_11: "",
            variable_12: "",
            variable_13: "",
            variable_14: "",
            variable_15: "",
            variable_16: "",
            variable_17: "",
            variable_18: "",
            variable_19: "",
            variable_20: "",
            variable_21: "",
        },
        searchTalants: {
            variable_1: "Инженерные & IT",
            variable_2: "Наука & Медицина",
            variable_3: "Языки & Копирайтинг",
            variable_4: "Менеджмент & Маркетинг",
            variable_5: "Финансы & Юриспруденция",
            variable_6: "Дизайн & Креатив",
            variable_7: "Спорт",
            variable_8: "Фрилансеры",
            variable_9: "Разместить резюме",
            variable_10: "Войти",
            variable_11: "Наша площадка фрилансеров соединяет заказчиков со специалистами во всех областях.",
            variable_12: "Найдите лучших исполнителей для своего проекта и получите высококачественный результат в кратчайшие сроки. Присоединяйтесь к нам сегодня и начните работать над своим проектом прямо сейчас!",
            variable_13: "Найти исполнителя",
            variable_14: "Каталог фрилансеров",
            variable_15: "Поиск талантов по категориям",
            variable_16: "Часто задаваемые вопросы",
            variable_17: "Как нанимать исполнителей:",
            variable_18: "Откройте резюме кандидата и свяжитесь с ним для последующего сотрудничества",
            variable_19: "Избранное",
            variable_20: "Найти",
            variable_21: "Категория:",
            variable_22: "Сортировать по:",
            variable_23: "Открыть резюме",
            variable_24: "",
        },
        payments: {
            variable_1: "Оплата по карте",
            variable_2: "Вы оплачиваете напрямую сервису с помощью банковской карты",
            variable_3: "Оплата по QR",
            variable_4: "Вам нужно просканировать код чтобы автоматически перейти к оплате",
            variable_5: "Оплата долями",
            variable_6: "Всю сумму не нужно оплачивать, стоимость делится на четыре равных платежа",
            variable_7: "Наведите камеру телефона на QR-код",
            variable_8: "На экране появится выбор приложения банка. Следуйте дальнейшим инструкциям в телефоне. Если не сработало, перейдите к шагу 3",
            variable_9: "Откройте приложение банка в телефоне",
            variable_10: "Перейдите в раздел “Платежи”",
            variable_11: "Выберите пункт “Оплата по QR-коду” и отсканируйте",
            variable_12: "Заказ сформируется в течении минуты после оплаты",
            variable_13: "Назад",
            variable_14: "Оплата по QR-коду",
            variable_15: "Оплата курса",
            variable_16: "Выберите удобный для вас формат оплаты",
            variable_17: "Оплатить",
        },
        chatGptModal: {
            variable_1: "Создаем план обучения для генерации курса",
            variable_2: "Система поможет сгенерировать пошаговый план обучения и сформировать текстовый контент для освоения навыка",
            variable_3: "Что будем учить?",
            variable_4: "Выберите навык чтобы создать план обучения с контентом",
            variable_5: "Обновить",
            variable_6: "Вы можете сгенерировать план обучения по одному навыку не более 5 раз и выбрать лучший вариант для генерации курса.",
            variable_7: "Обновлено:",
            variable_8: "Подождите, обработка запроса займёт некоторое время...",
            variable_9: "Сохранить инструкцию",
            variable_10: "Добавить курс и перейти к обучению",
            variable_11: "Оплатить",
            variable_12: "Закончились попытки генерации курса",
            variable_13: "Оплатить генерацию курсов (осталось:",
            variable_14: "Количество курсов:",
            variable_15: "Итоговая сумма:",
            variable_16: "Выберите лучший вариант:",
            variable_17: "Перейти к курсу",
            variable_18: "Дождитесь завершения генерации плана",
            variable_19: "Оплатить генерацию курсов",
            variable_20: "Доступно ",
            variable_21: " генераций курса",
            variable_22: "Сумма:",
            variable_23: "Цена со скидкой",
            variable_24: "Минимальное количество для покупки: 5 генераций",

        },
        generateCourse: {
            variable_1: "Генератор курсов",
            variable_2: "Выберите один из своих навыков, чтобы сгенерировать подходящий для вас курс",
            variable_3: "Увеличить",
            variable_4: "Уменьшить",
            variable_5: "Настройки генерации",
            variable_6: "Сгенерировать план обучения",
            variable_7: "Предложение ограничено, поторопись!",
            variable_8: "Успей пригласить друга и получи 2 генерации для себя и 2 генерации для друга бесплатно",
            variable_9: "Пригласить",
            variable_10: "Сложность",
            variable_11: "Для новичков",
            variable_12: "Время прохождения",
            variable_13: "Создать курс",
            variable_14: "Приобрести генерацию",
            variable_15: "Закончились попытки генерации курса",
            variable_16: "План обучения по",
            variable_17: "Подождите, обработка запроса займёт некоторое время...",
            variable_18: "Дождитесь завершения генерации плана",
            variable_19: "Добавить навыки",
            variable_20: "Количество занятий",
            variable_21: "Вариант",
            variable_22: "Перейти к курсу",
            variable_23: "Пополнить",
            variable_24: "Количество планов",
            variable_25: "Количество курсов",
            variable_26: "Скопируй ссылку",
            variable_27: "Скопировать",
            variable_28: "Отправь другу приглашение",
            variable_29: "Избранное",
            variable_30: "Список планов по обучению сохранённый в избранном",
            variable_31: "Приглашай друзей, чтобы получить 2 дополнительных генераций курса. Твои друзья тоже получат 2 генерации курса",
            variable_32: "Свернуть",
            variable_33: "Мой Скиллсет",
            variable_34: "Выберите навык",
            variable_35: "Развернуть",
            variable_36: "Войти",
            variable_37: "Поделись курсом в мессенджерах",
            variable_38: "Приглашай друзей, чтобы получить 2 дополнительных генераций курса. Твои друзья тоже получат 2 генерации курса.",
            variable_39: "Чтобы получить варианты планов обучения выберите желаемый навык",
            variable_40: "План обучения",

        },
        sidebar: {
            variable_1: "Навыки",
            variable_2: "Каталог",
            variable_3: "Профиль",
            variable_4: "Уведомления",
            variable_5: "Генератор",
            variable_6: "Мои курсы",
            variable_7: "Фрилансеры",
            variable_8: "Выход",
            variable_9: "Добавить генерацию",
            variable_10: "",
        }
    }
}
export default obj;