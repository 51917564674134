import React, { useEffect, useState } from 'react';
import { message, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import requests from "../../../../axios/requests";
import { useParams } from "react-router-dom";
import { setConfig, setRange } from "../../../../redux/actions/actions";
import { setUser } from "../../../../redux/actions/userActions";
import { defaultFuncAndData } from "../../../../../utils/defaultData";

import Input from "../../../../components/Input/Input";
import Slider from "./Slider";
import Button from "../../../../components/Button/Button";
import variableLanguage from "../../../../../utils/hookGetVariablesLanguage";
import close_modal from "../../../../../images/generate_course/close_red.svg";

const ModalBuy = ({openModalBuy, setOpenModalBuy}) => {
    const variable_11 = variableLanguage({keyPage: 'chatGptModal', keyVariable: 'variable_11'});
    const variable_14 = variableLanguage({keyPage: 'chatGptModal', keyVariable: 'variable_14'});
    const variable_15 = variableLanguage({keyPage: 'chatGptModal', keyVariable: 'variable_15'});
    const variable_19 = variableLanguage({keyPage: 'chatGptModal', keyVariable: 'variable_19'});
    const variable_20 = variableLanguage({keyPage: 'chatGptModal', keyVariable: 'variable_20'});
    const variable_21 = variableLanguage({keyPage: 'chatGptModal', keyVariable: 'variable_21'});
    const variable_22 = variableLanguage({keyPage: 'chatGptModal', keyVariable: 'variable_22'});
    const variable_23 = variableLanguage({keyPage: 'chatGptModal', keyVariable: 'variable_23'});
    const variable_24 = variableLanguage({keyPage: 'chatGptModal', keyVariable: 'variable_24'});

    const {user, language} = useSelector(state => state.Reducer);
    const dispatch = useDispatch();
    const params = useParams();
    if (params?.token)
        localStorage.setItem("token", params.token)

    const {range, config} = useSelector(state => state.SliderReducer);
    const [result, setResult] = useState(null);
    const [price, setPrice] = useState(null);
    const [discount, setDiscount] = useState(0);

    let buyTries = () => {
        requests.users.buy_education_plan(range >= 5 ? range : 5)
            .then(res => {
                setTimeout(() => window.location.href = res.data.payment_url)
            })
            .catch(err => console.error(err))
    }
    let calcResultPrice = () => {
        let discount = 0;
        for (let i = 5; i < range; i++)
            discount += 0.01;
        setDiscount(Number((discount * 100).toFixed(2)))
        setResult((1 - discount.toFixed(2)) * (Number(price) * range))
    }

    useEffect(() => {
        requests.users.get_config()
            .then(res => {
                dispatch(setConfig(res.data))
                setPrice(res.data.PLAN_STUDENT_RATE)
            })
            .catch(err => console.error(err))

        if (params?.token)
            requests.users.get_profile_new()
                .then(res => dispatch(setUser(res.data)))
    }, [])
    useEffect(() => {
        calcResultPrice()
    }, [range, price, config])
    return (
        <>
            {params?.token &&
                <div className="text count">
                    {language === "ru"
                        ? <>
                            {defaultFuncAndData.declension(user?.education_plan_limit, ["Доступна ", "Доступны ", "Доступны "])}
                            {user?.education_plan_limit}
                            {defaultFuncAndData.declension(user?.education_plan_limit, [" генерация курса", " генерации курса", " генераций курсов"])}
                        </>
                        : <>
                            {variable_20}
                            {user?.education_plan_limit}
                            {variable_21}
                        </>
                    }
                </div>
            }
            <Modal
                open={params?.token ? true : openModalBuy}
                footer={null}
                className={"buy_education"}
                closable={false}
                onCancel={() => {
                    if (!params?.token) { // закрыть модальное если это не web vue
                        setOpenModalBuy(false)
                    }
                }}
                maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.10)"}}
                bodyStyle={{padding: "30px", borderRadius: 40}}
                destroyOnClose={true}
            >
                <div className="title">
                    {variable_19}
                    {!params?.token &&
                        <div
                            className="close"
                            onClick={() => {
                                if (!params?.token) { // закрыть модальное если это не web vue
                                    setOpenModalBuy(false)
                                }
                            }}
                        >
                            <img src={close_modal} alt="close_modal"/>
                        </div>
                    }
                </div>
                <div className="main">
                    <div className="price_row">
                        <div className="label">{variable_22}</div>
                        <div className="value">{range * price}₽</div>
                    </div>
                    <div className="price_row">
                        <div className="label">{variable_23} {discount}%: </div>
                        <div className="value green">{result?.toFixed(0)}₽</div>
                    </div>
                </div>
                <div className="slider_row">
                    <Slider/>
                    <Input
                        padding={"4px 0 5px"}
                        margin={"0 0 0 5px"}
                        value={range}
                        maxWidth={"50px"}
                        setValue={(value) => {
                            if (value > 50) {
                                return dispatch(setRange(50))
                            }
                            /*if (value < 5) {
                                return dispatch(setRange(5))
                            }*/
                            dispatch(setRange(value))
                        }}
                        backgroundColor={"var(--background_lighten-dark)"}
                        onBlur={() => {
                            if(range < 5) {
                                dispatch(setRange(5))
                                message.error(variable_24)
                            }
                        }}
                        textAlign={true}
                        min={5}
                        max={50}
                        type={"number"}
                    />
                </div>

                <Button
                    text={variable_11}
                    click={() => buyTries()}
                    margin={"30px 0 0 0"}
                    maxWidth={"100%"}
                    zIndex={1}
                />

            </Modal>
        </>
    );
};

export default ModalBuy;