import React from 'react';
import moment from "moment/moment";

import fake_photos from "../../../../../../../images/fake_photos.png";
import { ReactComponent as Like } from "../../../../../../../images/icons/like.svg";
import { ReactComponent as Dislike } from "../../../../../../../images/icons/dislike.svg";
import './Comment.scss';

export const Comment = (props) => {
    const {text, created_at, likes_count, dislikes_count, id, setLike, setDislake, user} = props;

    let parseDateComment = (num) =>
         moment(num).format("DD.MM.YYYY - hh:mm");

    return (
        <div className="comment">
            <div className="comment_photo">
                <img
                    src={user.profile && user.profile.avatar || fake_photos}
                    style={{maxWidth: 40, height: 40, borderRadius: 13}}
                />
            </div>
            <div className="comment_fio">
                {user.profile && user.profile.name}
            </div>
            <div className="comment_date">
                {parseDateComment(created_at)}
            </div>
            <div className="comment_text">
                {text}
            </div>
            <div className="comment_like-dislike">
                <Like
                    onClick={() => setLike(id)}
                />
                <span>{likes_count}</span>
                <Dislike
                    onClick={() => setDislake(id)}
                />
                <span>{dislikes_count}</span>
            </div>
        </div>
    );
};

export default Comment;