import React from 'react';
import { Modal } from "antd";
import close from "../../../../../images/generate_course/close.svg";
import PlanningCourse from "../../PlanningCourse/PlanningCourse";

import "./ModalSize.scss";

const ModalSize = ({openModalSize, setOpenModalSize, currentElemSize, changeFavoritePlan, generateQuest, indx, loadingIndx}) => {
    return (
        <Modal
            open={openModalSize}
            footer={null}
            className={"modal_size"}
            closable={false}
            onCancel={() => setOpenModalSize(false)}
            maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.10)"}}
            destroyOnClose={true}
        >
            <div
                className="close"
                onClick={() => setOpenModalSize(false)}
            >
                <img src={close} alt="close"/>
            </div>
            {Object.values(currentElemSize)?.length > 0 &&
                <PlanningCourse
                    {...currentElemSize}
                    key={currentElemSize.id}
                    indx={indx}
                    changeFavoritePlan={changeFavoritePlan}
                    generateQuest={generateQuest}
                    loadingIndx={loadingIndx}
                    isModalSize={true}
                />
            }
        </Modal>
    );
};

export default ModalSize;