import React from 'react';
import { useNavigate } from "react-router-dom";

import { ReactComponent as Arrow } from "../../../../../../../images/arrow.svg";
import variableLanguage from "../../../../../../../utils/hookGetVariablesLanguage";

const TopButtons = (props) => {
    const {
        setDropDownTheme, dropDownTheme, setActiveTheme, dropDownFontSize,
        setDropDownFontSize, arrayFontSize, setActiveFontSize, activeFontSize,
        dropDownLanguage, language, theme, arrayTheme
    } = props;
    const history = useNavigate();
    const variable_10 = variableLanguage({keyPage: "lesson", keyVariable: "variable_10"});

    return (
        <div className="group_buttons">
            <div className="button prev" onClick={() => history("/student/my-courses")}>
                <Arrow/>
                <span>{variable_10}</span>
            </div>
            <>
               {/* <div
                    className={dropDownTheme ? "button open" : "button"}
                    tabIndex={0}
                    onBlur={() => {
                        setTimeout(() => {
                            setDropDownTheme(false);
                        }, 150);
                    }}
                    onClick={() => {
                        setDropDownTheme(!dropDownTheme);
                    }}
                >
                    <span>{theme.label}</span>
                    <Arrow/>
                    <div className={dropDownTheme ? "drop_down open" : "drop_down"}>
                        {arrayTheme.map((elem, indx) =>
                            <div
                                className="item"
                                onClick={() => {
                                    setActiveTheme(indx);
                                    setDropDownTheme(false);
                                }}
                                key={indx}
                            > {elem.label}</div>
                        )}
                    </div>
                </div>*/}
             {/*   <div
                    className={dropDownFontSize ? "button open" : "button"}
                    tabIndex={0}
                    onBlur={() => {
                        setTimeout(() => {
                            setDropDownFontSize(false);
                        }, 150);
                    }}
                    onClick={() => {
                        setDropDownFontSize(!dropDownFontSize);
                    }}
                >
                    <span>{arrayFontSize[activeFontSize]} px</span>
                    <Arrow/>
                    <div className={dropDownFontSize ? "drop_down open" : "drop_down"}>
                        {arrayFontSize.map((elem, indx) =>
                            <div
                                className="item"
                                onClick={() => {
                                    setActiveFontSize(indx);
                                    setDropDownFontSize(false);
                                }}
                                key={indx}
                            > {elem}</div>
                        )}
                    </div>
                </div>*/}
                <div
                    className={dropDownLanguage ? "button open" : "button"}
                    style={{backgroundColor: '#201E26', cursor: 'auto', maxWidth: 105}}
                    tabIndex={0}
                    /*onBlur={() => {
                        setTimeout(() => {
                            setDropDownLanguage(false);
                        }, 150);
                    }}*/
                >
                    <span>{language}</span>
                    <Arrow/>
                  {/*  <div className={dropDownLanguage ? "drop_down open" : "drop_down"}>
                        {arrayLanguage.map((elem, indx) =>
                            <div
                                className="item"
                                onClick={() => {
                                    setDropDownLanguage(false);
                                }}
                                key={indx}
                            > {elem}</div>
                        )}
                    </div>*/}
                </div>
            </>
        </div>
    );
};

export default TopButtons;