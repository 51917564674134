import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { mySaga } from "./saga/AuthorizationSaga";
import {all} from 'redux-saga/effects';
import SearchProfession from "./reducers/searchProfessionReducer";
import Reducer from './reducers/reducer';
import SliderReducer from './reducers/sliderReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const rootReducer = combineReducers({
    Reducer,
    SearchProfession,
    SliderReducer
});
const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware))
);

 function* rootSaga() {
    yield all([
        mySaga(),
    ])
}
sagaMiddleware.run(rootSaga)
export default store;