import React, { useEffect, useState } from 'react';
import requests from "../../../../../axios/requests";
import CustomCheckbox from "../../../../../components/CustomCheckbox";
import Button from "../../../../../components/Button/Button";
import { message, notification } from "antd";
import variableLanguage from "../../../../../../utils/hookGetVariablesLanguage";

import test_result_wrong from "../../../../../../images/icons/test_result_wrong.svg";
import test_result_ok from "../../../../../../images/icons/test_result_ok.svg";
import './Quiz.scss';

const Quiz = ({questions, stage_theory_id, attempts_left, propsKey}) => {
    const variable_16 = variableLanguage({keyPage: "lesson", keyVariable: "variable_16"});
    const variable_17 = variableLanguage({keyPage: "lesson", keyVariable: "variable_17"});
    const variable_18 = variableLanguage({keyPage: "lesson", keyVariable: "variable_18"});
    const variable_20 = variableLanguage({keyPage: "lesson", keyVariable: "variable_20"});
    const variable_23 = variableLanguage({keyPage: "lesson", keyVariable: "variable_23"});

    const [currentQuizIndx, setCurrentQuizIndx,] = useState(0);
    const [data, setData] = useState([]);
    const [quizOver, setQuizOver] = useState(false);
    const [feedbackResult, setFeedbackResult] = useState({});
    const [attempts, setAttempts] = useState(1);

    let showAnswerTitle = (ids) => {
        if (Array.isArray(ids) && ids.length > 0) {
            let result = [];
            if (questions.length > 0) {
                for (let i = 0; i < questions.length; i++) {
                    for (let j = 0; j < ids.length; j++) {
                        let text = questions[i].answers.find(elem => elem.id === ids[j])?.text;
                        if (typeof text !== "undefined") {
                            result = [...result, text + ","];
                        }
                    }
                }
            }
            if (result.length > 0)
                result[result.length - 1] = result[result.length - 1].slice(0, -1);
            return result;
        }
        return "";
    };
    let restartAgain = () => {
        let newData = [...data];
        for (let i = 0; i < newData.length; i++)
            for (let j = 0; j < newData[i].answers.length; j++)
                newData[i].answers[j].value = false;
        setData(newData)
        setFeedbackResult({});
        setCurrentQuizIndx(0);
        setQuizOver(false);
    }
    let sendResult = () => {
        let findCorrectAnswers = (index) => {
            let resultIndxs = [];
            for (let i = 0; i < data[index].answers.length; i++)
                if (data[index].answers[i].value)
                    resultIndxs = [...resultIndxs, data[index].answers[i].id]

            return resultIndxs;
        }
        let answers = {};
        for (let i = 0; i < data.length; i++)
            answers[data[i].id] = findCorrectAnswers(i)
        let dataObj = {
            stage_theory_id,
            answers
        }
        requests.stages
            .post_quizresult(dataObj)
            .then((response) => {
                setQuizOver(true);
                let newQuizAnswers = [];
                let newData = {...response.data};
                if (newData?.quiz_answers) {
                    for (let key in newData.quiz_answers)
                        newQuizAnswers =
                            [...newQuizAnswers, newData.quiz_answers[key]];
                    newData.quiz_answers = newQuizAnswers;
                }
                setFeedbackResult(newData)
                if (typeof newData?.attempts_left !== 'undefined') {
                    if (newData.attempts_left > 0) {
                        notification["warn"]({
                            message: variable_17,
                            description: `${variable_18} ${newData.attempts_left}`
                        })
                    } else {
                        message.error(variable_16)
                        setAttempts(0);
                    }
                }
            })
            .catch((error) => {
                console.log(error.response);
            });
    }
    let nextQuestion = () => {
        let flag = false;
        for (let i = 0; i < data[currentQuizIndx].answers.length; i++) {
            if (data[currentQuizIndx].answers[i].value)
                flag = true;
        }
        if (!flag)
            return;
        if (currentQuizIndx !== (data?.length - 1))
            setCurrentQuizIndx(prev => prev + 1)
        else
            sendResult();
    }

    useEffect(() => {
        let newData = [...questions];
        for (let i = 0; i < newData.length; i++)
            for (let j = 0; j < newData[i].answers.length; j++)
                newData[i].answers[j].value = false;
        setData(newData)
    }, [questions])
    return (
        <>
            {attempts_left !== 0
                ? <>
                    <div className="quiz_page">
                        {quizOver
                            ? <>
                                {(feedbackResult?.count_right === questions.length)
                                    ? <div className="title_result">
                                        <img src={test_result_ok} alt="success test"/>
                                        <span>{variable_20}</span>
                                    </div>
                                    : <div className="title_result">
                                        <img src={test_result_wrong} alt="wrong test"/>
                                        <span>Вы допустили ошибки</span>
                                    </div>
                                }
                                <div className={"result_list"}>
                                    {feedbackResult?.quiz_answers?.length > 0 &&
                                        feedbackResult.quiz_answers.map((elem, ind) => {
                                                return (
                                                    <div key={ind + "random_1"}>
                                                        <div className={elem.correct ? "index true" : "index false"}>
                                                            {ind + 1}.
                                                        </div>
                                                        {showAnswerTitle(elem?.answers).map((answ, i) =>
                                                            <span
                                                                className={elem.correct ? "index true" : "index false"}
                                                                style={{textAlign: "start", width: 'fit-content'}}
                                                                key={i}
                                                            >
                                                                {answ}
                                                            </span>
                                                        )}
                                                    </div>
                                                );
                                            }
                                        )}
                                </div>
                            </>
                            : <>
                                <div className="title_quiz">
                                    <span>{data[currentQuizIndx]?.title}</span>
                                    <div className="count_quiz">
                                        <span>Вопрос {currentQuizIndx + 1}</span>
                                        <span>/ {questions?.length}</span>
                                    </div>
                                </div>
                                <div className="variants_list">
                                    {data?.length > 0 &&
                                        data[currentQuizIndx].answers.map((variant, indx) =>
                                            <div
                                                className={data[currentQuizIndx].answers[indx].value
                                                    ? "variant active"
                                                    : "variant"
                                                }
                                                key={variant.id}
                                            >
                                                <CustomCheckbox
                                                    value={data[currentQuizIndx].answers[indx].value}
                                                    setValue={(e) => {
                                                        let newData = [...data];
                                                        newData[currentQuizIndx].answers[indx].value = e;
                                                        setData(newData)
                                                    }}
                                                    label={variant.text}
                                                    id={String(variant.id) + propsKey}
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                            </>
                        }
                    </div>
                    {Object.values(feedbackResult).length > 0 &&
                        <>
                            {attempts > 0 &&
                                <Button
                                    text={"Пройти заново"}
                                    click={() => restartAgain()}
                                    maxWidth={"200px"}
                                    margin={"20px 0 0 0"}
                                    boxShadow={"none"}
                                />
                            }
                        </>
                    }
                    {Object.values(feedbackResult).length === 0 &&
                        <Button
                            text={currentQuizIndx === (data?.length - 1)
                                ? "Завершить"
                                : "Следующий вопрос"
                            }
                            click={() => nextQuestion()}
                            maxWidth={"200px"}
                            margin={"20px 0 0 0"}
                            boxShadow={"none"}
                        />
                    }
                    {feedbackResult?.response &&
                        <div className="recomendation_quiz">
                            <div className="h2">
                                {variable_23}
                            </div>
                            <span>{feedbackResult.response}</span>
                        </div>
                    }
                </>
                : <div className="result_attemps-denied">
                    <img src={test_result_wrong} alt="attempts_left"/>
                    <span>{variable_16}</span>
                </div>
            }
        </>
    );
};

export default Quiz;