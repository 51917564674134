let initialState = {
    userSkills: [],
    trajectorySkills: [],
    language: '',
    activeTheme: true,
    myRegion: localStorage.getItem("region") || "",
    user: {},
    resizeWindow: 0,
    scrollDirection: null,
    isLandingWeb: false,
    config: null,
    collapse: true,
    flagSteps: false,
    scrollUp: false,
    showModalBuy: false
};
const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_USER_SKILLS":
            return {
                ...state,
                userSkills: action.payload
            };
        case 'SET_USER_TRAJECTORY':
            return {
                ...state,
                trajectorySkills: action.payload
            };

        case 'SET_USER_REGION':
            return {
                ...state,
                myRegion: action.payload
            };
        case 'CHANGE_LANGUAGE':
            return {
                ...state,
                language: action.payload
            };
        case "CHANGE_THEME":
            return {
                ...state,
                activeTheme: action.payload
            };
        case "SET_USER":
            return {
                ...state,
                user: action.payload
            };
        case "RESIZE_WINDOW":
            return {
                ...state,
                resizeWindow: action.payload
            };
        case "SCROLL_DIRECTION":
            return {
                ...state,
                scrollDirection: action.payload
            };
        case "SET_IS_LANDING_WEB":
            return {
                ...state,
                isLandingWeb: action.payload
            };
        case "SET_CONFIG":
            return {
                ...state,
                config: action.payload
            };
        case "SET_COLLAPSE":
            return {
                ...state,
                collapse: action.payload
            };
        case "SET_FLAG_STEPS":
            return {
                ...state,
                flagSteps: action.payload
            };
        case "SET_SCROLL_UP":
            return {
                ...state,
                scrollUp: action.payload
            };
        case "SET_SHOW_GENERATE_BUY":
            return {
                ...state,
                showModalBuy: action.payload
            };

        default:
            return state;
    }
}
export default Reducer;