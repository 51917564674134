import React, { useEffect, useRef, useState } from 'react';
import requests from "../../../../../axios/requests";
import Comments from "../Comments/Comments";
import './Scorm.scss';

const Scorm = ({taskProps, setTriggerLmsEnd}) => {

    const [timeoutLms, setTimeoutLms] = useState(null);
    const [resultData, setResultData] = useState(null);
    const [lastRequest, setLastRequest] = useState(null);
    const [comments, setComments] = useState("");
    const [valueComment, setValueComment] = useState("");

    let refInteractionsCount = useRef(0);
    let refData = useRef(null);

    let splitUrl = () => {
        let oldUrl = process.env.REACT_APP_API_URL.split("skillometer");
        return `${oldUrl[0]}web.skillometer${oldUrl[1]}`
    }
    let sendApiLms = (data) => {
        requests.stages.lms_data(data, taskProps?.id)
            .then(() => {
                if (data && typeof data?.raw_data !== "undefined"
                    && typeof data.raw_data["cmi.core.lesson_status"] !== "undefined"
                    && data.raw_data["cmi.core.lesson_status"] === "completed") {
                    setTriggerLmsEnd(prev => prev + 1);
                    clearTimeout(timeoutLms);
                }
            })
            .catch(err => console.error(err))
    }
    let getApiComments = () => {
        requests.comments.get_comment_matherial({
            contentType: 46,
            objectId: taskProps.id
        })
            .then((res) => setComments(res.data))
            .catch((error) => console.log(error, "error get_comments"));
    };
    let sendComment = () => {
        if (!valueComment)
            return;

        let obj = {
            user: {
                profile: {
                    avatar: user.avatar
                },
            },
            content_type: 46,
            object_id: taskProps.id,
            text: valueComment
        };
        requests.comments.add_comment_matherial(obj)
            .then((res) => setComments((prev) => [...prev, res.data]))
            .catch((error) => console.log(error, "error add_comment"));
        setValueComment("");
    };
    let handleSetLikeDislake = (id, value) => {
        let obj = {
            "content_type": 86,
            "object_id": id,
            "value": value
        };
        requests.comments.set_like(obj)
            .then(() => getApiComments())
            .catch((error) => console.error(error))
    };

    window.API = {
        LMSInitialize() {
            requests.stages.lmg_initialize(taskProps?.id)
                .catch(err => console.error(err))
            return true
        },
        LMSFinish() {
            clearTimeout(timeoutLms);
            refData.current = null;
            requests.stages.lmg_finish(taskProps?.id)
                .then(() => {
                    clearTimeout(timeoutLms);
                    refData.current = null;
                })
                .catch(err => console.error(err))
            return true
        },
        LMSGetValue(value) {
            if (value === "cmi.interactions._count") {
                let prevIterationCount = JSON.parse(JSON.stringify(refInteractionsCount));
                refInteractionsCount.current = prevIterationCount.current + 1;
                return prevIterationCount.current;
            }
            return "";
        },
        LMSSetValue(value, value_2) {
            let obj = {};
            if (refData?.current) {
                obj = {...refData?.current?.raw_data}
                obj[value] = value_2;
            } else {
                obj = {[value]: value_2};
            }
            let newObj = {
                "raw_data": obj
            }
            refData.current = newObj
            setResultData(newObj);
        },
        LMSCommit() {
            return true;
        },
        GetLastError(value) {
            console.log(value, 'GetLastError ')
            return value;
        }
    };

    useEffect(() => {
        getApiComments()
        clearTimeout(timeoutLms);
        refData.current = null;
        return () => {
            clearTimeout(timeoutLms);
            refData.current = null;
        }
    }, [taskProps?.id])
    useEffect(() => {
    }, [window.API.LMSSetValue]);
    useEffect(() => {
        if (refData?.current && !taskProps?.seen) {
            clearTimeout(timeoutLms);
            setTimeoutLms(setTimeout((data) => {
                sendApiLms(data);
                setLastRequest(data);
            }, 1000, refData.current))
        }
    }, [refData.current])
    /*
    // внедрить script в  iframe. Может пригодится
    const iframe = document.getElementById('myIframe');
       if(iframe) {
           const iframeWin = iframe.contentWindow || iframe;
           const iframeDoc = iframe.contentDocument || iframeWin.document;

           function injectThis() {
               console.log(window,'injectThis in iframe')
               window.addEventListener('message', receiveMessage, false);
               function receiveMessage(event) {
                   const data = event.data;
                   console.log(data,'data event')
                   // здесь можно обработать полученные данные
               }
           };

           var script = iframeDoc.createElement("script");
           script.append('window.onload = ' + injectThis.toString() + ';');
           iframeDoc.documentElement.appendChild(script);
       }*/
    return (
        <div className="scorm">
            {taskProps.title &&
                <div className="title">{taskProps.title}</div>
            }
            <iframe
                src={splitUrl() + `v2/web/student/stage/stage_theory/${taskProps?.id}/lms/main`}
                width={"100%"}
                id={'myIframe'}
                key={taskProps?.id}
            />
            {window.innerWidth > 820 &&
                <Comments
                    comments={comments}
                    sendComment={sendComment}
                    handleSetLikeDislake={handleSetLikeDislake}
                    valueComment={valueComment}
                    setValueComment={setValueComment}
                />
            }
        </div>
    );
};

export default Scorm;