import React, { useEffect, useState } from 'react';
import moment from "moment/moment";
import { constantsResume } from "../../Profile/constantsResume";
import requests from "../../../axios/requests";
import variableLanguage from "../../../../utils/hookGetVariablesLanguage";

import fake_photos from "../../../../images/fake_photos.png";

const Resume = ({flagAuth, completedCourses, skillTags}) => {

    const variable_46 = variableLanguage({keyPage: "landing", keyVariable: "variable_46"});
    const variable_50 = variableLanguage({keyPage: "landing", keyVariable: "variable_50"});
    const variable_51 = variableLanguage({keyPage: "landing", keyVariable: "variable_51"});

    const [dataResume, setDataResume] = useState({});
    const [profile, setProfile] = useState(null);

    let getApiResume = () => {
        requests.profile.get_profile_resume()
            .then((res) => {
                setDataResume(res.data);
            })
            .catch(err => console.error(err))
    }
    let getProfileUser = () => {
        requests.users.get_profile_new()
            .then((res) => setProfile(res.data))
            .catch(err => console.error(err))
    }

    useEffect(() => {
        if (localStorage.getItem("token")) {
            getProfileUser()
            getApiResume();
        }
    }, []);
    return (
        <>
            {flagAuth &&
                <>
                    <div className="resume_section">
                        <div className="title">{variable_46}</div>
                        <div className="resume">
                            <div className="image">
                                <img src={profile?.avatar || fake_photos}/>
                            </div>
                            <div className="detail">
                                <div className="full_name">{profile?.fio}</div>
                                {dataResume?.position &&
                                    <div className="label_resume">
                                        <span>{dataResume.position}</span>
                                        {dataResume?.salary > 0 &&
                                            <span>
                                                {dataResume.salary}
                                                {" "}
                                                {constantsResume.currencies[dataResume.salary_currency]}
                                            </span>
                                        }
                                    </div>
                                }
                                {skillTags && skillTags.length > 0 &&
                                    <>
                                        <div className="h2">{variable_50}</div>
                                        <div className="preview_skills">
                                            {skillTags?.map((skill, indx) =>
                                                <div
                                                    className={"skill"}
                                                    key={indx}
                                                >
                                                    {skill}
                                                </div>
                                            )}
                                        </div>
                                    </>

                                }
                                {completedCourses.length > 0 &&
                                    <>
                                        <div className="h2">{variable_51}</div>
                                        <div className="parent_course">
                                            {
                                                completedCourses.map((course) =>
                                                    <div className="course" key={course.id}>
                                                        <div className="year_end">
                                                            <span>
                                                                {
                                                                    course.passed_date && String(course.passed_date).includes("T")
                                                                        ? moment(course.passed_date).format("YYYY")
                                                                        : course.passed_date
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="info">
                                                            <div
                                                                className="title">
                                                                {course.name}
                                                            </div>
                                                            <div className="text">
                                                                {course.description}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="title_resume-mobile">{variable_46}</div>
                    <div className="resume_section-mobile">
                        <div className="resume">
                            <div className="top_container">
                                <div className="image">
                                    <img src={profile?.avatar || fake_photos}/>
                                </div>
                                <div className="label_resume">
                                    {dataResume?.position &&
                                        <div className="position">
                                            {dataResume?.position}
                                        </div>
                                    }
                                    {dataResume?.salary > 0 &&
                                        <div
                                            className="salary">
                                            {dataResume.salary}{constantsResume.currencies[dataResume.salary_currency]}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="full_name">
                                {profile?.fio}
                            </div>
                            <div className="parent_course">
                                {skillTags && skillTags.length > 0 &&
                                    <>
                                        <div className="h2">
                                            {variable_50}
                                        </div>
                                        <div className="preview_skills">
                                            {skillTags?.map((skill, indx) =>
                                                <div
                                                    className={"skill"}
                                                    key={indx}
                                                >
                                                    {skill}
                                                </div>
                                            )}
                                        </div>
                                    </>
                                }
                                {completedCourses.length > 0 &&
                                    <>
                                        <div className="h2">
                                            {variable_51}
                                        </div>
                                        <div className="courses">
                                            {completedCourses.map((course) =>
                                                <div className="course" key={course.id}>
                                                    <div className="year_end">
                                                        {
                                                            course.passed_date && String(course.passed_date).includes("T")
                                                                ? moment(course.passed_date).format("YYYY")
                                                                : course.passed_date
                                                        }
                                                    </div>
                                                    <div className="info">
                                                        <div
                                                            className="title">
                                                            {course.name}
                                                        </div>
                                                        <div className="text">
                                                            {course.description}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
};

export default Resume;