import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import constantsLanguages from "../student/data/constantsLanguages";

const HookGetVariablesLanguage = (props) => {
    const [value, setValue] = useState(null);
    const {language} = useSelector(state => state.Reducer);

    useEffect(() => {
        return () => setValue(null)
    }, [])
    useEffect(() => {
        if(props.keyPage && props.keyVariable && language) {
            setValue(constantsLanguages[language][props.keyPage][props.keyVariable]);
        }
    }, [language])

    return value || ''
};

export default HookGetVariablesLanguage;