import React, { useEffect, useRef, useState } from 'react';
import Slider from "react-slick";
import { ReuseDots } from "../Landing";

import format_study1 from "../../../../images/landing/format_study1.svg";
import format_study2 from "../../../../images/landing/format_study2.svg";
import format_study3 from "../../../../images/landing/format_study3.svg";
import format_study6 from "../../../../images/landing/format_study6.svg";
import format_study5 from "../../../../images/landing/format_study5.svg";
import format_study4 from "../../../../images/landing/format_study4.svg";
import variableLanguage from "../../../../utils/hookGetVariablesLanguage";

const FormatStudy = ({data, flagShowFormat, formatStudyRef, formatStudyRefMobile}) => {

    const variable_7 = variableLanguage({keyPage: "landing", keyVariable: "variable_7"});
    const variable_8 = variableLanguage({keyPage: "landing", keyVariable: "variable_8"});
    const variable_9 = variableLanguage({keyPage: "landing", keyVariable: "variable_9"});
    const variable_10 = variableLanguage({keyPage: "landing", keyVariable: "variable_10"});
    const variable_11 = variableLanguage({keyPage: "landing", keyVariable: "variable_11"});
    const variable_12 = variableLanguage({keyPage: "landing", keyVariable: "variable_12"});
    const variable_13 = variableLanguage({keyPage: "landing", keyVariable: "variable_13"});
    const variable_14 = variableLanguage({keyPage: "landing", keyVariable: "variable_14"});
    const variable_16 = variableLanguage({keyPage: "landing", keyVariable: "variable_16"});
    const variable_17 = variableLanguage({keyPage: "landing", keyVariable: "variable_17"});
    const variable_43 = variableLanguage({keyPage: "landing", keyVariable: "variable_43"});
    const variable_44 = variableLanguage({keyPage: "landing", keyVariable: "variable_44"});
    const variable_45 = variableLanguage({keyPage: "landing", keyVariable: "variable_45"});

    const [activeSlideFormatStudyMobile, setActiveSlideFormatStudyMobile] = useState(0);
    const settingsFormatStudyMobile = {
        /*dots: true,*/
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: "format_study-slides-mobile",
        arrows: false,
        initialSlide: activeSlideFormatStudyMobile
    };
    const carouselRefFormatStudy = useRef(null);

    let countFormatMatherial = () => {
        let count = 0;
        if (data !== null && Object.keys(data).length !== 0) {
            if (data.progress.count_video > 0) count += 1;
            if (data.progress.count_quiz > 0) count += 1;
            if (data.progress.count_article > 0) count += 1;
            if (data.progress.count_task > 0) count += 1;
            if (data.progress.count_code_task > 0) count += 1;
            if (data.quest_speakers?.length > 0) count += 1;
        }
        return count;
    };

    useEffect(() => {
        if (carouselRefFormatStudy.current?.slickGoTo)
            carouselRefFormatStudy.current.slickGoTo(activeSlideFormatStudyMobile);
    }, [activeSlideFormatStudyMobile]);
    return (
        <>
            {flagShowFormat
                ? <>
                    <div ref={formatStudyRef} className="format_study">
                        <div className="title">
                            {variable_7}
                        </div>
                        <ul className="list">
                            {data?.progress.count_video > 0 &&
                                <li>
                                    <div className="top">
                                        <div className="title">{variable_8}</div>
                                        <div className="info">{variable_9}</div>
                                    </div>
                                    <div className="bottom">
                                        <img src={format_study1}/>
                                    </div>
                                </li>
                            }
                            {data?.progress.count_quiz > 0 &&
                                <li>
                                    <div className="top">
                                        <div className="title">{variable_10}</div>
                                        <div className="info">{variable_11}</div>
                                    </div>
                                    <div className="bottom">
                                        <img src={format_study2}/>
                                    </div>
                                </li>
                            }
                            {data?.progress.count_article > 0 &&
                                <li>
                                    <div className="top">
                                        <div className="title">{variable_12}</div>
                                        <div className="info">{variable_13}</div>
                                    </div>
                                    <div className="bottom">
                                        <img src={format_study3}/>
                                    </div>
                                </li>
                            }
                            {data?.progress.count_task > 0 &&
                                <li>
                                    <div className="top">
                                        <div className="title">{variable_14}</div>
                                        <div className="info">{variable_44}</div>
                                    </div>
                                    <div className="bottom">
                                        <img src={format_study6}/>
                                    </div>
                                </li>
                            }
                            {data?.progress.count_code_task > 0 &&
                                <li>
                                    <div className="top">
                                        <div className="title">{variable_43}</div>
                                        <div className="info">{variable_45}</div>
                                    </div>
                                    <div className="bottom">
                                        <img src={format_study5}/>
                                    </div>
                                </li>
                            }
                            {data?.quest_speakers?.length > 0 &&
                                <li>
                                    <div className="top">
                                        <div className="title">{variable_16}</div>
                                        <div className="info">{variable_17}</div>
                                    </div>
                                    <div className="bottom">
                                        <img src={format_study4}/>
                                    </div>
                                </li>
                            }
                        </ul>
                    </div>
                    <div className="format_study-mobile" ref={formatStudyRefMobile}>
                        <div className="title">{variable_7}</div>
                        <Slider
                            {...settingsFormatStudyMobile}
                            afterChange={(e) => {
                                setActiveSlideFormatStudyMobile(e);
                            }}
                            ref={carouselRefFormatStudy}
                        >
                            {data?.progress.count_video > 0 &&
                                <div className={"parent_slider-item"}>
                                    <div className="slider_item">
                                        <div className="top">
                                            <div className="title">{variable_8}</div>
                                            <div className="info">{variable_9}</div>
                                        </div>
                                        <div className="bottom">
                                            <img src={format_study1}/>
                                        </div>
                                    </div>
                                </div>
                            }
                            {data?.progress.count_quiz > 0 &&
                                <div className={"parent_slider-item"}>
                                    <div className="slider_item">
                                        <div className="top">
                                            <div className="title">{variable_10}</div>
                                            <div className="info">{variable_11}</div>
                                        </div>
                                        <div className="bottom">
                                            <img src={format_study2}/>
                                        </div>
                                    </div>
                                </div>
                            }
                            {data?.progress.count_article > 0 &&
                                <div className={"parent_slider-item"}>
                                    <div className="slider_item">
                                        <div className="top">
                                            <div className="title">{variable_12}</div>
                                            <div className="info">{variable_13}</div>
                                        </div>
                                        <div className="bottom">
                                            <img src={format_study3}/>
                                        </div>
                                    </div>
                                </div>
                            }
                            {data?.progress.count_task > 0 &&
                                <div className={"parent_slider-item"}>
                                    <div className="slider_item">
                                        <div className="top">
                                            <div className="title">{variable_14}</div>
                                            <div className="info">{variable_44}</div>
                                        </div>
                                        <div className="bottom">
                                            <img src={format_study6}/>
                                        </div>
                                    </div>
                                </div>
                            }
                            {data?.progress?.count_code_task > 0 &&
                                <div className={"parent_slider-item"}>
                                    <div className="slider_item">
                                        <div className="top">
                                            <div className="title">{variable_43}</div>
                                            <div className="info">{variable_45}</div>
                                        </div>
                                        <div className="bottom">
                                            <img src={format_study5}/>
                                        </div>
                                    </div>
                                </div>
                            }
                            {data?.quest_speakers?.length > 0 &&
                                <div className={"parent_slider-item"}>
                                    <div className="slider_item">
                                        <div className="top">
                                            <div className="title">{variable_16}</div>
                                            <div className="info">{variable_17}</div>
                                        </div>
                                        <div className="bottom">
                                            <img src={format_study4}/>
                                        </div>
                                    </div>
                                </div>
                            }
                        </Slider>
                        <ReuseDots
                            activeSlide={activeSlideFormatStudyMobile}
                            setActiveSlide={(indx) => setActiveSlideFormatStudyMobile(indx)}
                            countDots={countFormatMatherial()}
                        />
                    </div>
                </>
                : <></>
            }
        </>
    );
};

export default FormatStudy;