import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import requests from "../../axios/requests";
import { defaultFuncAndData } from "../../../utils/defaultData";
import { setUserTrajectory } from "../../redux/actions/userActions";
import { motion } from "framer-motion/dist/framer-motion";
import { setScrollUp } from "../../redux/actions/actions";

import variableLanguage from "../../../utils/hookGetVariablesLanguage";
import { Modal, Spin, message, Pagination } from "antd";
import TextArea from "antd/es/input/TextArea";
import { CourseCard } from "../../components/CourseCard";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import HeaderNavigation from "../../components/HeaderNavigation/HeaderNavigation";
import Tag from "../../components/Tag/Tag";

import close_tag from "../../../images/icons/close_tag.svg";
import settings_mobile from "../../../images/icons/settings_mobile.svg";
import settings_mobile_category from "../../../images/icons/settings_mobile-category.svg";
import close_modal_grey from "../../../images/icons/close_modal-course.svg";
import study_traektory from "../../../images/icons/study_traektory.svg";
import { ReactComponent as Arrow } from "../../../images/arrow.svg";
import { ReactComponent as Logo } from "../../../images/icons/logo.svg";
import { ReactComponent as RateFeedback } from "../../../images/icons/rate_feedback.svg";

const MyCourse = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const {trajectorySkills} = useSelector(state => state.Reducer);

    const variables_1 = variableLanguage({keyPage: 'myCourses', keyVariable: 'variables_1'})
    const variables_5 = variableLanguage({keyPage: 'myCourses', keyVariable: 'variables_5'})
    const variables_6 = variableLanguage({keyPage: 'myCourses', keyVariable: 'variables_6'})
    const variables_7 = variableLanguage({keyPage: 'myCourses', keyVariable: 'variables_7'})
    const variables_8 = variableLanguage({keyPage: 'myCourses', keyVariable: 'variables_8'})
    const variables_9 = variableLanguage({keyPage: 'myCourses', keyVariable: 'variables_9'})
    const variables_10 = variableLanguage({keyPage: 'myCourses', keyVariable: 'variables_10'})
    const variables_11 = variableLanguage({keyPage: 'myCourses', keyVariable: 'variables_11'})
    const variables_12 = variableLanguage({keyPage: 'myCourses', keyVariable: 'variables_12'})
    const variables_13 = variableLanguage({keyPage: 'myCourses', keyVariable: 'variables_13'})
    const variables_14 = variableLanguage({keyPage: 'myCourses', keyVariable: 'variables_14'})
    const variables_15 = variableLanguage({keyPage: 'myCourses', keyVariable: 'variables_15'})
    const variables_16 = variableLanguage({keyPage: 'myCourses', keyVariable: 'variables_16'})
    const variables__8 = variableLanguage({keyPage: 'searchProfession', keyVariable: 'variables_8'})
    const variables__28 = variableLanguage({keyPage: 'searchProfession', keyVariable: 'variables_28'})

    const [activeSortCatalog, setActiveSortCatalog] = useState(0);
    const [activeCategory, setActiveCategory] = useState(0);
    const [dataMyCourse, setDataMyCourse] = useState([]);
    const [userTags, setUserTags] = useState(null);
    const [activeCourseModal, setActiveCourseModal] = useState({});
    const [activeRate, setActiveRate] = useState(0);
    const [feedbackValue, setFeedbackValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState([]);
    const [searchCourse, setSearchCourse] = useState("");

    const [flagDropDownCategory, setFlagDropDownCategory] = useState(false);
    const [flagModalSettingsMobile, setFlagModalSettingsMobile] = useState(false);
    const [flagModalTraektoryMobile, setFlagModalTraektoryMobile] = useState(false);
    const [flagModalFeedback, setFlagModalFeedback] = useState(false);
    const [loading, setLoading] = useState(false);

    const sortCatalog = [
        variableLanguage({keyPage: 'myCourses', keyVariable: 'variables_17'}),
        variableLanguage({keyPage: 'myCourses', keyVariable: 'variables_2'}),
        variableLanguage({keyPage: 'myCourses', keyVariable: 'variables_3'}),
        variableLanguage({keyPage: 'myCourses', keyVariable: 'variables_4'}),
    ];
    const arrCategory = [
        <span>{variableLanguage({keyPage: 'authorization', keyVariable: 'variables_49'})}</span>,
        <span>{variableLanguage({keyPage: 'authorization', keyVariable: 'variables_36'})}</span>,
        <span>{variableLanguage({keyPage: 'authorization', keyVariable: 'variables_31'})}</span>,
        <span>{variableLanguage({keyPage: 'authorization', keyVariable: 'variables_33'})}</span>,
        <span>{variableLanguage({keyPage: 'authorization', keyVariable: 'variables_30'})}</span>,
        <span>{variableLanguage({keyPage: 'authorization', keyVariable: 'variables_32'})}</span>,
        <span>{variableLanguage({keyPage: 'authorization', keyVariable: 'variables_35'})}</span>,
        <span>{variableLanguage({keyPage: 'authorization', keyVariable: 'variables_34'})}</span>
    ];
    const page_size = 20;

    let sendFeedback = () => {
        if (activeRate === 0) return message.error(variables_14)
        requests.quests.post_quest_review(activeCourseModal.id, activeRate, feedbackValue)
            .then((res) => {
                message.success(variables_15)
                setFlagModalFeedback(false);
            })
            .catch(err => console.error(err))
    }
    let getApiMyCourse = () => {
        let str;
        switch (activeSortCatalog) {
            case 0:
                str = 'all';
                break;
            case 1:
                str = 'current';
                break;
            case 2:
                str = 'coming';
                break;
            case 3:
                str = 'finished';
                break;
        }
        requests.quests.get_quest_quest([], currentPage, page_size, activeCategory, searchCourse, str, false, "")
            .then(res => {
                setDataMyCourse(res.data.results);
                setTotal(res.data.count)
            })
            .catch((error) => console.log("ошибка get_quest_quest", error))
            .finally(() => setLoading(false));
    }
    let handleRemoveSkillBasket = (skill) => {
        let newArr;
        for (let i = 0; i < trajectorySkills.length; i++) {
            if (trajectorySkills[i].name === skill.name) {
                newArr = trajectorySkills.filter(t => t.name !== skill.name);
                break;
            }
        }
        dispatch(setUserTrajectory(newArr))
        let name = skill.name;
        let obj = {name};
        requests.quests.remove_trajectory_skill(obj)
            .catch((e) => {
                console.error(e, "error remove_trajectory_skill ");
            });
    };

    useEffect(() => {
        setLoading(true);

        (async () => {
            const userTags = (await requests.quests.get_user_tags()).data;
            let tagsArr = [];
            Object.keys(userTags).map((key) => {
                for (let i = 0; i < userTags[key].tags.length; i++) {
                    tagsArr = [...tagsArr, userTags[key].tags[i]];
                }
            });
            setUserTags(tagsArr);
        })();
    }, [])
    useEffect(() => {
        if (dataMyCourse && dataMyCourse.length > 0) {
            let arrLearnTag = [];
            for (let i = 0; i < dataMyCourse.length; i++) {
                if (dataMyCourse[i].progress.progress === 100) {
                    for (let j = 0; j < dataMyCourse[i].speciality_tag.length; j++) {
                        if (!arrLearnTag.includes(dataMyCourse[i].speciality_tag[j]))
                            arrLearnTag = [...arrLearnTag, dataMyCourse[i].speciality_tag[j]];
                    }
                }
            }
        }
    }, [dataMyCourse]);
    useEffect(() => {
        getApiMyCourse()
        setSearchCourse("")
    }, [activeCategory, activeSortCatalog]);
    useEffect(() => {
        getApiMyCourse()
        dispatch(setScrollUp(true)); // проскорлить вверх
    }, [currentPage]);
    useEffect(() => {
        setCurrentPage(1)
        getApiMyCourse()
    }, [searchCourse]);
    return (
        <motion.div
            className="my_course"
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        >
            <div className="top_block-mobile">
                <div className={"top_filter"}>
                    <div className="icon">
                        <Logo
                            width={38}
                            height={38}
                            onClick={() => window.open("https://skillometer.ru/", 'blank')}
                        />
                    </div>
                    <Input
                        padding={"4px 0 6px 40px"}
                        value={searchCourse}
                        setValue={setSearchCourse}
                        backgroundColor={"transparent"}
                        placeholder={variables_16}
                        needFindIconMobile={true}
                    />
                    <div
                        className="settings"
                        onClick={() => {
                            setFlagModalSettingsMobile(true);
                        }}
                    >
                        <img src={settings_mobile}/>
                    </div>
                </div>
            </div>
            <HeaderNavigation
                activeNavigation={1}
                setActiveNavigation={(index) => {
                    if (index === 0) history("/student/course-list");
                }}
                title={variables_6}
                textVariable_1={variables_5}
                textVariable_2={variables_6}
            />
            <div className="group_traektory">
                <div className="my_skills">
                    <div className="title">{variables_7}</div>
                    <div className="skill_block">
                        {userTags && userTags.length > 0 &&
                            userTags.map((elem, indx) =>
                                <div
                                    className={elem.status === 'learned' ? "skill" : "skill added"}
                                    key={indx}
                                >
                                    {elem.name}
                                </div>
                            )}
                    </div>
                </div>
                <div className="need_skills">
                    <div className="title">{variables_8}</div>
                    <div className="skill_block">
                        {trajectorySkills && trajectorySkills.length > 0 &&
                            trajectorySkills.map((elem) =>
                                <Tag
                                    elem={elem}
                                    removeTag={handleRemoveSkillBasket}
                                    key={elem.id}
                                />
                            )}
                    </div>
                </div>
            </div>
            <div className="sort">
                <Input
                    padding={"5px 96px 7px 46px"}
                    value={searchCourse}
                    setValue={setSearchCourse}
                    backgroundColor={"transparent"}
                    maxWidthParent={"420px"}
                    widthParent={"100%"}
                    needFindBtn={true}
                    placeholder={variables_16}
                />
                <ul className="list">
                    <li>{variables_9}</li>
                    {sortCatalog.map((elem, indx) =>
                        <li
                            key={indx}
                            onClick={() => setActiveSortCatalog(indx)}
                            style={activeSortCatalog === indx ? {color: "#B3DAFF"} : {}}
                        >{elem}</li>
                    )}
                </ul>
                <div className="category">
                    <span>{variables_10}</span>
                    <div
                        className={flagDropDownCategory ? "select_block active" : "select_block"}
                        onClick={() => setFlagDropDownCategory(!flagDropDownCategory)}
                    >
                        <span style={{color: "#B3DAFF"}}>{arrCategory[activeCategory]}</span>
                        <Arrow/>
                        <ul className={flagDropDownCategory ? "drop_down-category active" : "drop_down-category"}>
                            {arrCategory.map((elem, indx) =>
                                <li key={indx} onClick={() => {
                                    setActiveCategory(indx);
                                    setFlagDropDownCategory(false);
                                }}>{elem}</li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="my_course-list">
                {loading &&
                    <Spin style={{position: "absolute"}}/>
                }
                <ul>
                    {dataMyCourse && dataMyCourse.length > 0 &&
                        dataMyCourse.map((course, indx) => {
                                if (activeSortCatalog === 0)
                                    return (
                                        <li key={indx} className={"reuse_course-item"}>
                                            <CourseCard
                                                course={course}
                                                progress={true}
                                                redirectUrl={course.id}
                                            />
                                        </li>
                                    )
                                if (activeSortCatalog === 1)
                                    return (
                                        <li key={indx} className={"reuse_course-item"}>
                                            <CourseCard
                                                course={course}
                                                progress={true}
                                                dateCourse={true}
                                                redirectUrl={course.id}
                                            />
                                        </li>
                                    )
                                if (activeSortCatalog === 2)
                                    return (
                                        <li key={indx} className={"reuse_course-item"}>
                                            <CourseCard
                                                course={course}
                                                dateCourse={true}
                                                redirectUrl={course.id}
                                            />
                                        </li>
                                    )
                                if (activeSortCatalog === 3)
                                    return (
                                        <li key={indx} className={"reuse_course-item"}>
                                            <CourseCard
                                                course={course}
                                                btnSetRate={true}
                                                redirectUrl={course.id}
                                                setOpenModal={(course) => {
                                                    setFlagModalFeedback(true);
                                                    setActiveCourseModal(course);
                                                }}
                                            />
                                        </li>
                                    )
                            }
                        )
                    }
                </ul>
                {total && total > page_size
                    ? <div className="pagination_all">
                        <Pagination
                            itemRender={defaultFuncAndData.itemRenderPagination}
                            defaultCurrent={1}
                            defaultPageSize={page_size}
                            total={total}
                            onChange={(e) => setCurrentPage(e)}
                            showTitle={false}
                        />
                    </div>
                    : <></>
                }
            </div>
            <>
                <Modal
                    open={flagModalFeedback}
                    footer={null}
                    className={"modal_feedback_course"}
                    closable={true}
                    closeIcon={<img style={{cursor: 'pointer'}} src={close_modal_grey}/>}
                    onCancel={() => setFlagModalFeedback(false)}
                    bodyStyle={{padding: 20, backgroundColor: "#F0E9FF", borderRadius: 40}}

                >
                    <div className="title">
                        {activeCourseModal.name}
                    </div>
                    <div className="rate_title">Оцените курс <span style={{color: 'red'}}>*</span></div>
                    <div className="row_rate">
                        {Array(5).fill(0).map((_, indx) =>
                            <div key={indx}>
                                <RateFeedback
                                    className={activeRate && activeRate >= indx + 1 ? 'svg_feedback active' : 'svg_feedback'}
                                    onClick={() => setActiveRate(indx + 1)}
                                />
                            </div>
                        )}
                    </div>
                    <div className="feedback_title">{variables_11}</div>
                    <div className="feedback_text">
                        <TextArea
                            className={"textarea_antd"}
                            value={feedbackValue || ''}
                            onChange={(e) => setFeedbackValue(e.target.value)}
                            placeholder={"Количество символов 400"}
                            maxLength={400}
                        />
                    </div>
                    <Button
                        text={variables_12}
                        click={sendFeedback}
                        padding={"12px"}
                    />
                </Modal>
            </>
            <>
                <Modal
                    open={flagModalSettingsMobile}
                    footer={null}
                    className={"modal_settings-mobile"}
                    // width={"auto"}
                    closable={false}
                    centered={true}
                    onCancel={() => {
                        setFlagModalSettingsMobile(false);
                    }}
                    maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.10)"}}
                    bodyStyle={{padding: "15px 15px 30px 15px", backgroundColor: "#343843", borderRadius: 16}}
                >
                    <div className="title">
                        <div className="group_left">
                            <img src={settings_mobile_category}/>
                            <span>{variables_9}</span>
                        </div>
                        <div className="close" onClick={() => {
                            setFlagModalSettingsMobile(false);
                        }}
                        >
                            <img src={close_modal_grey} alt=""/>
                        </div>
                    </div>
                    <div className="list" style={{minHeight: "auto"}}>
                        {sortCatalog.map((elem, indx) =>
                            <div
                                onClick={() => {
                                    setActiveSortCatalog(indx);
                                    setFlagModalSettingsMobile(false);
                                }}
                                key={indx}
                                style={activeSortCatalog === indx ? {color: "rgb(179, 218, 255)"} : {}}
                            >
                                {elem}
                            </div>
                        )}
                    </div>
                    <div className="title" style={{marginTop: 20}}>
                        <div className="group_left">
                            <img src={settings_mobile_category}/>
                            <span>{variables_10}</span>
                        </div>
                    </div>
                    <div className="list" style={{minHeight: "auto"}}>
                        {arrCategory.map((elem, indx) =>
                            <div
                                onClick={() => {
                                    setActiveCategory(indx);
                                    setFlagModalSettingsMobile(false);
                                }}
                                key={indx}
                            >
                                    <span style={activeCategory === indx ? {color: "rgb(179, 218, 255)"} : {}}>
                                      {elem}
                                    </span>
                            </div>
                        )}
                    </div>
                </Modal>
            </>
            <>
                <Modal
                    open={flagModalTraektoryMobile}
                    footer={null}
                    className={"modal_traektory-my-course"}
                    // width={"auto"}
                    closable={false}
                    // centered={true}
                    onCancel={() => setFlagModalTraektoryMobile(false)}
                    maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.10)"}}
                    bodyStyle={{padding: 15, backgroundColor: "#27292F", borderRadius: 20}}
                >
                    <div className="title" style={{marginBottom: 17}}>
                        <img src={study_traektory}/>
                        <span>{variables__8}</span>
                    </div>
                    <div className="h3">
                        {variables_13}
                    </div>
                    <div className="block_traektory">
                        {userTags && userTags.length > 0 &&
                            userTags.map((elem, indx) =>
                                <div
                                    className={elem.status === 'learned' ? "skill" : "skill added"}
                                    key={indx}
                                >
                                    {elem.name}
                                </div>
                            )}
                    </div>
                    <div className="h3">
                        {variables_8}
                    </div>
                    <div className="block_traektory">
                        {trajectorySkills && trajectorySkills.length > 0 &&
                            trajectorySkills.map((elem, indx) =>
                                <div
                                    className={"skill"}
                                    key={indx}
                                    style={{
                                        backgroundColor: "rgba(162, 170, 202, 0.2)",
                                        color: "#A2AACA",
                                        paddingRight: 30
                                    }}
                                >{elem.name}
                                    <img
                                        src={close_tag}
                                        onClick={() => handleRemoveSkillBasket(elem)}
                                    />
                                </div>
                            )}
                    </div>
                    <div className="close" onClick={() => setFlagModalTraektoryMobile(false)}>{variables__28}</div>
                </Modal>
            </>
            {flagDropDownCategory &&
                <div className="overlay" onClick={() => setFlagDropDownCategory(false)}/>
            }
        </motion.div>
    );
};

export default MyCourse;