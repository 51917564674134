import React from 'react';
import { defaultFuncAndData } from "../../../../utils/defaultData";
import { useNavigate } from "react-router-dom";
import languageVariable from "../../../../utils/hookGetVariablesLanguage";
import Button from "../../../components/Button/Button";

import difficulty from "../../../../images/generate_course/difficulty.svg";
import clock from "../../../../images/generate_course/clock.svg";
import count from "../../../../images/generate_course/count.svg";
import loupe from "../../../../images/generate_course/loupe.svg";
import { ReactComponent as Star } from "../../../../images/generate_course/star.svg";
import remove from "../../../../images/generate_course/remove.svg";
import "./PlanningCourse.scss";
import cn from "classnames";

const PlanningCourse = (props) => {
    const variable_13 = languageVariable({keyPage: 'generateCourse', keyVariable: 'variable_13'});
    const variable_20 = languageVariable({keyPage: 'generateCourse', keyVariable: 'variable_20'});
    const variable_21 = languageVariable({keyPage: 'generateCourse', keyVariable: 'variable_21'});
    const variable_22 = languageVariable({keyPage: 'generateCourse', keyVariable: 'variable_22'});
    const variable_40 = languageVariable({keyPage: 'generateCourse', keyVariable: 'variable_40'});

    const {
        text, generateQuest, id, indx, quest, isFavorite,
        favorite, changeFavoritePlan, stages_count, openModalSize,
        isModalSize, fullWidth, loadingIndx
    } = props;
    const history = useNavigate();
    return (
        <div className={cn("planning_course", {
            full_width: fullWidth,
            unAuthenticated: !localStorage.getItem("token")
        })}>
            <div className="title_index">
                <div className="h1">
                    {isModalSize &&
                        variable_40 + ' - '
                    }
                    {!isFavorite &&
                        <>{variable_21}</>
                    }
                    № {indx + 1}
                </div>
                <div className="buttons">
                    {!isModalSize &&
                        <div
                            className="btn"
                            onClick={() => {
                                openModalSize()
                            }}
                        >
                            <img src={loupe} alt="loupe"/>
                        </div>
                    }
                    {localStorage.getItem("token") &&
                        <div
                            className={favorite ? "btn active" : "btn"}
                            onClick={() => changeFavoritePlan(id, favorite)}
                        >
                            <Star/>
                        </div>
                    }
                    {/* <div className="btn remove">
                        <img src={remove} alt="remove"/>
                    </div>*/}
                </div>
            </div>
            <div className="difficulty_level">
                <div>
                    <img src={count} alt="count"/>
                    <span>{variable_20}:</span>
                    <span>{stages_count}</span>
                </div>
            </div>
            <div className="steps">
            <pre>
              {defaultFuncAndData.convertToFontCode(text)}
            </pre>
            </div>
            <Button
                text={quest?.id ? variable_22 : variable_13}
                click={() => {
                    if (loadingIndx === undefined) {
                        window.localStorage.setItem("previous_course_link", "https://web.skillometer.ru/student/generate-course");
                        history("/student/auth");
                        return
                    }
                    if (quest?.id) {
                        setTimeout(() => {
                            history(`/student/course/lesson/${quest?.id}`)
                        }, 500)
                    } else generateQuest(id)
                }}
                margin={"auto 0 0 0"}
                padding={"13px 15px"}
                maxWidth={"100%"}
                borderRadius={"24px"}
                boxShadow={"none"}
                disabled={loadingIndx !== null && loadingIndx !== undefined}
            />
        </div>
    );
};

export default PlanningCourse;