import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { resizeWindow } from "../student/redux/actions/actions";

const HookResize = () => {
    const dispatch = useDispatch();
    let resizes = () =>
        dispatch(resizeWindow(window.innerWidth))

    useEffect(() => {
        resizes()
        window.addEventListener("resize", resizes);
        return () =>
            window.removeEventListener("resize", resizes)
    }, [])
    return window.innerWidth;
};

export default HookResize;