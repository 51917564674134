import React from "react";
import "./HeaderNavigation.scss";

const HeaderNavigation = (props) => {
    const {
        activeNavigation, setActiveNavigation, title,
        textVariable_1, textVariable_2, textVariable_3,
        needPath
    } = props;

    return (
        <div className="navigation_header">
            <div className="h1">
                {title}
                {needPath &&
                    <>
                        <div>/</div>
                        <div>{needPath}</div>
                    </>
                }
            </div>
            <div className="buttons_navigate">
                <div
                    className={activeNavigation === 0 ? "active" : ""}
                    onClick={() => setActiveNavigation(0)}
                >
                    <span>{textVariable_1}</span>
                </div>
                <div
                    className={activeNavigation === 1 ? "active" : ""}
                    onClick={() => setActiveNavigation(1)}
                >
                    <span>{textVariable_2}</span>
                </div>
                {textVariable_3 &&
                    <div
                        className={activeNavigation === 2 ? "active" : ""}
                        onClick={() => setActiveNavigation(2)}
                    >
                        <span>{textVariable_3}</span>
                    </div>
                }
            </div>
        </div>
    );
};

export default HeaderNavigation;