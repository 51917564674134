import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import cn from "classnames";

import ReactPlayer from "react-player";
import { Progress, Rate, Tooltip } from "antd";
import { defaultFuncAndData } from "../../utils/defaultData";
import variableLanguage from '../../utils/hookGetVariablesLanguage';

import sport_icon from "../../images/icons/sport_icon.svg";
import medical_icon from "../../images/icons/medical_icon.svg";
import menegement_icon from "../../images/icons/menegement_icon.svg";
import it_icon from "../../images/icons/it_icon.svg";
import languages_icon from "../../images/icons/languages_icon.svg";
import design_icon from "../../images/icons/design_icon.svg";
import finance_icon from "../../images/icons/finance_icon.svg";
import redirect_course from "../../images/icons/redirect_course.svg";
import default_icon from '../../images/icons/default_course.png';

import { ReactComponent as Video_icon } from "../../images/icons/course_walk_1.svg";
import { ReactComponent as Article_icon } from "../../images/icons/course_walk_2.svg";
import { ReactComponent as Test_icon } from "../../images/icons/course_walk_3.svg";
import { ReactComponent as HomeWork_icon } from "../../images/icons/course_walk_4.svg";
import { ReactComponent as Code_icon } from "../../images/icons/code_icon.svg";
import { ReactComponent as Start } from "../../images/icons/start_.svg";
import { ReactComponent as End } from "../../images/icons/end_.svg";
import { ReactComponent as Medal_ } from "../../images/icons/medal_.svg";
import Button from "./Button/Button";

export const ProcentagueProgress = ({procentague}) => {
    return (
        <div className={"progress_block"}>
            <Medal_ className={defaultFuncAndData.calcColorPercentProgressCourse(procentague).name}/>
            <div className="progress">
                <Progress
                    percent={procentague !== null ? procentague : 0}
                    strokeColor={defaultFuncAndData.calcColorPercentProgressCourse(procentague).color}
                    trailColor="#757A88"
                    format={() => null}
                />
                <div className="procentague">{procentague || 0} %</div>
            </div>
        </div>
    );
};
export const CourseCard = (props) => {
    const {
        course, redirectUrl, setOpenModal, isCourseList = false, cost = false,
        dateCourse = false, progress = false, btnSetRate = false, skillMatches = []
    } = props;
    const {language} = useSelector(state => state.Reducer);

    const variables_5 = variableLanguage({keyPage: 'courseCard', keyVariable: 'variables_5'});
    const variables_6 = variableLanguage({keyPage: 'courseCard', keyVariable: 'variables_6'});
    const variables_7 = variableLanguage({keyPage: 'courseCard', keyVariable: 'variables_7'});
    const variables_8 = variableLanguage({keyPage: 'courseCard', keyVariable: 'variables_8'});
    const variables_9 = variableLanguage({keyPage: 'courseCard', keyVariable: 'variables_9'});
    const variables_10 = variableLanguage({keyPage: 'courseCard', keyVariable: 'variables_10'});
    const variables_11 = variableLanguage({keyPage: 'courseCard', keyVariable: 'variables_11'});
    const category = [
        <div className="category" style={{backgroundColor: "#FF5937"}}>
            <img width={16} height={16} src={sport_icon}/>
            <span>{variableLanguage({keyPage: 'authorization', keyVariable: 'variables_36'})}</span>
        </div>,
        <div className="category" style={{backgroundColor: "#3077FF"}}>
            <img width={19} height={19} src={medical_icon}/>
            <span>{variableLanguage({keyPage: 'authorization', keyVariable: 'variables_31'})}</span>
        </div>,
        <div className="category" style={{backgroundColor: "#FFD23B"}}>
            <img width={18} height={18} src={menegement_icon}/>
            <span>{variableLanguage({keyPage: 'authorization', keyVariable: 'variables_33'})}</span>
        </div>,
        <div className="category" style={{backgroundColor: "#8146FF"}}>
            <img width={17} height={15} src={it_icon}/>
            <span>{variableLanguage({keyPage: 'authorization', keyVariable: 'variables_30'})}</span>
        </div>,
        <div className="category" style={{backgroundColor: "#30E0A1"}}>
            <img width={26} height={12} src={languages_icon}/>
            <span>{variableLanguage({keyPage: 'authorization', keyVariable: 'variables_32'})}</span>
        </div>,
        <div className="category" style={{backgroundColor: "#FF3434"}}>
            <img width={16} height={16} src={design_icon}/>
            <span>{variableLanguage({keyPage: 'authorization', keyVariable: 'variables_35'})}</span>
        </div>,
        <div className="category" style={{backgroundColor: "#FF47ED"}}>
            <img width={14} height={14} src={finance_icon}/>
            <span>{variableLanguage({keyPage: 'authorization', keyVariable: 'variables_34'})}</span>
        </div>
    ];

    return (
        <>
            {(course?.demo_video || course?.demo_youtube) ?
                <ReactPlayer
                    className='react-player'
                    style={{marginBottom: 15, width: "100%", borderRadius: 30}}
                    controls={true}
                    url={course.demo_youtube ? course.demo_youtube : course.demo_video}
                    width='100%'
                    height='175px'
                />
                :
                <img
                    src={course.y_image || defaultFuncAndData.defaultImage(course.speciality - 1) || default_icon}
                    style={{marginBottom: 15, width: "100%", aspectRation: 1.77}}
                    className="preview_image"
                />
                /*</div>*/
            }
            {course.rating
                ? <div className="rate">
                    <Rate
                        disabled
                        allowHalf
                        defaultValue={course.rating}
                    />
                </div>
                : <></>
            }
            {/* <div className="popular">
               <img
                 width={19} height={19}
                 src={popular_count_course}
                 style={{ alignSelf: "center" }}
               />
               <span>{Math.round(course.popular)}</span>
             </div>*/}
            {category[course.speciality - 1]}
            <Tooltip
                placement="top"
                title={course.name}
            >
                <div className="info">
                    {defaultFuncAndData.spliceStr(course.name, 45)}
                </div>
            </Tooltip>
            <div className="organization">
                {defaultFuncAndData.spliceStr(course.organization, 80)}
            </div>
            {course?.progress.count_video || course?.progress.count_article ||
            course?.progress.count_quiz || course?.progress.count_task || course?.progress.count_code_task
                ? <div className="stage">
                    <ul>
                        {course?.progress?.count_video > 0 &&
                            <>
                                {language === "ru" &&
                                    <li>
                                        <Tooltip
                                            align={'top'}
                                            title={`Курс содержит ${course?.progress.count_video} видео.`}
                                        >
                                            <span>{course?.progress.count_video}</span>
                                            <Video_icon/>
                                        </Tooltip>
                                    </li>
                                }
                                {language === "en" &&
                                    <li>
                                        <Tooltip
                                            align={'top'}
                                            title={course?.progress.count_video === 1
                                                ? `The course contains ${course?.progress.count_video} video.`
                                                : `The course contains ${course?.progress.count_video} videos.`
                                            }
                                        >
                                            <span>{course?.progress.count_video}</span>
                                            <Video_icon/>
                                        </Tooltip>
                                    </li>
                                }
                            </>
                        }
                        {course?.progress.count_article > 0 &&
                            <>
                                {language === "ru" &&
                                    <li>
                                        <Tooltip
                                            align={'top'}
                                            title={`Курс содержит ${course?.progress.count_article} ${defaultFuncAndData.declension(course?.progress.count_article, [" статью", " статьи", " статей"])}.`}
                                        >
                                            <span>{course?.progress.count_article}</span>
                                            <Article_icon/>
                                        </Tooltip>
                                    </li>
                                }
                                {language === "en" &&
                                    <li>
                                        <Tooltip
                                            align={'top'}
                                            title={course?.progress.count_article === 1
                                                ? `The course contains ${course?.progress.count_article} article.`
                                                : `The course contains ${course?.progress.count_article} articles.`
                                            }
                                        >
                                            <span>{course?.progress.count_article}</span>
                                            <Article_icon/>
                                        </Tooltip>
                                    </li>
                                }
                            </>
                        }
                        {course?.progress.count_quiz > 0 &&
                            <>
                                {language === "ru" &&
                                    <li>
                                        <Tooltip
                                            align={'top'}
                                            title={`Курс содержит ${course?.progress?.count_quiz} ${defaultFuncAndData.declension(course?.progress?.count_quiz, [" тест", " теста", " тестов"])}.`}
                                        >
                                            <span>{course?.progress?.count_quiz}</span>
                                            <Test_icon/>
                                        </Tooltip>
                                    </li>
                                }
                                {language === "en" &&
                                    <li>
                                        <Tooltip
                                            align={'top'}
                                            title={course?.progress?.count_quiz === 1
                                                ? `The course contains ${course?.progress?.count_quiz} test.`
                                                : `The course contains ${course?.progress?.count_quiz} tests.`
                                            }
                                        >
                                            <span>{course?.progress?.count_quiz}</span>
                                            <Test_icon/>
                                        </Tooltip>
                                    </li>
                                }
                            </>
                        }
                        {course?.progress.count_text_task > 0 &&
                            <>
                                {language === "ru" &&
                                    <>
                                        <li>
                                            <Tooltip
                                                align={'top'}
                                                title={`Курс содержит ${course?.progress.count_text_task} ${defaultFuncAndData.declension(course?.progress.count_text_task, [" домашнее задание", " домашних задания", " домаших заданий"])}.`}
                                            >
                                                <span>{course?.progress.count_text_task}</span>
                                                <HomeWork_icon/>
                                            </Tooltip>
                                        </li>
                                    </>
                                }
                                {language === "en" &&
                                    <li>
                                        <Tooltip
                                            align={'top'}
                                            title={course?.progress.count_task === 1
                                                ? `The course contains ${course?.progress.count_task} homework.`
                                                : `The course contains ${course?.progress.count_task} homeworks.`
                                            }
                                        >
                                            <span>{course?.progress.count_task}</span>
                                            <HomeWork_icon/>
                                        </Tooltip>
                                    </li>
                                }
                            </>
                        }
                        {course?.progress.count_code_task > 0 &&
                            <>
                                <li>
                                    <Tooltip
                                        align={'top'}
                                        title={`Курс содержит ${course?.progress.count_code_task} ${defaultFuncAndData.declension(course?.progress.count_code_task, [" задание", " задания", " заданий"])} по программированию.`}
                                    >
                                        <span>{course?.progress.count_code_task}</span>
                                        <Code_icon/>
                                    </Tooltip>
                                </li>
                            </>
                        }
                    </ul>
                </div>
                : <></>
            }
            {course && course.speciality_tag.length > 0
                ? <>
                    <div className="title_skill">{variables_5}</div>
                    <div className="key_skills">
                        {course.speciality_tag.map((skill, indx) =>
                            <div
                                className={cn("skills", {
                                    "active": skillMatches.length > 0 &&
                                        skillMatches.map((a) => a.toLowerCase()).includes(skill?.name?.toLowerCase())
                                })}
                                key={indx}
                            >
                                <span>
                                    {skill.name || skill}
                                </span>
                            </div>
                        )}
                    </div>
                </>
                : null
            }
            <div className="bottom_block">
                {(dateCourse) &&
                    <div className="date_course">
                        {course.period ?
                            <>
                                <Start/>
                                <span>
                                    {variables_8}
                                    {' '}
                                    {defaultFuncAndData.calcPeriod(course.period).start}
                                </span>
                                <End/>
                                <span>
                                     {variables_9}
                                    {' '}
                                    {defaultFuncAndData.calcPeriod(course.period).end}
                                </span>
                            </>
                            : null
                        }
                    </div>
                }
                {cost
                    ? <div className="cost">
                        <span>{variables_6}</span>
                        {course.participateprice
                            ? <span style={{color: "#30E0A1"}}>{course.participateprice}₽</span>
                            : <span style={{color: "#30E0A1"}}>{variables_7}</span>
                        }
                    </div>
                    : ""
                }
                {(progress) &&
                    <>
                        {course?.status === 0
                            ? <ProcentagueProgress procentague={course.progress.progress}/>
                            : <div className="status">
                                <span>
                                    Статус:
                                </span>
                                {course?.status === 1
                                    ? <span> Ожидание подтверждения </span>
                                    : <span className="discard"> Отклонено </span>
                                }
                            </div>
                        }

                    </>
                }
                <div className="group_redirect">
                    <Link
                        to={isCourseList
                            ? "/student/course-preview/" + course.ses
                            : "/student/course/lesson/" + redirectUrl
                        }
                    >
                        <Button
                            text={variables_10}
                            padding={"14px"}
                        />
                    </Link>
                    {!isCourseList &&
                        <Link
                            className="view"
                            to={"/student/course-preview/" + course.ses}
                        >
                            <img src={redirect_course}/>
                        </Link>
                    }
                </div>
                {btnSetRate &&
                    <div
                        className="btn_set-rate"
                        onClick={() => setOpenModal(course)}
                    >
                        {variables_11}
                    </div>
                }
            </div>
        </>
    );
};