import React, { useEffect, useState } from 'react';
import { Transition } from "react-transition-group";

import Checkbox from "../Checkbox/Checkbox";
import Button from "../../../../../components/Button/Button";
import requests from "../../../../../axios/requests";
import { message, notification, Spin } from "antd";
import variableLanguage from "../../../../../../utils/hookGetVariablesLanguage";

import arrow from "../../../../../../images/arrow.svg";
import test_result_wrong from "../../../../../../images/icons/test_result_wrong.svg";
import "./QuizMob.scss";

const QuizMob = ({questions, attempts_left, stage_theory_id, title}) => {
    const variable_16 = variableLanguage({keyPage: "lesson", keyVariable: "variable_16"});
    const variable_17 = variableLanguage({keyPage: "lesson", keyVariable: "variable_17"});
    const variable_18 = variableLanguage({keyPage: "lesson", keyVariable: "variable_18"});

    const [data, setData] = useState([]);
    const [currentQuizIndx, setCurrentQuizIndx,] = useState(0);
    const [openHistory, setOpenHistory] = useState(true);
    const [dataHistory, setDataHistory] = useState([]);
    const [attempts, setAttempts] = useState(1);
    const [loading, setLoading] = useState(false);

    let fetchQuizResult = () => {
        setLoading(true);
        requests.quests.get_result_quiz(stage_theory_id)
            .then(({data}) => {
                setDataHistory(data)
                if (data?.length > 0)
                    setOpenHistory(false);
                setLoading(false)
            })
            .catch(err => {
                console.error(err)
                setLoading(false)
            })
    }
    let checkStatusAnswer = (answers, correctAnswers) => {
        if (Object.keys(answers)?.length !== Object.keys(correctAnswers)?.length)
            return false
        else {
            for (let key of Object.keys(answers)) {
                if (!answers[key].correct)
                    return false
            }
            return true
        }
    }
    let restartAgain = () => {
        let newData = [...data];
        for (let i = 0; i < newData.length; i++)
            for (let j = 0; j < newData[i].answers.length; j++)
                newData[i].answers[j].value = false;
        setData(newData)
        setCurrentQuizIndx(0);
        setDataHistory([]);
        setOpenHistory(true);
    }
    let sendResult = () => {
        let findCorrectAnswers = (index) => {
            let resultIndxs = [];
            for (let i = 0; i < data[index].answers.length; i++)
                if (data[index].answers[i].value)
                    resultIndxs = [...resultIndxs, data[index].answers[i].id]

            return resultIndxs;
        }
        let answers = {};
        for (let i = 0; i < data.length; i++)
            answers[data[i].id] = findCorrectAnswers(i)
        let dataObj = {
            stage_theory_id,
            answers
        }
        requests.stages
            .post_quizresult(dataObj)
            .then((response) => {
                let newQuizAnswers = [];
                let newData = {...response.data};
                if (newData?.quiz_answers) {
                    for (let key in newData.quiz_answers)
                        newQuizAnswers =
                            [...newQuizAnswers, newData.quiz_answers[key]];
                    newData.quiz_answers = newQuizAnswers;
                }
                fetchQuizResult();
                if (typeof newData?.attempts_left !== 'undefined') {
                    if (newData.attempts_left > 0) {
                        notification["warn"]({
                            message: variable_17,
                            description: `${variable_18} ${newData.attempts_left}`
                        })
                    } else {
                        message.error(variable_16)
                        setAttempts(0);
                    }
                }
            })
            .catch((error) => {
                console.log(error.response);
            });
    }
    let nextQuestion = () => {
        let flag = false;
        for (let i = 0; i < data[currentQuizIndx].answers.length; i++) {
            if (data[currentQuizIndx].answers[i].value)
                flag = true;
        }
        if (!flag)
            return;
        if (currentQuizIndx !== (data?.length - 1))
            setCurrentQuizIndx(prev => prev + 1)
        else
            sendResult();
    }
    let checkStatus = () => {
        if (dataHistory?.length > 0) {
            let countQuestion = dataHistory[0]?.questions_count;
            let countQuestionCorrect = dataHistory[0]?.correct_answers_count;
            if (countQuestion === countQuestionCorrect)
                return true
        }
        return false
    }

    useEffect(() => {
        fetchQuizResult();
        setAttempts(attempts_left)
    }, [])
    useEffect(() => {
        let newData = [...questions];
        for (let i = 0; i < newData.length; i++)
            for (let j = 0; j < newData[i].answers.length; j++)
                newData[i].answers[j].value = false;
        setData(newData)
    }, [questions])
    return (
        <div className="quiz_mob">
            {attempts !== 0
                ? <>
                    {loading ?
                        <Spin/>
                        : <>
                            {dataHistory?.length === 0 &&
                                <div
                                    className="history_questions"
                                    onClick={() => setOpenHistory(!openHistory)}
                                    tabIndex={0}
                                >
                                    <span>{currentQuizIndx + 1}</span>
                                    <span>/</span>
                                    <span>{data?.length}</span>
                                    <div
                                        className={openHistory ? "arrow active" : "arrow"}
                                    >
                                        <img src={arrow} alt="arrow"/>
                                    </div>
                                </div>
                            }
                            {data?.length > 0 &&
                                <Transition
                                    in={openHistory}
                                    timeout={400}
                                    unmountOnExit
                                    mountOnEnter
                                >
                                    {(state) =>
                                        <div className={`drop_list-history ${state}`}>
                                            {Array(data.length).fill(0).map((_, indx) =>
                                                <div
                                                    className={currentQuizIndx === indx ? "elem_list active" : "elem_list"}
                                                    key={indx}
                                                >
                                                    <span>{indx + 1}</span>
                                                </div>
                                            )}
                                        </div>
                                    }
                                </Transition>
                            }
                            {dataHistory?.length === 0
                                ? <div className="quiz_content">
                                    <div className="name_quiz">
                                        {title}
                                    </div>
                                    {data?.length > 0 &&
                                        <>
                                            <div className="title_question">
                                                {data[currentQuizIndx].title}
                                            </div>
                                            <div className="info">Выберите несколько вариантов</div>
                                            {data[currentQuizIndx].answers?.length > 0 &&
                                                <>
                                                    <div className="answers_list">
                                                        {data[currentQuizIndx].answers.map((elem, indx) =>
                                                            <div
                                                                className="answer"
                                                                key={elem.id}
                                                            >
                                                                <Checkbox
                                                                    checked={data[currentQuizIndx].answers[indx].value}
                                                                    setCheckedValue={(e) => {
                                                                        let newData = [...data];
                                                                        newData[currentQuizIndx].answers[indx].value = e;
                                                                        setData(newData)
                                                                    }}
                                                                    label={elem.text}
                                                                    htmlFor={elem.id}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </>
                                            }
                                        </>
                                    }
                                </div>
                                : <>
                                    <div className="quiz_result">
                                        <div className="top_row">
                                            <div className="info">Показатель</div>
                                            <div className="value">Значение</div>
                                        </div>
                                        {/*<div className="info_row time">
                            <div className="label">Длительность прохождения</div>
                            <div className="value">20:00</div>
                        </div>
                        <div className="info_row">
                            <div className="label">Начало прохождения</div>
                            <div className="value">07:34</div>
                        </div>*/}
                                        <div className="info_row">
                                            <div className="label">Статус:</div>
                                            <div className={checkStatus() ? "value" : "value error"}>
                                                {checkStatus()
                                                    ? "Пройден"
                                                    : "Не пройден"
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {dataHistory?.length > 0 &&
                                        <div className="quiz_answers">
                                            {dataHistory[0].questions.map((elem, indx) =>
                                                <div
                                                    className="answer"
                                                    key={elem.id}
                                                >
                                                    <div
                                                        className={checkStatusAnswer(elem.answers, elem.correct_answers)
                                                            ? "num"
                                                            : "num error"
                                                        }
                                                    >
                                                        {indx + 1}
                                                    </div>
                                                    <div className="label">
                                                        {elem.title}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    }
                                </>
                            }
                            {dataHistory?.length > 0
                                ? <>
                                    <Button
                                        text={"Начать заново"}
                                        click={() => restartAgain()}
                                        padding={"8px 15px 10px"}
                                        maxWidth={"100%"}
                                        margin={"30px 15px 0 0"}
                                        boxShadow={"none"}
                                    />
                                </>
                                : <Button
                                    text={(data?.length - 1) > currentQuizIndx
                                        ? "Следующий вопрос"
                                        : "Завершить тестирование"
                                    }
                                    click={() => nextQuestion()}
                                    padding={"8px 15px 10px"}
                                    maxWidth={"100%"}
                                    margin={"30px 15px 0 0"}
                                    boxShadow={"none"}
                                />
                            }
                        </>
                    }
                </>
                : <div className="result_attemps-denied">
                    <img src={test_result_wrong} alt="attempts_left"/>
                    <span>{variable_16}</span>
                </div>
            }
        </div>
    );
};

export default QuizMob;