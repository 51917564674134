export const constantsResume = {
    "categories": {
        "engineering_it": "Инженерные & IT",
        "science_medicine": "Наука & Медицина",
        "languages_copywriting": "Языки & Копирайтинг",
        "management_marketing": "Менеджмент & Маркетинг",
        "finance_law": "Финансы & Юриспруденция",
        "design_creative": "Дизайн & Креатив",
        "sport": "Спорт"
    },
    "categories_en": {
        "engineering_it": "Engineering & IT",
        "science_medicine": "Science & Medicine",
        "languages_copywriting": "Languages & Copywriting",
        "management_marketing": "Management & Marketing",
        "finance_law": "Finance & Law",
        "design_creative": "Design & Creative",
        "sport": "Sport"
    },
    "currencies": {
        "rub": "₽",
        "usd": "$",
        "eur": "€",
        "kzt": "₸",
        "byr": "Br",
        "uzs": "UZS",
        "azn": "₼",
        "gel": "₾",
        "kgs": "KGS"
    },
    "rate_type": {
        "month": "Месяц",
        "hour": "Час"
    },
    "driving_license_types": {
        "a": "A",
        "b": "B",
        "c": "C",
        "d": "D",
        "be": "BE",
        "ce": "CE",
        "de": "DE",
        "tm": "Tm",
        "tb": "Tb"
    },
    "education_levels": {
        "secondary": "Среднее",
        "special_secondary": "Среднее специальное",
        "unfinished_higher": "Неоконченное высшее",
        "higher": "Высшее",
        "bachelor": "Бакалавр",
        "master": "Магистр",
        "candidate": "Кандидат наук",
        "doctor": "Доктор наук"
    },
    "education_levels_en": {
        "secondary": "Secondary",
        "special_secondary": "Special secondary",
        "unfinished_higher": "Unfinished higher",
        "higher": "Higher",
        "bachelor": "Bachelor",
        "master": "Master",
        "candidate": "Candidate",
        "doctor": "Doctor"
    },
    "employments": {
        "full": "Полная занятость",
        "part": "Частичная занятость",
        "project": "Проектная работа",
        "volunteer": "Волонтерство",
        "probation": "Стажировка"
    },
    "languages": {
        "rus": "Русский",
        "eng": "Английский",
        "deu": "Немецкий",
        "fra": "Французский",
        "abq": "Абазинский",
        "abk": "Абхазский",
        "ava": "Аварский",
        "aze": "Азербайджанский",
        "sqi": "Албанский",
        "amh": "Амхарский",
        "ara": "Арабский",
        "hye": "Армянский",
        "afr": "Африкаанс",
        "eus": "Баскский",
        "bak": "Башкирский",
        "bel": "Белорусский",
        "ben": "Бенгальский",
        "mya": "Бирманский",
        "bul": "Болгарский",
        "bos": "Боснийский",
        "bua": "Бурятский",
        "hun": "Венгерский",
        "vie": "Вьетнамский",
        "nld": "Голландский",
        "ell": "Греческий",
        "kat": "Грузинский",
        "dag": "Дагестанский",
        "dar": "Даргинский",
        "dan": "Датский",
        "heb": "Иврит",
        "inh": "Ингушский",
        "ind": "Индонезийский",
        "gle": "Ирландский",
        "isl": "Исландский",
        "spa": "Испанский",
        "ita": "Итальянский",
        "kbd": "Кабардино-черкесский",
        "kaz": "Казахский",
        "krc": "Карачаево-балкарский",
        "krl": "Карельский",
        "cat": "Каталанский",
        "kas": "Кашмирский",
        "zho": "Китайский",
        "kom": "Коми",
        "kor": "Корейский",
        "crs": "Креольский (Сейшельские острова)",
        "kum": "Кумыкский",
        "kur": "Курдский",
        "khm": "Кхмерский (Камбоджийский)",
        "kir": "Кыргызский",
        "lbe": "Лакский",
        "lao": "Лаосский",
        "lat": "Латинский",
        "lav": "Латышский",
        "lez": "Лезгинский",
        "lit": "Литовский",
        "mke": "Македонский",
        "zlm": "Малазийский",
        "mns": "Мансийский",
        "chm": "Марийский",
        "mon": "Монгольский",
        "nep": "Непальский",
        "nog": "Ногайский",
        "nor": "Норвежский",
        "oss": "Осетинский",
        "pan": "Панджаби",
        "fas": "Персидский",
        "pol": "Польский",
        "por": "Португальский",
        "pus": "Пушту",
        "ron": "Румынский",
        "san": "Санскрит",
        "srp": "Сербский",
        "slk": "Словацкий",
        "slv": "Словенский",
        "som": "Сомалийский",
        "swa": "Суахили",
        "tgl": "Тагальский",
        "tgk": "Таджикский",
        "tha": "Тайский",
        "tly": "Талышский",
        "tam": "Тамильский",
        "tat": "Татарский",
        "bod": "Тибетский",
        "tyv": "Тувинский",
        "tur": "Турецкий",
        "tuk": "Туркменский",
        "udm": "Удмуртский",
        "uzb": "Узбекский",
        "uig": "Уйгурский",
        "ukr": "Украинский",
        "urd": "Урду",
        "fin": "Финский",
        "vls": "Фламандский",
        "hin": "Хинди",
        "hrv": "Хорватский",
        "che": "Чеченский",
        "ces": "Чешский",
        "chv": "Чувашский",
        "swe": "Шведский",
        "epo": "Эсперанто",
        "est": "Эстонский",
        "sah": "Якутский",
        "jpn": "Японский"
    },
    "languages_en": {
        "rus": "Russian",
        "eng": "English",
        "deu": "Deutsch",
        "fra": "Français",
        "abq": "Abaza",
        "abk": "Abkhazian",
        "ava": "Avar",
        "aze": "Azerbaijani",
        "sqi": "Albanian",
        "amh": "Amharic",
        "ara": "Arab",
        "hye": "Armenian",
        "afr": "Afrikaans",
        "eus": "Basque",
        "bak": "Bashkir",
        "bel": "Belorussian",
        "ben": "Bengal",
        "mya": "Burmese",
        "bul": "Bulgarian",
        "bos": "Bosnian",
        "bua": "Buryat",
        "hun": "Hungarian",
        "vie": "Vietnamese",
        "nld": "Dutch",
        "ell": "Greek",
        "kat": "Georgian",
        "dag": "Dagestan",
        "dar": "Darginsky",
        "dan": "Danish",
        "heb": "Hebrew",
        "inh": "Ingush",
        "ind": "Indonesian",
        "gle": "Irish",
        "isl": "Icelandic",
        "spa": "Spanish",
        "ita": "Italian",
        "kbd": "Kabardino-Circassian",
        "kaz": "Kazakh",
        "krc": "Karachay-Balkar",
        "krl": "Karelian",
        "cat": "Catalan",
        "kas": "Kashmiri",
        "zho": "Chinese",
        "kom": "Komi",
        "kor": "Korean",
        "crs": "Creole (Seychelles)",
        "kum": "Kumyk",
        "kur": "Kurdish",
        "khm": "Khmer (Cambodian)",
        "kir": "Kyrgyz",
        "lbe": "Laksky",
        "lao": "Laotian",
        "lat": "Latin",
        "lav": "Latvian",
        "lez": "Lezginsky",
        "lit": "Lithuanian",
        "mke": "Macedonian",
        "zlm": "Malaysian",
        "mns": "Mansi",
        "chm": "Mari",
        "mon": "Mongolian",
        "nep": "Nepali",
        "nog": "Nogai",
        "nor": "Japanese",
        "oss": "Ossetian",
        "pan": "Punjabi",
        "fas": "Persian",
        "pol": "Polish",
        "por": "Portuguese",
        "pus": "Pashto",
        "ron": "Romanian",
        "san": "Sanskrit",
        "srp": "Serbian",
        "slk": "Slovak",
        "slv": "Slovenian",
        "som": "Somali",
        "swa": "Swahili",
        "tgl": "Tagalog",
        "tgk": "Tajik",
        "tha": "Thai",
        "tly": "Talysh",
        "tam": "Tamil",
        "tat": "Tatar",
        "bod": "Tibetan",
        "tyv": "Tuva",
        "tur": "Turkish",
        "tuk": "Turkmen",
        "udm": "Udmurt",
        "uzb": "Uzbek",
        "uig": "Uigur",
        "ukr": "Ukrainian",
        "urd": "Urdu",
        "fin": "Finnish",
        "vls": "Flemish",
        "hin": "Hindi",
        "hrv": "Croatian",
        "che": "Chechen",
        "ces": "Czech",
        "chv": "Chuvash",
        "swe": "Swedish",
        "epo": "Esperanto",
        "est": "Estonian",
        "sah": "Yakut",
        "jpn": "Japanese"
    },
    "language_levels": {
        "a1": "A1 - Начальный",
        "a2": "A2 - Элементарный",
        "b1": "B1 - Средний",
        "b2": "B2 - Средне-продвинутый",
        "c1": "C1 - Продвинутый",
        "c2": "C2 - В совершенстве"
    },
    "language_levels_en": {
        "a1": "A1 - Primary",
        "a2": "A2 - Elementary",
        "b1": "B1 - Medium",
        "b2": "B2 - Intermediate Advanced",
        "c1": "C1 - Advanced",
        "c2": "C2 - Perfect"
    },
    "relocation_types": {
        "no_relocation": "Не возможен",
        "relocation_possible": "Возможен",
        "relocation_desirable": "Желателен"
    },
    "relocation_types_en": {
        "no_relocation": "Not possible",
        "relocation_possible": "Possible",
        "relocation_desirable": "Desirable"
    },
    "schedules": {
        "fullDay": "Полный день",
        "shift": "Сменный график",
        "flexible": "Гибкий график",
        "remote": "Удаленная работа",
        "flyInFlyOut": "Вахтовый метод"
    },
    "social_networks": {
        "telegram": "Telegram",
        "skype": "Skype",
        "linkedin": "LinkedIn",
        "vk": "VK",
        "tiktok": "TikTok",
        "another": "Другое"
    },
    "social_networks_en": {
        "telegram": "Telegram",
        "skype": "Skype",
        "linkedin": "LinkedIn",
        "vk": "VK",
        "tiktok": "TikTok",
        "another": "Other"
    }
}

