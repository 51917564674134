import React, { useEffect, useState } from "react";
import requests from "../../../axios/requests";
import moment from "moment";
import { Modal, Pagination, Spin } from "antd";
import { CourseCard } from "../../../components/CourseCard";
import variableLanguage from '../../../../utils/hookGetVariablesLanguage';

import close_tag from "../../../../images/icons/close_tag.svg";
import it_icon from "../../../../images/icons/it_icon.svg";
import medical_icon from "../../../../images/icons/medical_icon.svg";
import languages_icon from "../../../../images/icons/languages_icon.svg";
import menegement_icon from "../../../../images/icons/menegement_icon.svg";
import finance_icon from "../../../../images/icons/finance_icon.svg";
import design_icon from "../../../../images/icons/design_icon.svg";
import sport_icon from "../../../../images/icons/sport_icon.svg";
import close_modal from "../../../../images/icons/close_modal-course.svg";
import { ReactComponent as Arrow } from "../../../../images/arrow.svg";
import { defaultFuncAndData } from "../../../../utils/defaultData";
import ModalContainer from "../../../components/ModalContainer/ModalContainer";
import Tag from "../../../components/Tag/Tag";

const ModalCourse = (props) => {
    const {flagModalCourse, closeModalCourse, skillsUserTraektory, handleRemoveSkillBasket} = props;

    const variable_1 = variableLanguage({keyPage: 'modalCourse', keyVariable: 'variable_1'});
    const variable_2 = variableLanguage({keyPage: 'modalCourse', keyVariable: 'variable_2'});
    const variable_3 = variableLanguage({keyPage: 'modalCourse', keyVariable: 'variable_3'});
    const variable_4 = variableLanguage({keyPage: 'modalCourse', keyVariable: 'variable_4'});
    const variable_5 = variableLanguage({keyPage: 'modalCourse', keyVariable: 'variable_5'});
    const variable_6 = variableLanguage({keyPage: 'modalCourse', keyVariable: 'variable_6'});
    const variable_7 = variableLanguage({keyPage: 'modalCourse', keyVariable: 'variable_7'});
    const variables_36 = variableLanguage({keyPage: 'authorization', keyVariable: 'variables_36'});
    const variables_31 = variableLanguage({keyPage: 'authorization', keyVariable: 'variables_31'});
    const variables_33 = variableLanguage({keyPage: 'authorization', keyVariable: 'variables_33'});
    const variables_30 = variableLanguage({keyPage: 'authorization', keyVariable: 'variables_30'});
    const variables_32 = variableLanguage({keyPage: 'authorization', keyVariable: 'variables_32'});
    const variables_34 = variableLanguage({keyPage: 'authorization', keyVariable: 'variables_34'});
    const variables_35 = variableLanguage({keyPage: 'authorization', keyVariable: 'variables_35'});

    const [activeSort, setActiveSort] = useState(0);
    const [courses, setCourses] = useState([]);
    const [flagDropDown, setFlagDropDown] = useState(false);
    const [originalCourse, setOriginalCourse] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [page_size] = useState(20);
    const [loading, setLoading] = useState(false);

    const sortList = [variable_1, variable_2, variable_3];
    const category = [
        <div className="category" style={{backgroundColor: "#FF5937"}}>
            <img width={16} height={16} src={sport_icon}/>
            <span>{variables_36}</span>
        </div>,
        <div className="category" style={{backgroundColor: "#3077FF"}}>
            <img width={19} height={19} src={medical_icon}/>
            <span>{variables_31}</span>
        </div>,
        <div className="category" style={{backgroundColor: "#FFD23B"}}>
            <img width={18} height={18} src={menegement_icon}/>
            <span style={{color: "#343843"}}>{variables_33}</span>
        </div>,
        <div className="category" style={{backgroundColor: "#8146FF"}}>
            <img width={17} height={15} src={it_icon}/>
            <span>{variables_30}</span>
        </div>,
        <div className="category" style={{backgroundColor: "#30E0A1"}}>
            <img width={26} height={12} src={languages_icon}/>
            <span style={{color: "#343843"}}>{variables_32}</span>
        </div>,
        <div className="category" style={{backgroundColor: "#FF3434"}}>
            <img width={16} height={16} src={design_icon}/>
            <span>{variables_35}</span>
        </div>,
        <div className="category" style={{backgroundColor: "#FF47ED"}}>
            <img width={14} height={14} src={finance_icon}/>
            <span>{variables_34}</span>
        </div>
    ];

    let handleCloseModal = () => {
        closeModalCourse();
    };
    let customPagination = (array) => {
        if (array) {
            const contentPerPage = 12;
            const lastIndex = currentPage * contentPerPage; // 3
            const firstIndex = lastIndex - contentPerPage; // 0
            return array.slice(firstIndex, lastIndex);
        }
        return null;
    };
    let getCourseList = () => {
        let arr = [];
        if (skillsUserTraektory && skillsUserTraektory.length > 0) {
            for (let i = 0; i < skillsUserTraektory.length; i++) {
                arr = [...arr, skillsUserTraektory[i].id];
            }
        }
        const ordering =
            activeSort === 1
                ? "price,name"
                : activeSort === 2
                    ? "dateBegin,name"
                    : "";
        setLoading(true);
        requests.quests.get_quest_quest(arr, currentPage, page_size, "", "", "", false, ordering)
            .then(res => setOriginalCourse(res.data.results))
            .catch((error) => console.log("ошибка get_quest_quest", error))
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        getCourseList();
    }, []);
    useEffect(() => {
        if (originalCourse && originalCourse.length > 0) {
            let newList = [...originalCourse];
            setCourses(newList);
        }
    }, [originalCourse]);
    useEffect(() => {
        getCourseList();
    }, [activeSort]);
    useEffect(() => {
        customPagination();
    }, [currentPage]);
    return (
        /* <Modal
             open={flagModalCourse}
             footer={null}
             className={"modal_course"}
             closable={false}
             onCancel={handleCloseModal}
             maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.10)"}}
             bodyStyle={{padding: "40px 40px 10px 40px", backgroundColor: "#201E26", borderRadius: 40}}
         >*/
        <ModalContainer
            open={flagModalCourse}
            close={handleCloseModal}
            current={"course"}
        >
            <div className="modal_content">
                <div className="title">
                    <span>{variable_4}</span>
                    <div className="close_icon">
                        <img
                            onClick={handleCloseModal}
                            className={"close_title"}
                            width={40}
                            height={40}
                            src={close_modal}
                        />
                    </div>
                </div>
                <div className="title_info">{variable_5}</div>
                <div className="sort">
                    <ul>
                        <li style={{color: "white", cursor: "initial"}}>{variable_6}:</li>
                        {sortList.map((elem, indx) =>
                            <li
                                onClick={() => setActiveSort(indx)}
                                key={indx}
                                className={activeSort === indx ? "active" : ""}
                            >
                                {sortList[indx]}
                            </li>
                        )}
                    </ul>
                </div>
                <div
                    onClick={() => setFlagDropDown(!flagDropDown)}
                    className={flagDropDown ? "sort_mobile active" : "sort_mobile"}
                    style={{display: "none"}}
                >
                    <span>{variable_6}:</span> <span
                    style={{color: "#A1A3B2", marginLeft: 5}}>{sortList[activeSort]} </span>
                    <Arrow className={flagDropDown ? "active" : ""}/>
                </div>
                <div className={flagDropDown ? "sort_mobile-drop-down active" : "sort_mobile-drop-down"}>
                    <ul>
                        {sortList.map((elem, indx) =>
                            <li
                                key={indx}
                                onClick={() => {
                                    if (!flagDropDown) {
                                        return;
                                    }
                                    setFlagDropDown(false);
                                    setActiveSort(indx);
                                }}
                            >
                                {elem}
                            </li>
                        )}
                    </ul>
                </div>
                <div className="traektory_title">{variable_7}</div>
                <div className="traektory_basket">
                    {skillsUserTraektory && skillsUserTraektory.length > 0 &&
                        skillsUserTraektory.map((elem) =>
                            <Tag
                                elem={elem}
                                removeTag={handleRemoveSkillBasket}
                                key={elem.id}
                            />
                        )
                    }
                </div>
                <div className="course_list_modal">
                    <ul style={{position: "relative"}}>
                        {(courses && courses.length > 0) && courses.length <= 12
                            ? courses.map((course, indx) => {
                                    return (
                                        <li key={indx} className={"reuse_course-item"}>
                                            <CourseCard
                                                course={course}
                                                isCourseList={true}
                                                redirectUrl={course.id}
                                                cost={true}
                                                dateCourse={true}
                                            />
                                        </li>
                                    );
                                }
                            )
                            : customPagination(courses).map((course, indx) => {
                                    return (
                                        <li key={indx} className={"reuse_course-item"}>
                                            <CourseCard
                                                course={course}
                                                category={category}
                                                isCourseList={true}
                                                redirectUrl={course.id}
                                                cost={true}
                                                dateCourse={true}
                                            />
                                        </li>
                                    );
                                }
                            )}
                        {loading &&
                            <Spin style={{position: "absolute"}}/>
                        }
                    </ul>
                    {courses && courses.length > 12 &&
                        <div className="pagination_all">
                            <Pagination
                                itemRender={defaultFuncAndData.itemRenderPagination}
                                defaultCurrent={1}
                                defaultPageSize={12}
                                total={courses.length}
                                onChange={(e) => setCurrentPage(e)}
                                showTitle={false}
                            />
                        </div>
                    }
                </div>
            </div>
        </ModalContainer>
        /*</Modal>*/
    );
};

export default ModalCourse;