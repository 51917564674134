import React, { useEffect, useState } from 'react';
import { message, Select, Tag } from "antd";
import add_activity from "../../../../images/icons/add_activity-icon.svg";
import { defaultFuncAndData } from "../../../../utils/defaultData";
import add_activity_bottom from "../../../../images/auth/add_skill_dark.svg";
import requests from "../../../axios/requests";
import variableLanguage from "../../../../utils/hookGetVariablesLanguage";
import Button from "../../../components/Button/Button";

const Step4 = (props) => {
    const {suggestedSelectOptions, setCurrent} = props;
    const variables_12 = variableLanguage({keyPage: "authorization", keyVariable: "variables_12"});
    const variables_39 = variableLanguage({keyPage: "authorization", keyVariable: "variables_39"});
    const variables_40 = variableLanguage({keyPage: "authorization", keyVariable: "variables_40"});
    const variables_41 = variableLanguage({keyPage: "authorization", keyVariable: "variables_41"});
    const variables_43 = variableLanguage({keyPage: "authorization", keyVariable: "variables_43"});
    const variables_44 = variableLanguage({keyPage: "authorization", keyVariable: "variables_44"});
    const variables_45 = variableLanguage({keyPage: "authorization", keyVariable: "variables_45"});

    const [tagsStep4, setTagsStep4] = useState([]);
    const [valueInputTag, setValueInputTag] = useState("");
    const [existTagsList, setExistTagsList] = useState([]);
    const [flagOpenSelectTags, setFlagOpenSelectTags] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [timeoutSearch, setTimeoutSearch] = useState(null);
    const [timeoutFetchPagination, setTimeoutFetchPagination] = useState(null);
    const [loading, setLoading] = useState(false);

    let addTagsTrajectory = (name) => {
        /*if (!validateSelectSkills(name)) return*/
        if (tagsStep4.includes(name)) return;
        setTagsStep4(prev => [...prev, name]);
        let obj = {name: name, id: name.id, index: tagsStep4.length + 1};
        requests.quests.add_trajectory_skill(obj)
            .catch((e) => console.error(e));
    };
    let fetchSearchTags = (page_size) => {
        setLoading(true);
        requests.profs.get_speciality_tags(valueInputTag, 1, page_size || pageSize)
            .then((v) => setExistTagsList(v.data))
            .finally(() => setLoading(false))
    }
    let tagRender = (props) => {
        const {label, closable} = props;
        const onPreventMouseDown = event => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <Tag
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={() => {
                    let newArr = [...tagsStep4];
                    newArr = newArr.filter(tag => tag !== label);
                    setTagsStep4(newArr)
                    requests.quests.remove_trajectory_skill(label)
                        .catch((e) => console.error(e));
                }}
                style={{
                    marginRight: 3,
                    marginBottom: 5,
                    color: "#A2AACA",
                    fontSize: 12,
                    background: "rgba(162, 170, 202, 0.2)",
                    lineHeight: 2.1,
                    border: "none"
                }}
            >
                {label}
            </Tag>
        );
    };

    useEffect(() => {
        if (valueInputTag.length > 1) {
            let lastSymbol = valueInputTag[valueInputTag.length - 1];
            if (lastSymbol !== " ") {
                setPageSize(10)
                setExistTagsList([]);
                if (timeoutSearch)
                    clearTimeout(timeoutSearch)
                setTimeoutSearch(setTimeout(() =>
                    fetchSearchTags(10), 500))
            }
        }
    }, [valueInputTag])
    return (
        <div className="step_4">
            <div className="top_block">
                <div className="right_block">
                    <p>{variables_43}</p>
                    <Select
                        showSearch
                        placeholder={variables_44}
                        onSearch={setValueInputTag}
                        value={tagsStep4}
                        mode="tags"
                        tagRender={tagRender}
                        onSelect={addTagsTrajectory}
                        className={flagOpenSelectTags
                            ? "activity_select active"
                            : "activity_select"
                        }
                        onDropdownVisibleChange={(e) => setFlagOpenSelectTags(e)}
                        dropdownStyle={{
                            backgroundColor: "#343843",
                            padding: 0,
                            color: "white",
                            borderRadius: 0
                        }}
                        dropdownAlign={{
                            offset: [1, -1]
                        }}
                        onPopupScroll={async (e) => {
                            const {target} = e;
                            if (
                                target.scrollTop
                                + target.offsetHeight ===
                                target.scrollHeight
                            ) {
                                if ((existTagsList?.count > pageSize)) {
                                    setTimeoutFetchPagination(
                                        setTimeout(() => {
                                            setPageSize(prev => prev + 10);
                                            fetchSearchTags(pageSize + 10)
                                        }, 500)
                                    )
                                }
                            }
                        }}
                    >
                        <Select.OptGroup label={variables_39}>
                            {existTagsList?.results?.map((tag) =>
                                <Select.Option
                                    key={tag.id}
                                    value={tag.name}
                                >
                                    {tag.name}
                                </Select.Option>)
                            }
                        </Select.OptGroup>
                        <Select.OptGroup label={variables_40}>
                            {suggestedSelectOptions.map((option, i) =>
                                <Select.Option
                                    key={`suggest_${option}`}
                                    value={option}
                                >
                                    {option}
                                </Select.Option>)
                            }
                        </Select.OptGroup>
                    </Select>
                    <div className="add_activity-icon">
                        <img src={add_activity}/>
                    </div>
                </div>
            </div>
            <div className="popular"><span>{variables_45}</span></div>
            <div className="bottom_block">
                <div className="bottom_tags">
                    {defaultFuncAndData.arrTagsAllPopular().map((tag, indx) =>
                        <div
                            style={{cursor: "pointer", zIndex: 2}}
                            key={tag.id}
                            onClick={() => addTagsTrajectory(tag.name)}
                        >
                            {tag.name}
                            <img src={add_activity_bottom}/>
                        </div>
                    )}
                </div>
            </div>
            <div className="btn_block">
                <Button
                    text={variables_41}
                    click={() => setCurrent(2)}
                    maxWidth={"135px"}
                    margin={"0 15px 0 0"}
                    padding={"8px 9px 9px 9px"}
                    backgroundColor={"var(--background_lighten-dark)"}
                    boxShadow={"none"}
                    zIndex={10}
                />
                <Button
                    text={variables_12}
                    click={() => {
                        if (tagsStep4?.length === 0) {
                            message.error("Добавьте желаемые навыки")
                        } else setCurrent(4);
                    }}
                    maxWidth={"305px"}
                    zIndex={10}
                />
            </div>
        </div>
    );
};

export default Step4;