import React from 'react';
import variableLanguage from "../../../../../../utils/hookGetVariablesLanguage";
import { Input } from "antd";
import Comment from './Comment/Comment';

import send_comment from "../../../../../../images/icons/send_comment.svg";

const Comments = ({comments, sendComment, handleSetLikeDislake, valueComment, setValueComment}) => {
    const variable_12 = variableLanguage({keyPage: "lesson", keyVariable: "variable_12"});
    const variable_13 = variableLanguage({keyPage: "lesson", keyVariable: "variable_13"});

    return (
        <>
            {comments && comments.length > 0 &&
                <div className="comment_title">
                    {variable_12}
                </div>
            }
            {comments && comments.length > 0 &&
                comments.map((comment, indx) =>
                        <Comment
                            key={indx}
                            {...comment}
                            setLike={(id) => handleSetLikeDislake(id, true)}
                            setDislake={(id) => handleSetLikeDislake(id, false)}
                        />
                )
            }
            <div className="comment_input">
                <Input
                    placeholder={variable_13}
                    className={"input_classic"}
                    value={valueComment}
                    onChange={(e) => setValueComment(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === "Enter") sendComment();
                    }}
                />
                <img src={send_comment} onClick={sendComment}/>
            </div>
        </>
    );
};

export default Comments;