import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setUserTrajectory } from "../../../redux/actions/userActions";
import { arrayMoveImmutable } from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import requests from "../../../axios/requests";
import cn from "classnames";

import variableLanguage from "../../../../utils/hookGetVariablesLanguage";
import FetchSkills from "../../../components/FetchSkills/FetchSkills";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

import { ReactComponent as TrajectoryIcon } from "../../../../images/icons/study_traektory.svg";
import close_tag from "../../../../images/icons/close_tag.svg";
import './TrajectorySection.scss';

const TrajectorySection = (props) => {
    const variables_8 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_8"});
    const variables_10 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_10"});
    const variables_36 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_36"});
    const variables_37 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_37"});

    const {getListApiCourses, studyTraektoryRef} = props;

    const history = useNavigate();
    const dispatch = useDispatch();
    const {trajectorySkills} = useSelector(state => state.Reducer);

    let removeTrajectorySkill = (skill) => {
        let newArr;
        for (let i = 0; i < trajectorySkills.length; i++) {
            if (trajectorySkills[i].name === skill.name) {
                newArr = trajectorySkills.filter(t => t.name !== skill.name);
                break;
            }
        }
        dispatch(setUserTrajectory(newArr))
        requests.quests.remove_trajectory_skill(skill.name)
            .catch((err) => console.error(err));
    };
    let onSortEnd = ({oldIndex, newIndex}) => {
        let newArr = arrayMoveImmutable(trajectorySkills, oldIndex, newIndex);
        let obj = trajectorySkills[oldIndex];
        obj.index = newIndex + 1;
        dispatch(setUserTrajectory(newArr))
        requests.quests.add_trajectory_skill(obj)
            .catch((err) => console.error(err));
    };

    const SortableItem = SortableElement(({elem}) => (
        <div
            className={cn("tag_trajectory", {
                without_img: elem.has_generated_quest
            })
            }
        >
            <span>{elem.name}</span>
            {!elem.has_generated_quest &&
                <img
                    onClick={() => {
                        removeTrajectorySkill(elem);
                    }}
                    src={close_tag}
                />
            }
        </div>
    ));
    const SortableList = SortableContainer(({items}) => {
        return (
            <div className="block_tags">
                {items.map((elem, indx) =>
                    <SortableItem key={`item-${elem.id}`} index={indx} elem={elem}/>
                )}
            </div>
        );
    });

    return (
        <div
            className={cn("trajectory_section", {
                z_index_0: props.flagSteps && props.flagSteps !== 3
            })}
            ref={studyTraektoryRef}
        >
            <div className="down_block-traektory">
                <div className="group">
                    <div className="svg">
                        <TrajectoryIcon/>
                    </div>
                    <span>{variables_8}</span>
                    <FetchSkills/>
                </div>
                <div className="block_tags">
                    {trajectorySkills?.length > 0 &&
                        <SortableList
                            distance={1}
                            axis={"xy"}
                            items={trajectorySkills}
                            onSortEnd={(oldIndex, newIndex) => {
                                onSortEnd(oldIndex, newIndex);
                                document.body.style.cursor = "default";
                            }}
                            onSortStart={() => (document.body.style.cursor = "grabbing")}
                        />
                    }
                </div>
                <div className="buttons">
                    <div
                        onClick={() => {
                            if (trajectorySkills.length > 0) {
                                /*props.openChatGpt()*/
                                if (props.flagSteps) {
                                    props.setFlagSteps(false)
                                    requests.users.edit_profile_new({trained: true})
                                        .catch(err => console.error(err))
                                }
                                history('/student/generate-course')
                            } else
                                message.warning(variables_37)
                        }}
                        className="btn_search-course planned"
                    >
                        {variables_36}
                    </div>
                    <div
                        onClick={() => {
                            if (trajectorySkills.length > 0) {
                                getListApiCourses()
                                if (props.flagSteps) {
                                    props.setFlagSteps(false)
                                }
                            } else
                                message.warning(variables_37)
                        }}
                        className="btn_search-course courses"
                    >
                        {variables_10}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default TrajectorySection;