import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import requests from "../../axios/requests";
import { setUserTrajectory } from "../../redux/actions/userActions";

import variableLanguage from "../../../utils/hookGetVariablesLanguage";
import { message, Spin } from "antd";
import Input from "../Input/Input";
import './FetchSkills.scss';

const FetchSkills = ({maxWidth}) => {
    const variables_9 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_9"});
    const variables_31 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_31"});
    const variables_39 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_39"});

    const {trajectorySkills} = useSelector(state => state.Reducer);
    const [valueInputTag, setValueInputTag] = useState("");
    const [existTagsList, setExistTagsList] = useState([]);
    const [flagOpenSelectTags, setFlagOpenSelectTags] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [timeoutSearch, setTimeoutSearch] = useState(null);
    const [timeoutFetchPagination, setTimeoutFetchPagination] = useState(null);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const refParentTag = useRef();

    let addTrajectorySkill = (name) => {
        let obj = {};
        if (name.name) obj = name;
        else obj = {name};
        obj.index = trajectorySkills.length + 1
        requests.quests.add_trajectory_skill(obj)
            .catch((err) => console.error(err));
    };
    let fetchSearchTags = (page_size) => {
        setLoading(true);
        requests.profs.get_speciality_tags(valueInputTag, 1, page_size || pageSize)
            .then((v) => setExistTagsList(v.data))
            .finally(() => setLoading(false))
    }
    let handleScroll = (event) => {
        let scrollTop = event.target.scrollTop;
        if (timeoutFetchPagination)
            clearTimeout(timeoutFetchPagination)
        if ((scrollTop >= (pageSize * 14)) && (existTagsList?.count > pageSize)) {
            setTimeoutFetchPagination(
                setTimeout(() => {
                    setPageSize(prev => prev + 10);
                    fetchSearchTags(pageSize + 10)
                }, 500)
            )
        }
    }

    useEffect(() => {
        if (valueInputTag.length > 1) {
            let lastSymbol = valueInputTag[valueInputTag.length - 1];
            if (lastSymbol !== " ") {
                setPageSize(10)
                setExistTagsList([]);
                if (timeoutSearch)
                    clearTimeout(timeoutSearch)

                setTimeoutSearch(setTimeout(() =>
                    fetchSearchTags(10), 500))
            }
        }
    }, [valueInputTag])
    return (
        <div
            className="select_block"
            style={{maxWidth}}
        >
            <Input
                padding={"3px 15px 5px"}
                value={valueInputTag}
                setValue={setValueInputTag}
                backgroundColor={"var(--background_lighten-dark)"}
                placeholder={variables_9}
                onFocus={() => setFlagOpenSelectTags(true)}
                onBlur={() => {
                    setTimeout(() => {
                        setFlagOpenSelectTags(false);
                    }, 300);
                }}
                zIndex={4}
            />
            {existTagsList?.results?.length > 0 &&
                <div
                    className={flagOpenSelectTags
                        ? "parent_tag open"
                        : "parent_tag"
                    }
                    onScroll={handleScroll}
                    ref={refParentTag}
                >
                    {existTagsList.results.map((elem, indx) =>
                        <div
                            className={"tag_"}
                            key={indx}
                            onClick={() => {
                                try {
                                    for (let i = 0; i < trajectorySkills.length; i++) {
                                        if (trajectorySkills[i].id === elem.id) {
                                            return message.warning(variables_31);
                                        }
                                    }
                                    addTrajectorySkill(elem);
                                    let newPrev = [...trajectorySkills];
                                    newPrev = [...newPrev, {name: elem.name, id: elem.id}];
                                    dispatch(setUserTrajectory(newPrev));
                                    message.success(variables_39)
                                    console.log('success added')
                                } catch (err) {
                                    message.error(err)
                                    console.error(err, 'err')
                                }
                            }}
                        >
                            {elem.name}
                        </div>
                    )}
                </div>
            }
            {loading &&
                <Spin className="spin_loading"/>
            }
        </div>
    );
};

export default FetchSkills;