import React, { useEffect, useState } from 'react';
import { defaultFuncAndData } from "../../../../utils/defaultData";
import { ReuseForm } from "../Landing";
import default_icon from '../../../../images/icons/default_course.png';
import requests from "../../../axios/requests";
import { message } from "antd";
import variableLanguage from "../../../../utils/hookGetVariablesLanguage";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";

const FooterForm = (props) => {
    const {
        data, nameFeedbackFooter, phoneFooter, textFeedbackFooter,
        handleChangeNameFeedback, setPhoneFooter, setTextFeedbackFooter,
        setFlagDropDownPhone, flagDropDownPhone, createUserQuestionFooter,
        flagAuth, profile, quest_id
    } = props;
    const variable_28 = variableLanguage({keyPage: "landing", keyVariable: "variable_28"});
    const variable_34 = variableLanguage({keyPage: "landing", keyVariable: "variable_34"});
    const variable_35 = variableLanguage({keyPage: "landing", keyVariable: "variable_35"});
    const variable_36 = variableLanguage({keyPage: "landing", keyVariable: "variable_36"});
    const variable_39 = variableLanguage({keyPage: "landing", keyVariable: "variable_39"});
    const variable_40 = variableLanguage({keyPage: "landing", keyVariable: "variable_40"});
    const variable_52 = variableLanguage({keyPage: "landing", keyVariable: "variable_52"});

    const history = useNavigate();

    const [strBtn, setStrBtn] = useState("");

    let currentButtonStr = () => {
        let str = "Оплатить";
        if (data !== null) {
            if (data?.participateprice) {
                if (data?.referral) {
                    str = variable_39;
                    return str
                }
                if (strBtn)
                    str = strBtn;
                else
                    str = variable_39;
            } else {
                if (strBtn)
                    str = strBtn;
                else str = variable_28;
            }
        }
        return str;
    };
    let addRequestApi = () => {
        if (data?.isCorporate) {
            requests.quests
                .add_request_quest({ses: quest_id, userName: profile.fio})
                .then((response) => {
                    if (typeof response.data.status !== "undefined" && response.data.status === "already requested") {
                        return message.warning(variable_34);
                    }
                    return message.success(variable_35);
                })
                .catch((e) => {
                    console.log(e.message);
                });
            return;
        }
        if (data?.participateprice) {
            requests.quests.payment_init_post({ses: quest_id.replace("/", "")})
                .then((res) => {
                    if (res.data.payment_url)
                        window.open(res.data.payment_url, '_blank')
                })
                .catch(err => {
                    console.error(err);
                });
        } else {
            requests.quests
                .add_request_quest({quest_id: data.id, userName: profile.fio})
                .then((response) => {
                    if (typeof response.data.status !== "undefined" && response.data.status === "already requested") {
                        return message.warning(variable_34);
                    }
                    return message.success(variable_52);
                })
                .catch((e) => {
                    console.log(e.message);
                    message.error(variable_36);
                });
        }
    };
    let sendDiscrabe = () => {
        if (flagAuth) {
            if (data?.referral) {
                requests.quests.get_referral(data.referral)
                    .then(res => {
                        setTimeout(() => {
                            window.open(res.data?.url, "_blank")
                        }, 100)
                    })
                    .catch(err => console.error(err))
                return
            }
            if (strBtn === variable_40)
                history(`/student/course/lesson/${data.id}`);
            else
                addRequestApi();
        } else {
            window.localStorage.setItem("previous_course_link", window.location.href);
            history("/student/auth");
        }
    }

    useEffect(() => {
        if (data !== null) {
            if (localStorage.getItem("token")) {
                requests.stages.get_stages(data.id)
                    .then((v) => {
                        if (v.status === 200) {
                            if (v.data.length > 0) {
                                setStrBtn(variable_40);
                            }
                        }
                    })
                    .catch(err => console.error(err));
            }
        }
    }, [data]);
    return (
        <div
            className={data?.banner_position === "right" ? "footer right" : "footer"}
            style={
                data?.banner_position === null
                    ? {background: `url(${defaultFuncAndData.defaultImage(data?.speciality - 1) || default_icon}) no-repeat`}
                    : data?.banner
                        ? {background: `url(${data.banner}) no-repeat`}
                        : {background: `url(${defaultFuncAndData.defaultImage(data?.speciality - 1) || default_icon}) no-repeat`}
            }
        >
            <ReuseForm
                nameFeedback={nameFeedbackFooter}
                phone={phoneFooter}
                textFeedback={textFeedbackFooter}
                setNameFeedback={(value => handleChangeNameFeedback(value, true))}
                setPhone={(value) => setPhoneFooter(value)}
                setTextFeedback={(value) => setTextFeedbackFooter(value)}
                setFlagDropDownPhone={setFlagDropDownPhone}
                flagDropDownPhone={flagDropDownPhone}
                createUserQuestion={createUserQuestionFooter}
            />
            {data?.banner_position !== null &&
                <Button
                    className={"btn_describe"}
                    text={flagAuth
                        ? currentButtonStr()
                        : data?.participateprice
                            ? variable_39
                            : variable_28
                    }
                    click={() => sendDiscrabe()}
                    padding={"12px"}
                    maxWidth={"280px"}
                    backgroundColor={"var(--dark)"}
                    boxShadow={"none"}
                />
            }
        </div>
    );
};

export default FooterForm;