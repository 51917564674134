import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { defaultFuncAndData } from "../../../utils/defaultData";
import variableLanguage from "../../../utils/hookGetVariablesLanguage";
import { motion } from "framer-motion/dist/framer-motion";

import { ReactComponent as Search_talants_preview } from '../../../images/search_talants/search_talants-preview.svg';
import arrow from "../../../images/arrow.svg";

const SearchTalants = () => {
    const variable_8 = variableLanguage({keyPage: 'searchTalants', keyVariable: 'variable_8'});
    const variable_9 = variableLanguage({keyPage: 'searchTalants', keyVariable: 'variable_9'});
    const variable_10 = variableLanguage({keyPage: 'searchTalants', keyVariable: 'variable_10'});
    const variable_11 = variableLanguage({keyPage: 'searchTalants', keyVariable: 'variable_11'});
    const variable_12 = variableLanguage({keyPage: 'searchTalants', keyVariable: 'variable_12'});
    const variable_13 = variableLanguage({keyPage: 'searchTalants', keyVariable: 'variable_13'});
    const variable_14 = variableLanguage({keyPage: 'searchTalants', keyVariable: 'variable_14'});
    const variable_15 = variableLanguage({keyPage: 'searchTalants', keyVariable: 'variable_15'});
    const variable_16 = variableLanguage({keyPage: 'searchTalants', keyVariable: 'variable_16'});
    const variable_17 = variableLanguage({keyPage: 'searchTalants', keyVariable: 'variable_17'});
    const variable_18 = variableLanguage({keyPage: 'searchTalants', keyVariable: 'variable_18'});

    const history = useNavigate();
    const {language} = useSelector(state => state.Reducer);

    const [openQuestion, setOpenQuestion] = useState(false);
    const [flagAuth, setFlagAuth] = useState(false);
    const [flagHiddenFotter, setFlagHiddenFotter] = useState(null);
    const [scrollY, setScrollY] = useState({});

    let handleScroll = (event) => {
        if (event && window.innerWidth < 821) {
            setScrollY(event.currentTarget.scrollTop);
            if (event.currentTarget.scrollTop < scrollY)
                setFlagHiddenFotter(true);
            else
                setFlagHiddenFotter(false);
        }
    };

    useEffect(() => {
        if (localStorage.getItem("token"))
            setFlagAuth(true)
    }, [])
    return (
        <motion.div
            className={flagAuth
                ? "search_talants"
                : "search_talants not_auth"
            }
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        >
            <div className="header">
                <span>{variable_8}</span>
                <div
                    className={flagAuth ? "btn" : "btn sign_in"}
                    onClick={() => {
                        if (flagAuth)
                            history("/student/profile")
                        else {
                            window.localStorage.setItem("previous_course_link", window.location.href)
                            history("/student/auth")
                        }
                    }}
                >
                    <span>
                        {flagAuth
                            ? variable_9
                            : variable_10
                        }
                    </span>
                </div>
            </div>
            <div className="preview_title">
                <div className="page_info">
                    <div className="title">
                        {variable_11}
                    </div>
                    <div className="text">
                        {variable_12}
                    </div>
                    <div
                        className="btn_find"
                        onClick={() => {
                            if (flagAuth)
                                history("/student/freelance-list/all")
                            else
                                history("/freelance-list/all")
                        }}
                    >
                        <span>{variable_13}</span>
                    </div>
                </div>
                <div className="page_image">
                    <div className="image">
                        <Search_talants_preview/>
                    </div>
                </div>
            </div>
            <div className="preview_title-mobile">
                <div className="title">
                    <span>{variable_14}</span>
                </div>
                <div className="page_info">
                    <div className="info">{variable_11}</div>
                    <div className="text">{variable_12}</div>
                    <div
                        className="btn_find"
                        onClick={() => {
                            if (flagAuth)
                                history("/student/freelance-list/all")
                            else
                                history("/freelance-list/all")
                        }}
                    >
                        <span>{variable_13}</span>
                    </div>
                </div>
            </div>
            <div className="search_category">
                <div className="h2">{variable_15}</div>
                <div className="list_category">
                    {defaultFuncAndData.categoryArray().map((elem, indx) =>
                        <div
                            className="category"
                            key={indx}
                            onClick={() => {
                                if (flagAuth)
                                    history(elem.url)
                                else
                                    history(elem.url.replace("/student", ""))

                            }}
                        >
                            <div className="label">
                                <div
                                    className="img"
                                    style={{background: defaultFuncAndData.categoryArray()[indx].color}}
                                >
                                    <img src={defaultFuncAndData.categoryArray()[indx].src}/>
                                </div>
                                <span>
                                        {language === "ru"
                                            ? defaultFuncAndData.categoryArray()[indx].text
                                            : defaultFuncAndData.categoryArray()[indx].text_en
                                        }
                                    </span>
                            </div>
                            {/* <div className="info">
                                    <div className="rating">
                                        <img src={rating}/>
                                        <span>4.8/5</span>
                                    </div>
                                    <div className="count_skills">
                                        1350 навыка
                                    </div>
                                </div>*/}
                        </div>
                    )}
                </div>
            </div>
            <div className="asked_questions">
                <div className="h2">{variable_16}</div>
                <div
                    className={openQuestion ? "question open" : "question"}
                    onClick={() => setOpenQuestion(!openQuestion)}
                >
                    <span>{variable_17}</span>
                    <div className="arrow_icon">
                        <img src={arrow}/>
                    </div>
                    <div className="question_drop">
                        <span>{variable_18}</span>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default SearchTalants;