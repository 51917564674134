import "./Checkbox.scss";
import cn from "classnames";

const Checkbox = ({checked, htmlFor, setCheckedValue, label, errorAnswer}) => {
    return (
        <div
            className={cn("custom_checkbox", {
                errorAnswer
            })}
        >
            <input
                type="checkbox"
                id={htmlFor}
                checked={checked}
                onChange={(e) => setCheckedValue(e.target.checked)}
            />
            {label &&
                <label htmlFor={htmlFor} className={checked ? "active" : ""}>
                    <div>{label}</div>
                </label>
            }
        </div>
    );
};

export default Checkbox;