import { call, put, takeEvery } from 'redux-saga/effects';
import requests from "../../axios/requests";

function* fetchUser() {
    // тестовый запрос.
    try {
        const user = yield call(requests.users.get_profile);
        console.log(user,'user saga')
        yield put({type: "USER_FETCH_SUCCEEDED", user: user});
    } catch (e) {
        console.log(e,'error')
        yield put({type: "USER_FETCH_FAILED", message: e.message});
    }
}

export function* mySaga() {
    yield takeEvery("USER_FETCH_REQUESTED", fetchUser);
}
