import React, { memo } from "react";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";

import { defaultFuncAndData } from "../../../../utils/defaultData";
import variableLanguage from '../../../../utils/hookGetVariablesLanguage';
import DangerSkill from "./DangerSkill";

const SkillBlockProfession = (props) => {
    const {
        userSkills, professionSkillLeft, addSkillTraektory,
        needAnimation, setCoordsTagAnimation
    } = props;
    let dataDanger = defaultFuncAndData.funcMissingsSkill(userSkills, professionSkillLeft).missingSkills;
    let dataHave = defaultFuncAndData.funcMissingsSkill(userSkills, professionSkillLeft).haveSkills;

    const variables_15 = variableLanguage({keyPage: 'searchProfession', keyVariable: 'variables_15'});
    const variables_16 = variableLanguage({keyPage: 'searchProfession', keyVariable: 'variables_16'});
    const variables_17 = variableLanguage({keyPage: 'searchProfession', keyVariable: 'variables_17'});
    const variables_35 = variableLanguage({keyPage: 'searchProfession', keyVariable: 'variables_35'});

    return (
        <div className="tags_block">
            {dataHave && dataHave.length > 0
                ? <>
                    <div className="h2">{variables_35}</div>
                    <div className="h3">{variables_15}</div>
                    <div className="have_skill">
                        {dataHave.map((skill, indx) =>
                            <AnimatePresence key={indx}>
                                <motion.div
                                    initial={{opacity: 0, transform: "scale(0.5)"}}
                                    animate={{opacity: 1, transform: "scale(1)"}}
                                    exit={{opacity: 0, transform: "scale(1)"}}
                                    transition={{delay: .6}}
                                >
                                    <div
                                        key={`${indx}_`}
                                        className={skill.status === "added" ? "skill unlearned" : "skill"}
                                    >
                                        {skill.name}
                                    </div>
                                </motion.div>
                            </AnimatePresence>
                        )}
                    </div>
                </>
                : ''
            }
            {typeof dataDanger !== 'undefined' &&
                <>
                    {dataDanger && dataDanger.length > 0
                        ? <div className="h3">{variables_16}</div>
                        : <div className="h3">{variables_17}</div>
                    }
                </>
            }
            {dataDanger && dataDanger.length !== 0 &&
                <DangerSkill
                    data={dataDanger}
                    addSkillTraektory={(skill) => {
                        addSkillTraektory(skill)
                    }}
                    setCoordsTagAnimation={setCoordsTagAnimation}
                    needAnimation={needAnimation}
                />
            }
            {/*Если выученных скиллов нет вообще - отобразить все нужные скиллы для активной професиии*/}
            {typeof dataDanger === 'undefined' &&
                <>
                    {professionSkillLeft && professionSkillLeft.length > 0
                        ? <DangerSkill
                            data={professionSkillLeft}
                            addSkillTraektory={(skill) => {
                                addSkillTraektory(skill)
                            }}
                            setCoordsTagAnimation={setCoordsTagAnimation}
                            needAnimation={needAnimation}
                        />
                        : <div className="h3">{variables_17}</div>
                    }
                </>
            }
        </div>
    );
};

export default memo(SkillBlockProfession);