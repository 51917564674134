import React, { useEffect, useRef, useState } from 'react';
import Slider from "react-slick";
import fake_photos from "../../../../images/fake_photos.png";
import variableLanguage from "../../../../utils/hookGetVariablesLanguage";
import { ReuseDots } from "../Landing";
import slider_prev from "../../../../images/landing/slider_prev.png";
import slider_next from "../../../../images/landing/slider_next.png";

const Speakers = ({data, speakersRef, speakersRefMobile}) => {
    const variable_2 = variableLanguage({keyPage: "landing", keyVariable: "variable_2"});
    const variable_48 = variableLanguage({keyPage: "landing", keyVariable: "variable_48"});

    const [activeSlideSpikerMobile, setActiveSlideSpikerMobile] = useState(0);

    let PrevArrow = ({className, style, onClick}) => {
        return (
            <div
                className={className}
                style={{...style, display: "block"}}
                onClick={onClick}
            >
                <img src={slider_prev}/>
            </div>
        );
    };
    let NextArrow = ({className, style, onClick}) => {
        return (
            <div
                className={className}
                style={{...style, display: "block"}}
                onClick={onClick}
            >
                <img src={slider_next}/>
            </div>
        );
    };

    const carouselRef = useRef(null);
    const settingsSpiker = {
        dots: false,
        infinite: true,
        speed: 700,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: "spikers_slides",
        prevArrow: <PrevArrow/>,
        nextArrow: <NextArrow/>
    };
    const settingsSpikerMobile = {
        /*dots: true,*/
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: "spikers_slides-mobile",
        arrows: false,
        initialSlide: activeSlideSpikerMobile
    };

    useEffect(() => {
        if (carouselRef.current?.slickGoTo)
            carouselRef.current.slickGoTo(activeSlideSpikerMobile);
    }, [activeSlideSpikerMobile]);
    return (
        <>
            {data && Object.keys(data).length > 0 && data?.quest_speakers?.length > 0 &&
                <>
                    <div className="title_speakers">
                        {variable_2}
                    </div>
                    <div ref={speakersRef} className="spikers_new">
                        <Slider
                            {...settingsSpiker}
                        >
                            {data.quest_speakers.map((elem, indx) =>
                                <div key={indx} className={"slider_card"}>
                                    <div
                                        className="slider_image"
                                        style={{
                                            background:
                                                `url(${elem?.avatar || fake_photos}) no-repeat`
                                        }}
                                    />
                                    <div className="info_speaker">
                                        {/*<div className="title">
                                            {variable_48}
                                        </div>*/}
                                        {/*{data !== null && Object.keys(data).length !== 0 && data.speciality_tag?.length > 0 &&
                                            <div className="skills_list">
                                                {data.speciality_tag.map((elem, indx) =>
                                                    <div className="skill" key={indx}>
                                                        <span>{elem.name}</span>
                                                    </div>
                                                )}
                                            </div>
                                        }*/}
                                        <div className="name">
                                            <span>{elem?.first_name || ""} {elem?.last_name || ""}</span>
                                        </div>
                                        <div className="position">
                                            <span>
                                                {elem?.position || ""}
                                            </span>
                                        </div>
                                        <pre className="description">
                                            {elem?.description || ""}
                                        </pre>
                                    </div>
                                </div>
                            )}
                        </Slider>
                    </div>
                    <div className="spikers_mobile-new" ref={speakersRefMobile}>
                        <div className="speakers_title-mobile">{variable_2}</div>
                        <div className="spikers_mobile-inner">
                          {/*  <div className="title">{variable_48}</div>
                            {data && Object.keys(data).length !== 0 && data.speciality_tag?.length > 0 &&
                                <div className="skills_list">
                                    {data.speciality_tag.map((elem, indx) =>
                                        <div className="skill" key={indx}>
                                            <span>{elem.name}</span>
                                        </div>
                                    )}
                                </div>
                            }*/}
                            <Slider
                                {...settingsSpikerMobile}
                                afterChange={(e) => {
                                    setActiveSlideSpikerMobile(e);
                                }}
                                ref={carouselRef}
                            >
                                {data.quest_speakers.map((elem, indx) =>
                                    <div key={indx}>
                                        <img src={elem.avatar || fake_photos}/>
                                        <div
                                            className="name">{elem.first_name || ""} {elem.last_name || ""}</div>
                                        <div className="position">{elem.position || ""}</div>
                                        <pre className="description">{elem.description || ""}</pre>
                                    </div>
                                )}
                            </Slider>
                        </div>
                        <ReuseDots
                            activeSlide={activeSlideSpikerMobile}
                            setActiveSlide={(indx) => setActiveSlideSpikerMobile(indx)}
                            countDots={data.quest_speakers.length}
                        />
                    </div>
                </>
            }
        </>
    );
};

export default Speakers;