import { message, Upload } from 'antd'
import React, { useState } from "react";
import styled from "styled-components"
import requests from "../axios/requests";
import { ReactComponent as Upload_ } from "../../images/icons/upload.svg";
import variableLanguage from "../../utils/hookGetVariablesLanguage";

const {Dragger} = Upload;

const HideSpinner = styled.div`
  /*.ant-upload-text-icon {visibility: hidden;}*/
`;

export const StorageUploader = (props) => {
    let {
        yandexKey, count, fileTypes, setFileExist, editable, setFileName,
        fileWillBeUpload = false, removeFile = false, setFileListProps = false
    } = props;

    const variable_48 = variableLanguage({keyPage: 'lesson', keyVariable: 'variable_48'})
    const variable_59 = variableLanguage({keyPage: 'lesson', keyVariable: 'variable_59'})

    const [fileList, setFileList] = useState(editable ? [
        {
            uid: '-1',
            name: yandexKey,
            status: 'done',
            response: 'Server Error 500', // custom error message to show
            url: `https://storage.yandexcloud.net/skill-storage${yandexKey}`,
        },
    ] : [])
    if (setFileExist) {
        if (fileList.length > 0) {
            setFileExist(true)
        } else {
            setFileExist(false)
        }
    }

    const customRequest = (file) => {
        if (file?.size / 1000 > 100000) {
            return message.warning(variable_59)
        }
        setFileName(file.name)
        requests.storage.storage_upload(yandexKey, file, (progress) => {
            var percentCompleted = Math.round((progress.loaded * 100) / progress.total)
            setFileList([{
                uid: file.name,
                name: file.name,
                percent: percentCompleted,
                status: (percentCompleted === 100) ? 'done' : 'uploading',
            }]);
            if (typeof setFileListProps === 'function') {
                setFileListProps([{
                    uid: file.name,
                    name: file.name,
                    percent: percentCompleted,
                    status: (percentCompleted === 100) ? "done" : "uploading",
                }]);
            }
        })
            .then(() => {
                message.success(`file uploaded successfully.`);
                if (fileWillBeUpload) {
                    fileWillBeUpload(true)
                }
            })
            .catch((e) => {
                message.error(`file upload failed.`);
                setFileList([{
                    uid: file.name,
                    name: file.name,
                    percent: 0,
                    status: 'error'
                }]);
            })
    }
    const uploadProps = {
        beforeUpload: (file) => {
            if (file) {
                customRequest(file);
            }
            return false;
        },
        onRemove: () => {
            setFileList([]);
            if (removeFile) {
                removeFile()
            }
        },
        fileList,
    }

    return (
        <>
            <HideSpinner>
                <Dragger
                    listType="picture"
                    accept={fileTypes}
                    maxCount={count}
                    {...uploadProps}
                    showUploadList={{showPreviewIcon: true}}
                    progress={{
                        strokeColor: {
                            '0%': '#108ee9',
                            '100%': '#87d068',
                        },
                        strokeWidth: 3
                    }}
                >
                    <Upload_/>
                    <p className="ant-upload-text">{variable_48}</p>
                </Dragger>
            </HideSpinner>
        </>
    )

}
