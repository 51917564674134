import React from "react";

const CustomCheckbox = ({ value, setValue, label,  id }) => {
    let handleChange = (e) => {
      setValue(e.target.checked);
    };
  return (
    <div className="form-group">
      <input
        type="checkbox"
        id={id || 'html'}
        checked={value}
        onChange={handleChange}
      />
      <label htmlFor={id || 'html'}>{label || ""}</label>
    </div>
  );
};

export default CustomCheckbox;