import React, { useEffect, useState } from 'react';
import {  useNavigate } from "react-router-dom";
import cn from "classnames";
import variableLanguage from "../../../../utils/hookGetVariablesLanguage";
import { skillsData } from "./data";

import Tag from "../../../components/Tag/Tag";
import Button from "../../../components/Button/Button";
import PlanningCourse from "../PlanningCourse/PlanningCourse";
import ModalSize from "../Modals/ModalSize/ModalSize";

import size_icon_2 from "../../../../images/generate_course/size_icon_2.svg";
import size_icon from "../../../../images/generate_course/size_icon.svg";
import banner from "../../../../images/generate_course/banner.jpg";
import ReactPlayer from "react-player";

const UnAuthenticated = ({sizeTrajectory, setSizeTrajectory}) => {
    const variable_3 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_3'});
    const variable_4 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_4'});
    const variable_6 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_6'});
    const variable_19 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_19'});
    const variable_21 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_21'});
    const variable_24 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_24'});
    const variable_25 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_25'});
    const variable_34 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_34'});

    const history = useNavigate();
    const [selectTag, setSelectTag] = useState({});
    const [openModalSize, setOpenModalSize] = useState(false);
    const [currentIndxes, setCurrentIndxes] = useState(0);

    useEffect(() => {
        setCurrentIndxes(0)
    }, [selectTag])
    return (
        <div>
            <div
                className={`trajectory_block`}
            >
                <div
                    className={cn("trajectory width_50", {
                        open: sizeTrajectory
                    })
                    }
                >
                    <div className="top_settings">
                        <div className="label">
                            {variable_34}
                        </div>
                        <div
                            className="size_btn"
                            onClick={() => setSizeTrajectory(!sizeTrajectory)}
                        >
                                    <span>
                                       {sizeTrajectory ? variable_4 : variable_3}
                                    </span>
                            {sizeTrajectory
                                ? <img src={size_icon_2} alt="size_icon"/>
                                : <img src={size_icon} alt="size_icon"/>
                            }
                        </div>
                    </div>
                    {skillsData?.length > 0 &&
                        <div className="list_tags">
                            {skillsData.map((elem) =>
                                <Tag
                                    elem={elem}
                                    key={elem.id}
                                    cursorPointer={true}
                                    hideRemoveIcon={true}
                                    countCourses={undefined}
                                    countPlanes={undefined}
                                    exist_quest={false}
                                    className={cn("", {
                                        color_active: selectTag?.name === elem.name,
                                    })}
                                    click={(event) => {
                                        event.stopPropagation();
                                        setSelectTag(elem)
                                    }}
                                />
                            )}
                        </div>
                    }
                    <Button
                        text={variable_19}
                        click={() => {
                            window.localStorage.setItem("previous_course_link", "https://web.skillometer.ru/");
                            history("/student/auth");
                        }}
                        margin={"auto 0 0 0"}
                        padding={"7px 15px"}
                        maxWidth={"100%"}
                        backgroundColor={"var(--background_lighten-dark)"}
                        boxShadow={"none"}
                    />
                </div>
                <div className="banner">
                    <img src={banner} alt="banner"/>
                </div>
            </div>
            {Object.values(selectTag)?.length > 0
                ? <div className="list_planning unAuthenticated">
                    <>
                        {Array(4).fill(0).map((_, indx) => {
                                let text = selectTag.text_1;
                                switch (indx) {
                                    case 1: {
                                        text = selectTag.text_2;
                                        break;
                                    }
                                    case 2: {
                                        text = selectTag.text_3;
                                        break;
                                    }
                                    case 3: {
                                        text = selectTag.text_4;
                                        break;
                                    }
                                }
                                if (indx < currentIndxes) {
                                    return (
                                        <PlanningCourse
                                            {...selectTag}
                                            text={text}
                                            key={indx}
                                            indx={indx}
                                            openModalSize={() => {
                                                setOpenModalSize(true);
                                            }}
                                        />
                                    )
                                } else {
                                    return (
                                        <div
                                            className="planning_course btn"
                                            key={indx}
                                        >
                                            <div className="title_index">
                                                <div className="h1">
                                                    <>{variable_21} </>
                                                    № {indx + 1}
                                                </div>
                                            </div>
                                            <Button
                                                text={variable_6}
                                                click={() => {
                                                    setCurrentIndxes(prev => prev + 1)
                                                }}
                                                padding={"7px 15px"}
                                                maxWidth={"fit-content"}
                                                boxShadow={"none"}
                                                disabled={false}
                                            />
                                        </div>
                                    )
                                }
                            }
                        )}
                    </>
                </div>
                : <div className="player">
                    <ReactPlayer
                        className={"player_demo"}
                        controls={true}
                        url={"https://www.youtube.com/watch?v=VuCj68f6pUQ"}
                        width={"100%"}
                    />
                </div>
            }
            <ModalSize
                openModalSize={openModalSize}
                currentElemSize={selectTag}
                setOpenModalSize={() => {
                    setOpenModalSize(false)
                    /*setSelectTag({})*/
                }}
            />
        </div>
    );
};

export default UnAuthenticated;