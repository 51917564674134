import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { message, Modal } from "antd";
import variableLanguage from "../../../../utils/hookGetVariablesLanguage";
import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";

import close from "../../../../images/generate_course/close.svg";
import "./ModalCertificate.scss";
import { defaultFuncAndData } from "../../../../utils/defaultData";

const ModalCertificate = ({setOpenModal, handleGenerateCertificate, currentQuest, setOldProgress}) => {
    const variable_63 = variableLanguage({keyPage: "lesson", keyVariable: "variable_63"});
    const variable_64 = variableLanguage({keyPage: "lesson", keyVariable: "variable_64"});

    const {user} = useSelector(state => state.Reducer);
    const [fio, setFio] = useState("");
    const [email, setEmail] = useState("");

    useEffect(() => {
        if (Object.keys(user)?.length > 0) {
            setFio(user.fio)
            setEmail(user.email)
        }
    }, [user])
    useEffect(() => {
        return () => {
            if(currentQuest?.id) {
                let newArr = JSON.parse(localStorage.getItem("courseCompleted"))
                newArr.push(currentQuest?.id)
                localStorage.setItem("courseCompleted", JSON.stringify(newArr))
            }
        }
    }, [])
    return (
        <Modal
            open={true}
            footer={null}
            className={"modal_certificate"}
            closable={false}
            onCancel={() => {
                setOpenModal(false)
                setOldProgress(100)
            }}
            maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.70)"}}
            bodyStyle={{padding: "25px", borderRadius: 30}}
        >
            <div className="title">
                <div className="h1">
                    {variable_63}
                </div>
                <div
                    className="close"
                    onClick={() => {
                        setOpenModal(false)
                        setOldProgress(100)
                      /*  let newArr = JSON.parse(localStorage.getItem("courseCompleted"))
                        newArr.push(currentQuest?.id)
                        localStorage.setItem("courseCompleted", JSON.stringify(newArr))*/
                    }}
                >
                    <img src={close} alt="close"/>
                </div>
            </div>
            <div className="label">{variable_64}</div>
            <Input
                padding={"3px 15px 5px"}
                margin={"0 0 15px 0"}
                value={fio}
                setValue={setFio}
                placeholder={variable_64}
            />
            <div className="label">Email</div>
            <Input
                padding={"3px 15px 5px"}
                margin={"0 0 15px 0"}
                value={email}
                setValue={setEmail}
                placeholder={"Email"}
            />
            <Button
                text={variable_63}
                click={() => {
                    if (!defaultFuncAndData.validateEmail(email))
                        return message.error("Введите валидный email")
                    if (!fio)
                        message.error("Заполните ФИО")
                    else handleGenerateCertificate(fio, email)
                }}
                margin={"15px 15px 0 0"}
                padding={"8px 9px 9px 9px"}
                backgroundColor={"var(--background_lighten-dark)"}
                boxShadow={"none"}
                zIndex={10}
            />
        </Modal>

    );
};

export default ModalCertificate;