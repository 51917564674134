import React, { useRef, useState } from 'react';
import InputMask from "react-input-mask";
import requests from "../../../axios/requests";
import { message as messageWarn } from "antd";
import variableLanguage from "../../../../utils/hookGetVariablesLanguage";
import { useLocation, useNavigate } from "react-router-dom";
import QrAuth from "./QrAuth";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";

import { ReactComponent as PlaymarketIcon } from "../../../../images/auth/playmarket.svg";
import { ReactComponent as AppstoreIcon } from "../../../../images/auth/app_store.svg";
import arrow from '../../../../images/arrow.svg';
import rus from "../../../../images/country/russian_icon.png";
import belarus from "../../../../images/country/belarus_icon.png";
import kazahstan from "../../../../images/country/kazahstan_icon.png";
import azer from "../../../../images/country/az.png";
import uz from "../../../../images/country/uz.png";
import gr from "../../../../images/country/gr.png";
import kr from "../../../../images/country/kr.png";
import app_store from '../../../../images/auth/app_store.svg';
import './Step_1.scss';

const Step_1 = (props) => {
    const history = useNavigate();

    const {phone, enteredCodStep, setCurrent, setName, setFio, setImage, setPhone, setEnteredCodStep} = props;
    const variables_2 = variableLanguage({keyPage: "authorization", keyVariable: "variables_2"});
    const variables_3 = variableLanguage({keyPage: "authorization", keyVariable: "variables_3"});
    const variables_4_1 = variableLanguage({keyPage: "authorization", keyVariable: "variables_4"}).part_1;
    const variables_4_2 = variableLanguage({keyPage: "authorization", keyVariable: "variables_4"}).part_2;
    const variables_5 = variableLanguage({keyPage: "authorization", keyVariable: "variables_5"});
    const variables_6 = variableLanguage({keyPage: "authorization", keyVariable: "variables_6"});
    const variables_7 = variableLanguage({keyPage: "authorization", keyVariable: "variables_7"});
    const variables_8 = variableLanguage({keyPage: "authorization", keyVariable: "variables_8"});
    const variables_9 = variableLanguage({keyPage: "authorization", keyVariable: "variables_9"});
    const variables_10 = variableLanguage({keyPage: "authorization", keyVariable: "variables_10"});
    const variables_11 = variableLanguage({keyPage: "authorization", keyVariable: "variables_11"});
    const variables_12 = variableLanguage({keyPage: "authorization", keyVariable: "variables_12"});
    const variables_21 = variableLanguage({keyPage: "authorization", keyVariable: "variables_21"});
    const variables_22 = variableLanguage({keyPage: "authorization", keyVariable: "variables_22"});
    const variables_25 = variableLanguage({keyPage: "authorization", keyVariable: "variables_25"});
    const variables_50 = variableLanguage({keyPage: "authorization", keyVariable: "variables_50"});
    const variables_51 = variableLanguage({keyPage: "authorization", keyVariable: "variables_51"});
    const variables_52 = variableLanguage({keyPage: "authorization", keyVariable: "variables_52"});
    const variables_53 = variableLanguage({keyPage: "authorization", keyVariable: "variables_53"});
    const variables_54 = variableLanguage({keyPage: "authorization", keyVariable: "variables_54"});
    const variables_55 = variableLanguage({keyPage: "authorization", keyVariable: "variables_55"});
    const variables_56 = variableLanguage({keyPage: "authorization", keyVariable: "variables_56"});
    const variables_57 = variableLanguage({keyPage: "authorization", keyVariable: "variables_57"});

    const [code, setCode] = useState("");
    const [message, setMessage] = useState();
    const [activeMaskPhone, setActiveMaskPhone] = useState(0);
    const [smsTimeout, setSmsTimeout] = useState(false);
    const [flagDropDownPhone, setFlagDropDownPhone] = useState(false);
    const [flagCaptcha, setFlagCaptcha] = useState(false);

    const inputRefCod = useRef();
    const refMaskInput = useRef();
    const recaptchaRef = useRef();
    const arrMaskPhone = [
        "+7 (999) 999-99-99",
        "+375 (99) 999-99-99",
        "+7 (999) 999-99-99",
        "+\\9\\9\\4 (99) 999-99-99",
        "+\\9\\9\\8 (99) 999-99-99",
        "+\\9\\9\\5 (999) 999-99-99",
        "+\\9\\9\\6 (999) 99-99-99",
    ];
    const arrIconsCountry = [
        <div className="icon_country"><img src={rus}/></div>,
        <div className="icon_country"><img src={belarus}/></div>,
        <div className="icon_country"><img src={kazahstan}/></div>,
        <div className="icon_country"><img src={azer}/></div>,
        <div className="icon_country"><img src={uz}/></div>,
        <div className="icon_country"><img src={gr}/></div>,
        <div className="icon_country"><img src={kr}/></div>,
    ];
    const sending_sms = {
        request_sms() {
            recaptchaRef.current.reset();

            if (phone && phone.includes("_"))
                return messageWarn.error(variables_21);
            if (!phone)
                return messageWarn.error(variables_21);
            requests.users
                .request_sms({phone})
                .then((response) => {
                    if (response.data.result === "sent" || response.data.status === "success") {
                        setEnteredCodStep(true);
                    }
                })
                .catch((error) => {
                    console.error(error.response);
                });
        },
        validate_sms() {
            requests.users
                .validate_sms({
                    phone: phone,
                    code: code
                })
                .then((response) => {
                    if (response.status === 203) {
                        messageWarn.error(variables_22);
                        setMessage(variables_22);
                        return;
                    }
                    setName(response.data.name);
                    setFio(response.data.fio);
                    if (response.data?.user_exists) {
                        // если неаутентифицированный пользователь просматривал страницу, то редирект его на эту страницу.
                        if (window.localStorage.getItem("previous_course_link")) {
                            window.localStorage.setItem("token", response.data.auth_token);
                            window.localStorage.setItem("username", response.data.name);
                            window.localStorage.setItem("last_name", response.data.last_name);
                            window.localStorage.setItem("middle_name", response.data.middle_name);
                            window.location.href = window.localStorage.getItem("previous_course_link");
                            setTimeout(() => {
                                window.localStorage.removeItem("previous_course_link");
                            }, 1000)
                            return;
                        }
                        setImage(response.data.avatar);
                        setCurrent(4);
                        setEnteredCodStep(false);
                        window.localStorage.setItem("token", response.data.auth_token);
                        window.localStorage.setItem("username", response.data.name);
                        window.localStorage.setItem("last_name", response.data.last_name);
                        window.localStorage.setItem("middle_name", response.data.middle_name);
                    } else {
                        setCurrent(1);
                        messageWarn.warning(variables_25);
                    }
                })
                .catch((error) => console.error(error));
        }
    };
    return (
        <>
            {!enteredCodStep &&
                <div className="step_1">
                    {/*  <QrAuth
                        {
                            ...{
                                setName, setMessage, setCurrent, variables_2,
                                variables_3, variables_4_1, variables_4_2,
                                variables_5, variables_25, variables_7, variables_11
                            }
                        }
                    />*/}
                    <div className="block_phone">
                        <InputMask
                            className={flagDropDownPhone ? "phoneNumber active" : "phoneNumber"}
                            style={{width: "100%", zIndex: 3}}
                            value={phone}
                            ref={refMaskInput}
                            alwaysShowMask={true}
                            onChange={(e) => setPhone(e.target.value)}
                            onClick={() => refMaskInput.current.focus()}
                            mask={arrMaskPhone[activeMaskPhone]}
                            onKeyDown={async (e) => {
                                if (e.keyCode === 13) {
                                    const token = await recaptchaRef?.current?.executeAsync();
                                    if (token)
                                        sending_sms.request_sms();
                                }
                            }}
                        />
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={"6Lfr084mAAAAAMlGHEpV7ti8tqn70lulrj04v4Mt"}
                            className={"captcha"}
                            size="invisible"
                        />
                        {arrIconsCountry[activeMaskPhone]}
                        <div className={flagDropDownPhone ? "drop_down-phone active" : "drop_down-phone"}>
                            <ul>
                                <li onClick={() => {
                                    setActiveMaskPhone(0);
                                    setFlagDropDownPhone(false);
                                    setPhone("");
                                }}>
                                    <img width={21} height={15} src={rus}/>
                                    <span>+7 (___) ___-__-__ {variables_51}</span>
                                </li>
                                <li onClick={() => {
                                    setActiveMaskPhone(1);
                                    setFlagDropDownPhone(false);
                                    setPhone("");
                                }}>
                                    <img width={21} height={15} src={belarus}/>
                                    <span>+375 (__) ___-__-__ {variables_52}</span>
                                </li>
                                <li onClick={() => {
                                    setActiveMaskPhone(2);
                                    setPhone("");
                                    setFlagDropDownPhone(false);
                                }}>
                                    <img width={21} height={15} src={kazahstan}/>
                                    <span>+7 (___) ___-__-__ {variables_53}</span>
                                </li>
                                <li onClick={() => {
                                    setActiveMaskPhone(3);
                                    setPhone("");
                                    setFlagDropDownPhone(false);
                                }}>
                                    <img width={21} height={15} src={azer}/>
                                    <span>+994 (___) __-__-__ {variables_54}</span>
                                </li>
                                <li onClick={() => {
                                    setActiveMaskPhone(4);
                                    setPhone("");
                                    setFlagDropDownPhone(false);
                                }}>
                                    <img width={21} height={15} src={uz}/>
                                    <span>+998 (__) ___-__-__ {variables_55}</span>
                                </li>
                                <li onClick={() => {
                                    setActiveMaskPhone(5);
                                    setPhone("");
                                    setFlagDropDownPhone(false);
                                }}>
                                    <img width={21} height={15} src={gr}/>
                                    <span>+995 (___) __-__-__ {variables_56}</span>
                                </li>
                                <li onClick={() => {
                                    setActiveMaskPhone(6);
                                    setPhone("");
                                    setFlagDropDownPhone(false);
                                }}>
                                    <img width={21} height={15} src={kr}/>
                                    <span>+996 (___) __-__-__ {variables_57}</span>
                                </li>
                            </ul>
                        </div>
                        <Button
                            text={variables_8}
                            click={async () => {
                                const token = await recaptchaRef?.current?.executeAsync();
                                if (token)
                                    sending_sms.request_sms()
                            }}
                            maxWidth={"265px"}
                            margin={"20px 0"}
                            zIndex={3}
                        />
                        <div
                            className="arrow_phone"
                            tabIndex={0}
                            onBlur={() => {
                                setTimeout(() => {
                                    setFlagDropDownPhone(false);
                                }, 150);
                            }}
                            onClick={() => setFlagDropDownPhone(!flagDropDownPhone)}
                        >
                            <img src={arrow}/>
                        </div>
                        <div className="store_icons">
                            <a href="https://apps.apple.com/ru/app/skillometer/id1498020495">
                                {variables_6 === "ru"
                                    ? <AppstoreIcon
                                        width={150}
                                        height={50}
                                    />
                                    : <img
                                        src={app_store}
                                        width={150}
                                        height={50}
                                        style={{marginBottom: 10}}
                                    />
                                }

                            </a>
                            <a href="https://play.google.com/store/apps/details?id=study.android.familyapp&hl=ru&gl=RU">
                                <PlaymarketIcon
                                    width={150}
                                    height={50}
                                />
                            </a>
                        </div>
                    </div>

                </div>
            }
            {enteredCodStep &&
                <div className={"entered_code"}>
                    <p>{variables_9}</p>
                    <Input
                        value={code}
                        setValue={setCode}
                        padding={"6px 15px 8px"}
                        onKeyDown={(e) => {
                            if (e.key === "Enter")
                                sending_sms.validate_sms();
                        }}
                        autoFocus={true}
                        refProps={inputRefCod}
                        placeholder={variables_10}
                    />
                    <div className="warn">{message}</div>
                    <Button
                        text={variables_12}
                        click={() => sending_sms.validate_sms()}
                        maxWidth={"265px"}
                        margin={"15px 0 20px 0"}
                        zIndex={3}
                    />
                    {smsTimeout && (
                        <a
                            className="send_code-again"
                            onClick={() => {
                                setEnteredCodStep(false);
                                setSmsTimeout(false);
                            }}
                        >
                            <span>Отправить еще раз</span>
                        </a>
                    )}
                    <div className="store_icons">
                        <a href="https://apps.apple.com/ru/app/skillometer/id1498020495">
                            <AppstoreIcon
                                width={150}
                                height={50}
                            />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=study.android.familyapp&hl=ru&gl=RU">
                            <PlaymarketIcon
                                width={150}
                                height={50}
                            />
                        </a>
                    </div>
                </div>
            }
        </>
    );
};

export default Step_1;