import React, { useEffect, useRef, useState } from "react";
import requests from "../../../axios/requests";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser, setUserTrajectory } from "../../../redux/actions/userActions";
import variableLanguage from "../../../../utils/hookGetVariablesLanguage";

import { Modal } from "antd";
import Button from "../../../components/Button/Button";

import fake_photos from "../../../../images/fake_photos.png";
import left_top from "../../../../images/auth/left_top.png";
import left_middle from "../../../../images/auth/left_middle.png";
import right_bottom from "../../../../images/auth/right_bottom.png";
import './ModalWelcome.scss';

const ModalWelcome = ({image, name, setImage}) => {
    const [flagModalWelcome, setFlagModalWelcome] = useState(true);
    const history = useNavigate();
    const dispatch = useDispatch();
    const refBtn = useRef(null);

    const variables_46 = variableLanguage({keyPage: "authorization", keyVariable: "variables_46"});
    const variables_47 = variableLanguage({keyPage: "authorization", keyVariable: "variables_47"});

    let getApiTrajectorySkill = () => {
        requests.quests.get_trajectory_skill()
            .then(({data}) => dispatch(setUserTrajectory(data)))
            .catch((err) => console.error(err));
    };
    let getProfileUser = () => {
        requests.users.get_profile_new()
            .then((res) => {
                dispatch(setUser(res.data))
            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        refBtn?.current?.focus();
        setTimeout(() => {
            getProfileUser();
            getApiTrajectorySkill();
        }, 1000)
    }, [])
    return (
        <Modal
            open={flagModalWelcome}
            onCancel={() => setFlagModalWelcome(false)}
            footer={null}
            closable={false}
            bodyStyle={{backgroundColor: "#201E26", boxShadow: "none !important", borderRadius: 40, padding: 0}}
            className="modal_welcome"
            width={"45%"}
            centered={true}
            destroyOnClose={true}
        >
            <div className="content_modal">
                <img
                    src={image || fake_photos}
                    key={image}
                    style={{borderRadius: 15}}
                />
                <p>{name || "Unknown"}, {variables_46}</p>
                <Button
                    text={variables_47}
                    click={() => {
                        if (window.localStorage.getItem("previous_course_link")) {
                            history(window.localStorage.getItem("previous_course_link"));
                            window.localStorage.removeItem("previous_course_link");
                            setFlagModalWelcome(false)
                        } else {
                            setFlagModalWelcome(false)
                            history("/")
                        }
                        setImage("")
                    }}
                    keyDown={(event) => {
                        if (event.keyCode === 13) {
                            if (window.localStorage.getItem("previous_course_link")) {
                                history(window.localStorage.getItem("previous_course_link"));
                                window.localStorage.removeItem("previous_course_link");
                                setFlagModalWelcome(false)
                            } else {
                                setFlagModalWelcome(false)
                                history("/")
                            }
                            setImage("")
                        }
                    }}
                    maxWidth={"320px"}
                    margin={"0 auto"}
                    refProps={refBtn}
                    tabIndex={true}
                    zIndex={10}
                />
            </div>
            <div className="image_modal">
                <div
                    className="left_top"
                    style={{top: 30, left: "12%"}}
                >
                    <img width={85} height={85} src={left_top} alt=""/>
                </div>
                <div
                    style={{top: "43%", left: "8%"}}
                    className="left_middle">
                    <img
                        width={60}
                        height={60}
                        src={left_middle}
                        alt=""
                    />
                </div>
                <div
                    style={{top: "40%", right: "4%"}}
                    className="right_bottom">
                    <img
                        width={90}
                        height={90}
                        src={right_bottom}
                        alt=""
                    />
                </div>
            </div>
            <div className="image_modal-mobile" style={{display: "none"}}>
                <div
                    style={{top: 30, left: "16%"}}
                    className="left_top"
                >
                    <img
                        width={60}
                        height={60}
                        src={left_top}
                        alt=""
                    />
                </div>
                <div
                    style={{top: "54%", left: "11%"}}
                    className="left_middle"
                >
                    <img
                        width={40}
                        height={43}
                        src={left_middle}
                        alt=""
                    />
                </div>
                <div
                    style={{top: "54%", right: "8%"}}
                    className="right_bottom"
                >
                    <img width={48} height={48}
                         src={right_bottom}
                         alt=""
                    />
                </div>
            </div>
        </Modal>
    );
};

export default ModalWelcome;