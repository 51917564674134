import React from 'react';
import requests from "../../axios/requests";
import { Popconfirm } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import variableLanguage from "../../../utils/hookGetVariablesLanguage";
import cn from "classnames";

import { ReactComponent as Sidebar_1 } from "../../../images/sidebar/sidebar_1.svg";
import { ReactComponent as Sidebar_2 } from "../../../images/sidebar/sidebar_2.svg";
import icon_button from "../../../images/sidebar/icon_added_button.svg";
import { ReactComponent as MyCourse } from "../../../images/icons/my_course.svg";
import { ReactComponent as Notification } from "../../../images/icons/navbar_notification.svg";
import { ReactComponent as FreelanceIcon } from "../../../images/icons/freelance_icon.svg";
import { ReactComponent as GenerateCourse } from "../../../images/generate_course/generate_course.svg";
import fake_photos from "../../../images/fake_photos.png";
import nav_bar_exit from "../../../images/icons/sidebar_logout.svg";
import "./SidebarMobile.scss"
import { setShowBuyModal } from "../../redux/actions/actions";

const SidebarMobile = (props) => {
    const variable_1 = variableLanguage({keyPage: "sidebar", keyVariable: "variable_1"});
    const variable_2 = variableLanguage({keyPage: "sidebar", keyVariable: "variable_2"});
    const variable_3 = variableLanguage({keyPage: "sidebar", keyVariable: "variable_3"});
    const variable_4 = variableLanguage({keyPage: "sidebar", keyVariable: "variable_4"});
    const variable_5 = variableLanguage({keyPage: "sidebar", keyVariable: "variable_5"});
    const variable_6 = variableLanguage({keyPage: "sidebar", keyVariable: "variable_6"});
    const variable_7 = variableLanguage({keyPage: "sidebar", keyVariable: "variable_7"});
    const variable_8 = variableLanguage({keyPage: "sidebar", keyVariable: "variable_8"});
    const variable_9 = variableLanguage({keyPage: "sidebar", keyVariable: "variable_9"});
    const variable__6 = variableLanguage({keyPage: 'navbar', keyVariable: 'variable_6'});

    const {flagHiddenFotter, userPhoto} = props;
    const {user} = useSelector(state => state.Reducer);

    const dispatch = useDispatch();
    const history = useNavigate();
    const location = useLocation();

    let handleLogOut = () => {
        requests.users
            .logout()
            .then(() => {
                window.localStorage.removeItem("token");
                window.localStorage.removeItem("username");
                window.localStorage.removeItem("last_name");
                window.localStorage.removeItem("middle_name");
                window.localStorage.removeItem("fio");
                window.localStorage.removeItem("id");
                window.localStorage.removeItem("count_notification");
            })
            .catch((error) => {
                window.localStorage.removeItem("token");
                window.localStorage.removeItem("username");
                window.localStorage.removeItem("last_name");
                window.localStorage.removeItem("middle_name");
                window.localStorage.removeItem("fio");
                window.localStorage.removeItem("id");
                window.localStorage.removeItem("count_notification");
            })
            .finally(() => history("/student/auth"));
    };
    return (
        <>
            {(location.pathname === "/student/generate-course") &&
                <div
                    className={cn("added_generation", {
                        show: (flagHiddenFotter === null || flagHiddenFotter === true)
                    })}
                    onClick={() => dispatch(setShowBuyModal(true))}
                >
                    <img src={icon_button} alt="icon_button"/>
                    <span>{variable_9}</span>
                </div>
            }
            <div className={(flagHiddenFotter === null || flagHiddenFotter === true)
                ? "sidebar_mobile show"
                : "sidebar_mobile"
            }
            >
                <div className="item">
                    <div className="item_icon">
                        <img
                            src={user?.avatar || userPhoto || fake_photos}
                            onClick={() => history('/student/profile')}
                        />
                    </div>
                    <div className="item_label">
                        {variable_3}
                    </div>
                </div>
                <div className="item">
                    <div className="item_icon none_background">
                        <Notification
                            className={location?.pathname.includes("/notification") ? "active" : ""}
                            onClick={() => history('/notification')}
                        />
                    </div>
                    <div className="item_label">
                        {variable_4}
                    </div>
                </div>
                <div className="item">
                    <div className="item_icon">
                        <Sidebar_1
                            className={location?.pathname === "/" ? "active" : ""}
                            onClick={() => history('/')}
                        />
                    </div>
                    <div className="item_label">
                        {variable_1}
                    </div>
                </div>
                <div className="item">
                    <div className="item_icon">
                        <GenerateCourse
                            className={location?.pathname === "/student/generate-course" ? "active" : ""}
                            onClick={() => history('/student/generate-course')}
                        />
                    </div>
                    <div className="item_label">
                        {variable_5}
                    </div>
                </div>
                <div className="item">
                    <div className="item_icon">
                        <Sidebar_2
                            className={location?.pathname === "/student/course-list" ? "active" : ""}
                            onClick={() => history('/student/course-list')}
                        />
                    </div>
                    <div className="item_label">
                        {variable_2}
                    </div>
                </div>
                <div className="item">
                    <div className="item_icon">
                        <MyCourse
                            className={location?.pathname === "/student/my-courses" ? "active" : ""}
                            onClick={() => history('/student/my-courses')}
                        />
                    </div>
                    <div className="item_label">
                        {variable_6}
                    </div>
                </div>
                <div className="item">
                    <div className="item_icon">
                        <FreelanceIcon
                            className={location?.pathname === "/student/search-talants" ? "active" : ""}
                            onClick={() => {
                                history("/student/search-talants");
                            }}
                        />
                    </div>
                    <div className="item_label">
                        {variable_7}
                    </div>
                </div>
                <div className="item">
                    <div className="item_icon">
                        <Popconfirm
                            title={variable__6}
                            onConfirm={() => handleLogOut()}
                            okText="Да"
                            cancelText="Нет"
                        >
                            <img
                                src={nav_bar_exit}
                                width={38}
                                height={38}
                            />
                        </Popconfirm>
                    </div>
                    <div className="item_label">
                        {variable_8}
                    </div>
                </div>

            </div>
        </>
    );
};

export default SidebarMobile;