import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { motion } from "framer-motion/dist/framer-motion";
import { Pagination, Spin } from "antd";
import requests from "../../axios/requests";

import { constantsResume } from "../Profile/constantsResume";
import { defaultFuncAndData } from "../../../utils/defaultData";
import variableLanguage from "../../../utils/hookGetVariablesLanguage";
import Input from "../../components/Input/Input";
import FreelancerCard from "./FreelancerCard/FreelancerCard";

import roadmap from '../../../images/search_talants/roadmap.svg';
import { ReactComponent as Arrow } from "../../../images/search_talants/arrow.svg";
import { ReactComponent as ArrowDrop } from "../../../images/arrow.svg";
import { ReactComponent as Rating } from "../../../images/search_talants/rating.svg";

const FreelanceList = () => {
    const variable_8 = variableLanguage({keyPage: 'searchTalants', keyVariable: 'variable_8'})
    const variable_13 = variableLanguage({keyPage: 'searchTalants', keyVariable: 'variable_13'})
    const variable_19 = variableLanguage({keyPage: 'searchTalants', keyVariable: 'variable_19'})
    const variable_21 = variableLanguage({keyPage: 'searchTalants', keyVariable: 'variable_21'})
    const variable_22 = variableLanguage({keyPage: 'searchTalants', keyVariable: 'variable_22'})

    const params = useParams();
    const history = useNavigate();
    const {language} = useSelector(state => state.Reducer);

    const [data, setData] = useState([]);
    const [activeSortCatalog, setActiveSortCatalog] = useState("name");
    const [sortCatalogIndx, setSortCatalogIndx] = useState(0);
    const [activeCategory, setActiveCategory] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [openListSkills, setOpenListSkills] = useState(null);
    const [activeFavorite, setActiveFavorite] = useState(false);
    const [categoryLable, setCategoryLable] = useState("");
    const [flagDropDownSort, setFlagDropDownSort] = useState(false);
    const [flagDropDownCategory, setFlagDropDownCategory] = useState(false);
    const [flagAuth, setFlagAuth] = useState(false);
    const [loading, setLoading] = useState(false);
    const sortCatalog = [
        {value: "name", label: "ФИО", label_en: "Full name"},
        {value: "rate", label: "Ставка", label_en: "Rate"},
        {value: "skills_count", label: "Количество навыков", label_en: "Count of skills"},
        {value: "-name", label: "-ФИО", label_en: "-Full name"},
        {value: "-rate", label: "-Ставка", label_en: "-Rate"},
        {value: "-skills_count", label: "-Количество навыков", label_en: "-Count of skills"}
    ];

    let getLabelCategory = () => {
        switch (params?.category) {
            case "engineering_it":
                if (language === "ru")
                    setCategoryLable("Инженерные & IT")
                else setCategoryLable("Engineering & IT")
                setActiveCategory(0)
                break;
            case "science_medicine":
                if (language === "ru")
                    setCategoryLable("Наука & Медицина")
                else setCategoryLable("Science & Medicine")
                setActiveCategory(1)
                break;
            case "languages_copywriting":
                if (language === "ru")
                    setCategoryLable("Языки & Копирайтинг")
                else setCategoryLable("Languages & Copywriting")
                setActiveCategory(2)
                break;
            case "management_marketing":
                if (language === "ru")
                    setCategoryLable("Менеджмент & Маркетинг")
                else setCategoryLable("Management & Marketing")
                setActiveCategory(3)
                break;
            case "finance_law":
                if (language === "ru")
                    setCategoryLable("Финансы & Юриспруденция")
                else setCategoryLable("Finance & Law")
                setActiveCategory(4)
                break;
            case "design_creative":
                if (language === "ru")
                    setCategoryLable("Дизайн & Креатив")
                else setCategoryLable("Design & Creative")
                setActiveCategory(5)
                break;
            case "sport":
                if (language === "ru")
                    setCategoryLable("Спорт")
                else setCategoryLable("Sport")
                setActiveCategory(6)
                break;
            case "all":
                if (language === "ru")
                    setCategoryLable("Все категории")
                else setCategoryLable("All categories")
                setActiveCategory(7)
                break;
            default:
                if (language === "ru")
                    setCategoryLable("Инженерные & IT")
                else setCategoryLable("Engineering & IT")
                setActiveCategory(0)
        }
    }
    let getFreelanceListApi = async () => {
        setLoading(true)
        await defaultFuncAndData.delay(1000)

        requests.users.get_freelancers_list(
            activeFavorite, activeSortCatalog, search,
            params?.category === "all" ? "" : params?.category,
            currentPage, 20
        )
            .then(res => (setData(res.data), setLoading(false)))
            .catch(err => (console.error(err), setLoading(false)))
    }

    useEffect(() => {
        if (localStorage.getItem("token"))
            setFlagAuth(true)
    }, [])
    useEffect(() => {
        getFreelanceListApi()
        getLabelCategory();
    }, [activeSortCatalog, search, activeCategory, activeFavorite, currentPage])
    return (
        <motion.div
            className={flagAuth ? "freelancers_page" : "freelancers_page not_auth"}
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        >
            <div className="roadmap">
                <img src={roadmap}/>
                <span onClick={() => {
                    history("/student/search-talants")
                }}>
                        {variable_8}
                    </span>
                <Arrow/>
                <span>{categoryLable}</span>
            </div>
            <div className="h1">
                {categoryLable}
                {flagAuth &&
                    <div
                        className={activeFavorite ? "favorite active" : "favorite"}
                        onClick={() => setActiveFavorite(!activeFavorite)}
                    >
                        <div className="svg">
                            <Rating/>
                        </div>
                        <span>{variable_19}</span>
                    </div>
                }
            </div>
            <div className="filters_row">
                <div className="search">
                    <Input
                        padding={"5px 96px 7px 46px"}
                        value={search}
                        setValue={setSearch}
                        backgroundColor={"transparent"}
                        placeholder={variable_13}
                        needFindBtn={true}
                    />
                </div>
                <div className="category">
                    <span>{variable_21}</span>
                    <div
                        className={flagDropDownCategory ? "select_block active" : "select_block"}
                        onClick={() => setFlagDropDownCategory(!flagDropDownCategory)}
                        tabIndex={0}
                        onBlur={() => setFlagDropDownCategory(false)}
                    >
                            <span>
                                {language === 'ru'
                                    ? defaultFuncAndData.categoryArray()[activeCategory].text
                                    : defaultFuncAndData.categoryArray()[activeCategory].text_en
                                }
                            </span>
                        <ArrowDrop/>
                        <ul className={flagDropDownCategory ? "drop_down-category active" : "drop_down-category"}>
                            {defaultFuncAndData.categoryArray().map((elem, indx) =>
                                <li
                                    key={indx}
                                    onClick={() => {
                                        setSearch("");
                                        history(elem.url);
                                        setActiveCategory(indx);
                                        setFlagDropDownCategory(false);
                                    }}>
                                        <span
                                            className={activeCategory === indx ? "active" : ""}
                                        >
                                            {language === 'ru'
                                                ? elem.text
                                                : elem.text_en
                                            }
                                        </span>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
                <div className="category">
                    <span>{variable_22}</span>
                    <div
                        className={flagDropDownSort ? "select_block active" : "select_block"}
                        onClick={() => setFlagDropDownSort(!flagDropDownSort)}
                        onBlur={() => setFlagDropDownSort(false)}
                        tabIndex={0}
                    >
                            <span>
                                {language === "ru"
                                    ? sortCatalog[sortCatalogIndx].label
                                    : sortCatalog[sortCatalogIndx].label_en
                                }
                            </span>
                        <ArrowDrop/>
                        <ul className={flagDropDownSort ? "drop_down-category active" : "drop_down-category"}>
                            {sortCatalog.map((elem, indx) =>
                                <li
                                    key={indx}
                                    onClick={() => {
                                        setSortCatalogIndx(indx)
                                        setActiveSortCatalog(elem.value);
                                        setFlagDropDownSort(false);
                                    }}
                                >
                                        <span>
                                            {language === "ru"
                                                ? elem.label
                                                : elem.label_en
                                            }
                                        </span>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
            {loading &&
                <Spin style={{ margin: "0 auto", width: "fit-content", zIndex: 2}}/>
            }
            <ul className="freelancers_list">
                {data?.results?.length > 0 &&
                    data.results.map((elem, indx) =>
                        <FreelancerCard
                            key={elem.id}
                            id={elem.id}
                            indx={indx}
                            name={`${elem.first_name} ${elem.last_name}`}
                            photo={elem.photo}
                            salary={elem.salary ?
                                `${elem.salary} ${constantsResume.currencies[elem.salary_currency] || "р"}/ ${elem.rate_type === "month"
                                    ?
                                    language === "ru"
                                        ?
                                        "месяц"
                                        : "month"
                                    : language === "ru"
                                        ?
                                        "час"
                                        : "hour"
                                }`
                                : ""
                            }
                            position={elem.position}
                            openListSkills={openListSkills === indx}
                            setOpenListSkills={(indx) => {
                                if (openListSkills === indx)
                                    setOpenListSkills(null)
                                else setOpenListSkills(indx)
                            }}
                            skills={[]}
                            skillsResume={elem.skills}
                            favorite={elem.favorite}
                            changeFavoriteState={(id, value) => {
                                let newData = [...data?.results];
                                let findIndx = newData.findIndex(elem => elem.id === id);
                                if (findIndx !== -1) {
                                    newData[findIndx].favorite = value;
                                }

                                setData(prev => ({...prev, ["results"]: newData}))
                            }}
                            flagAuth={flagAuth}
                        />
                    )
                }
            </ul>
            {data?.count && data.count > 20
                ? <div className="pagination_all">
                    <Pagination
                        itemRender={defaultFuncAndData.itemRenderPagination}
                        defaultCurrent={1}
                        defaultPageSize={20}
                        total={data.count}
                        onChange={(e) => setCurrentPage(e)}
                        showTitle={false}
                    />
                </div>
                : <></>
            }
        </motion.div>
    );
};

export default FreelanceList;