import linkedin from "../images/icons/linkedin.svg";
import linkedin_png from "../images/icons/linked_in.png";
import telegram from "../images/icons/telegram.png";
import telegram_png from "../images/icons/telegram_new.png";
import tiktok from "../images/icons/tiktok.png";
import skype_icon from "../images/icons/skype_icon.svg";
import skype_icon_png from "../images/icons/skype_png.png";
import vk_icon from "../images/icons/vk_icon.svg";
import vk_icon_png from "../images/icons/vk_png.png";
import no_name_social_png from "../images/icons/no_name_social_png.png";
import mail from "../images/icons/mail_resume.svg";
import whatsapp from "../images/icons/whatsapp.svg";

import speciality_logo_1 from "../images/speciality_id_default/speciality_id_1.png";
import speciality_logo_2 from "../images/speciality_id_default/speciality_id_2.png";
import speciality_logo_3 from "../images/speciality_id_default/speciality_id_3.png";
import speciality_logo_4 from "../images/speciality_id_default/speciality_id_4.png";
import speciality_logo_5 from "../images/speciality_id_default/speciality_id_5.png";
import speciality_logo_6 from "../images/speciality_id_default/speciality_id_6.png";
import speciality_logo_7 from "../images/speciality_id_default/speciality_id_7.png";
import it_icon from "../images/search_talants/it_icon.svg";
import medical_icon from "../images/search_talants/medical_icon.svg";
import languages_icon from "../images/search_talants/languages_icon.svg";
import menegement_icon from "../images/search_talants/menegement_icon.svg";
import finance_icon from "../images/search_talants/finance_icon.svg";
import design_icon from "../images/search_talants/design_icon.svg";
import sport_icon from "../images/search_talants/sport_icon.svg";
import React from "react";

export const defaultFuncAndData = {
    arrTags() {
        return [
            {name: "postgresql", id: 1},
            {name: "java", id: 2},
            {name: "ms excel", id: 3},
            {name: "UI", id: 4},
            {name: "Моб. приложения", id: 5},
            {name: "пользователь пк", id: 5},
            {name: "python", id: 5},
            {name: "git", id: 5},
            {name: "linux", id: 5},
        ];
    },
    arrTagsAllPopular() {
        return [
            {name: "Работа в команде", id: 1},
            {name: "Грамотная речь", id: 2},
            {name: "Пользователь пк", id: 3},
            {name: "Английский язык", id: 4},
            {name: "Грамотность", id: 5},
            {name: "Активные продажи", id: 6},
            {name: "Медицинская документация", id: 7},
            {name: "Управление проектами", id: 8},
            {name: "smm", id: 9},
            {name: "Управление персоналом", id: 10},
            {name: "Подбор персонала", id: 11},
            {name: "Консультирование", id: 12},
            {name: "Холодные продажи", id: 13},
            {name: "Ответственность", id: 14},
            {name: "Техническое обслуживание", id: 15},
            {name: "html", id: 16},
            {name: "javascript", id: 17},
            {name: "1с: предприятие 8", id: 18},
            {name: "Документооборот", id: 19},
            {name: "ms excel", id: 20},
            {name: "Коммуникабельность", id: 21},
            {name: "java", id: 22},
            {name: "css", id: 23},
            {name: "ms sql server", id: 24},
            {name: "figma", id: 25}

        ];
    },
    calcColorPercentProgressProfession(procentague) {
        if (procentague >= 66)
            return "#30E0A1";
        if (procentague >= 33)
            return "#FF5937";
        return "#FF3434";
    },
    calcColorPercentProgressCourse(procentague) {
        if (procentague >= 60)
            return {
                color: "#8146FF",
                name: "fiolet"
            };
        if (procentague >= 20)
            return {
                color: "#FFD23B",
                name: "yellow"
            };
        return {
            color: "#FF3434",
            name: "red"
        };
    },
    calcProcentague(salary) {
        const maxNum = 250000;
        return salary / maxNum * 100;
    },
    calcPeriod(str) {
        if (str)
            return {
                start: str.split(" ")[1].replace(/[/]/g, "."),
                end: str.split(" ")[3].replace(/[/]/g, ".")
            };
        return "";
    },
    funcMissingsSkill(firstArr, secondArr) {
        if (firstArr && firstArr.length > 0 && secondArr && secondArr.length > 0) {
            let missingSkills =
                secondArr.filter(elem => firstArr.every(item => item.name.toLowerCase() !== elem.name.toLowerCase()));
            let haveSkills = [];
            for (let i = 0; i < firstArr.length; i++) {
                for (let j = 0; j < secondArr.length; j++) {
                    if (firstArr[i].name.toLowerCase() === secondArr[j].name.toLowerCase()) {
                        haveSkills = [...haveSkills, firstArr[i]]
                    }
                }
            }
            return {
                missingSkills,
                haveSkills
            }
        }
        return [];
    },
    calcSalarys(arrVacancy) {
        if (arrVacancy && arrVacancy.length > 0) {
            let minSalar = 100000;
            let maxSalar = 0;

            for (let i = 0; i < arrVacancy.length; i++) {
                if (arrVacancy[i].salary !== null) {
                    if (arrVacancy[i].salary.currency === "RUR") {
                        if (arrVacancy[i].salary.from && arrVacancy[i].salary.from < minSalar)
                            minSalar = arrVacancy[i].salary.from;

                        if (arrVacancy[i].salary.to && arrVacancy[i].salary.to > maxSalar)
                            maxSalar = arrVacancy[i].salary.to;

                        if (arrVacancy[i].salary.from && arrVacancy[i].salary.from > maxSalar)
                            maxSalar = arrVacancy[i].salary.from;

                    } else {
                        if (arrVacancy[i].salary.from && (arrVacancy[i].salary.from * 70) < minSalar)
                            minSalar = arrVacancy[i].salary.from * 70;

                        if (arrVacancy[i].salary.to && (arrVacancy[i].salary.to * 70) > maxSalar)
                            maxSalar = arrVacancy[i].salary.to * 70;

                        if (arrVacancy[i].salary.from && (arrVacancy[i].salary.from * 70) > maxSalar)
                            maxSalar = arrVacancy[i].salary.from * 70;
                    }
                }
            }
            return {
                minSalary: minSalar,
                maxSalary: maxSalar,
                averageSalary: (minSalar + maxSalar) / 2
            };
        }
    },
    calcSalarysVacancy(elem) {
        let result = "";
        if (elem?.salary) {
            if (elem.salary.from && !elem.salary.to)
                result += "От "
            if (!elem.salary.from && elem.salary.to)
                result += "До "
            if (elem.salary.from)
                result += elem.salary.from
            if (elem.salary.from && elem.salary.to)
                result += " - "
            else
                result += " "
            if (elem.salary.to)
                result += elem.salary.to
            if (elem.salary.to || elem.salary.from) {
                if (elem.salary.currency === "RUR")
                    result += " руб."
                if (elem.salary.currency === "USD")
                    result += " $"
            }
        }
        return result;
    },
    calcActiveFavoriteIcon(elem, favoriteProfOreVacancy) {
        if (favoriteProfOreVacancy && favoriteProfOreVacancy.length > 0
            && elem && Object.keys(elem).length > 0
        )
            for (let i = 0; i < favoriteProfOreVacancy.length; i++)
                if (favoriteProfOreVacancy[i].id == elem.id)
                    return true;
        return false;
    },
    viewImg(current, format = "") {
        switch (current) {
            case 'telegram':
                if (format)
                    return telegram_png
                return telegram;
            case 'skype':
                if (format)
                    return skype_icon_png
                return skype_icon;
            case 'linkedin':
                if (format)
                    return linkedin_png
                return linkedin;
            case 'vk':
                if (format)
                    return vk_icon_png
                return vk_icon;
            case 'tiktok':
                return tiktok;
            case 'email':
                return mail;
            case 'whatsapp':
                return whatsapp;
            default:
                return no_name_social_png
        }

    },
    defaultImage(indx) {
        const specialities = [
            speciality_logo_1,
            speciality_logo_2,
            speciality_logo_3,
            speciality_logo_4,
            speciality_logo_5,
            speciality_logo_6,
            speciality_logo_7
        ]
        return specialities[indx]
    },
    declension(number, txt, cases = [2, 0, 1, 1, 1, 2]) {
        // sample: declension(3, ["предмет", "предмета", "предметов"])
        return txt[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]]
    },
    spliceStr(text, countSlice) {
        let sliced = '';
        if (text) {
            sliced = text.slice(0, countSlice);
            if (sliced.length < text.length) {
                sliced += '...';
            }
        }
        return sliced
    },
    validateUrl(value) {
        return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
    },
    dateParser(locales, date) {
        const options = {
            // era: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            weekday: "long",
            timezone: "UTC",
            hour: "numeric",
            minute: "numeric",
            second: "numeric"
        };
        const actualDate = new Date(date);
        return `${actualDate.toLocaleDateString(locales, options)} `;
    },
    brChanger(text) {
        if (!text) return []
        const arr = [];
        const splitArr = text.split("\n"); // [`abc`, `def`]
        for (let i = 0; i < splitArr.length; i++) {
            arr.push(splitArr[i]);
            arr.push(<br/>);
        }
        arr.pop();
        return arr;
    },
    linkChanger(text) {
        //let regEx1 = text.match(/https:\/\/[^\s\Z]+/i);
        //let regEx2 = text.match(/https:\/\/[^\s\Z]+/g);
        //let regEx3 = /(https?:\/\/|ftps?:\/\/|www\.)((?![.,?!;:()]*(\s|$))[^\s]){2,}/gim;
        let regEx = /((?:https?:\/\/|ftps?:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,})|(\n+|(?:(?!(?:https?:\/\/|ftp:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,}).)+)/gim;
        let arr = [];
        text.replace(
            regEx,
            (m, link, text) => {
                arr.push(link
                    ? <a
                        href={(link[0] === "w" ? "//" : "") + link}
                        key={arr.length}
                    >
                        {link}
                    </a>
                    : text)
            })
        return arr;
    },
    categoryArray() {
        return [
            {
                src: it_icon,
                color: "#8146FF",
                text: "Инженерные & IT",
                text_en: "Engineering & IT",
                url: "/student/freelance-list/engineering_it"
            },
            {
                src: medical_icon,
                color: "#3077FF",
                text: "Наука & Медицина",
                text_en: "Science & Medicine",
                url: "/student/freelance-list/science_medicine"
            },
            {
                src: languages_icon,
                color: "#30E0A1",
                text: "Языки & Копирайтинг",
                text_en: "Languages & Copywriting",
                url: "/student/freelance-list/languages_copywriting"
            },
            {
                src: menegement_icon,
                color: "#FFD23B",
                text: "Менеджмент & Маркетинг",
                text_en: "Management & Marketing",
                url: "/student/freelance-list/management_marketing"
            },
            {
                src: finance_icon,
                color: "#FF47ED",
                text: "Финансы & Юриспруденция",
                text_en: "Finance & Law",
                url: "/student/freelance-list/finance_law"
            },
            {
                src: design_icon,
                color: "#CF1A1A",
                text: "Дизайн & Креатив",
                text_en: "Design & Creative",
                url: "/student/freelance-list/design_creative"
            },
            {
                src: sport_icon,
                color: "#F95A00",
                text: "Спорт",
                text_en: "Sport",
                url: "/student/freelance-list/sport"
            },
            {
                src: it_icon,
                color: "grey",
                text: "Все категории",
                text_en: "All categories",
                url: "/student/freelance-list/all"
            }
        ];
    },
    getCurrentCategoryId(categoryName) {
        switch (categoryName) {
            case "engineering_it":
                return 4;
            case "science_medicine":
                return 2;
            case "languages_copywriting":
                return 5;
            case "management_marketing":
                return 3;
            case "finance_law":
                return 7;
            case "design_creative":
                return 6;
            case "sport":
                return 1;
            case "all":
                return 0;
            default:
                return 4
        }
    },
    countExperience(startDate, endDate) {
        let convertMonth = (oldDate) => {
            let newDate = oldDate.split("-");
            newDate[1] = `0${newDate[1]}`;
            newDate = newDate.join("-");
            return newDate;
        }

        if (startDate && startDate.length === 6)
            startDate = convertMonth(startDate);
        if (endDate && endDate.length === 6)
            endDate = convertMonth(endDate);

        const startYear = parseInt(startDate.substring(0, 4));
        const startMonth = parseInt(startDate.substring(5, 7));
        const endYear = parseInt(endDate.substring(0, 4));
        const endMonth = parseInt(endDate.substring(5, 7));

        const diffYears = endYear - startYear;
        const diffMonths = endMonth - startMonth;

        let experience = diffYears * 12 + diffMonths;

        if (diffMonths < 0) {
            experience -= 12;
        }
        if (experience) {
            if (experience < 12) {
                return defaultFuncAndData.declension(experience, [" месяц", " месяца", " месяцев"])
            }
            if (experience === 12) {
                return "1 год"
            }
            if (experience > 12) {
                let monthToYear = experience / 12;
                let decimal = monthToYear - Math.floor(monthToYear);
                let yearStr = `${Math.floor(monthToYear)} ${defaultFuncAndData.declension(Math.floor(monthToYear), [" год", " года", " лет"])}`
                let monthStr = `${parseInt(decimal.toString()[2])} ${defaultFuncAndData.declension(parseInt(decimal.toString()[2]), [" месяц", " месяца", " месяцев"])}`
                return `${yearStr} и ${monthStr}`
            }
        }

        return null;
    },
    itemRenderPagination(_, type, originalElement) {
        if (type === "prev")
            return null;
        if (type === "next")
            return;

        return originalElement;
    },
    convertToFontCode(str) { // выделение тегом <code> команд и тегов.
        let sentence = str.replace(/`{3}/g, "`");
        let wordsInQuotes = sentence.match(/`([^`]+)`/g);
        if (wordsInQuotes && wordsInQuotes?.length > 0) {
            wordsInQuotes.forEach((word) =>
                sentence = sentence.replace(word, "*****")
            );
            let splitArr = sentence.split("*****")
            for (let i = 0; i < wordsInQuotes.length; i++) {
                let newElem = <code
                    style={{
                        color: 'lightgreen',
                        background: "#11191e",
                        padding: "0 5px"
                    }}
                >
                    {wordsInQuotes[i].replace(/`/g, "")}
                </code>
                splitArr.splice(i + i + 1, 0, newElem)
            }
            return splitArr || str
        }
        return str
    },
    delay(ms) {
        return new Promise((res) => {
            setTimeout(res, ms)
        })
    },
    validateEmail(email) {
        let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        return reg.test(email)
    }
};
